body.home {
  background-color: #202d51;
  color: #fff;

  [class*='display-'] {
    font-family: var(--font_family_brand_regular);
  }

  .content {
    margin-top: 75px;
  }

  .image-right {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 1);
    transform: rotate(-1deg);
    border-radius: 8px;
  }

  .image-left {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 1);
    transform: rotate(1deg);
    border-radius: 8px;
  }

  .site-sidebar {
    position: relative;
  }

  .home-header {
    height: 45px;
    .dropdown-toggle-caret::after {
      margin-top: -18px;
    }
  }

  .hero-main {
    padding-top: 70px;
    background-image: linear-gradient(
      135deg,
      rgb(0, 114, 184, 0.7) 0%,
      rgb(49, 65, 131, 0.7) 50%,
      rgb(160, 63, 155, 0.7) 100%
    );
    // background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(67, 85, 145, 0.7)),
    //   url('../../../assets/images/homepage/home-bg.png');
    // background-position: center center;
    // background-repeat: no-repeat;
    // background-size: cover;
  }
  .hero-waves {
    display: block;
    margin-top: 60px;
    width: 100%;
    height: 40px;
    color: #202d51;
  }
  section {
    padding: 1rem;

    h2 {
      color: white;
      font-family: var(--font_family_brand_light);
      font-size: 20px;
      margin: 0 0 0 3.5rem;
      line-height: 1;
    }
  }

  .wave1 use {
    -webkit-animation: move-forever1 10s linear infinite;
    animation: move-forever1 10s linear infinite;
    -webkit-animation-delay: -2s;
    animation-delay: -2s;
  }

  .wave2 use {
    -webkit-animation: move-forever2 8s linear infinite;
    animation: move-forever2 8s linear infinite;
    -webkit-animation-delay: -2s;
    animation-delay: -2s;
  }

  .wave3 use {
    -webkit-animation: move-forever3 6s linear infinite;
    animation: move-forever3 6s linear infinite;
    -webkit-animation-delay: -2s;
    animation-delay: -2s;
  }

  @-webkit-keyframes move-forever1 {
    0% {
      transform: translate(85px, 0%);
    }

    100% {
      transform: translate(-90px, 0%);
    }
  }

  @keyframes move-forever1 {
    0% {
      transform: translate(85px, 0%);
    }

    100% {
      transform: translate(-90px, 0%);
    }
  }

  @-webkit-keyframes move-forever2 {
    0% {
      transform: translate(-90px, 0%);
    }

    100% {
      transform: translate(85px, 0%);
    }
  }

  @keyframes move-forever2 {
    0% {
      transform: translate(-90px, 0%);
    }

    100% {
      transform: translate(85px, 0%);
    }
  }

  @-webkit-keyframes move-forever3 {
    0% {
      transform: translate(-90px, 0%);
    }

    100% {
      transform: translate(85px, 0%);
    }
  }

  @keyframes move-forever3 {
    0% {
      transform: translate(-90px, 0%);
    }

    100% {
      transform: translate(85px, 0%);
    }
  }

  .coach-marks-home {
    margin-top: 12px;
    box-shadow: 0 8px 16px 3px rgba(0, 0, 0, 0.5);

    .shepherd-content .shepherd-text {
      font-size: var(--font_size_base);
      line-height: 1.5;
    }
  }

  .site-footer {
    footer {
      margin-top: 3rem;
      position: relative;

      ul.list-inline {
        display: none;
      }
    }
  }
}
