.dropdown-menu-accounts {
  padding: 1rem;

  > .p-treenode {
    border-bottom: 1px solid var(--border_color);
  }
  .p-treenode-content {
    white-space: nowrap;
  }
  .p-tree-toggler {
    width: 16px;
    display: inline-block;
  }

  .p-treenode {
    display: block;
  }

  .p-tree-container {
    > .p-treenode-content {
      border-bottom: 1px solid var(--border_color);
    }
  }

  .p-tree-filter-icon {
    position: absolute;
    top: 6px;
    left: 6px;
  }
  .p-tree-filter-container {
    position: relative;
    margin-bottom: 1rem;
  }
  // the input field
  .p-tree-filter {
    height: calc(1.6248125rem + 2px);
    padding: 0.25rem 0.5rem 0.25rem 24px;
    font-size: var(--font_size_sm);
    line-height: 1.5;
    border-radius: 0.2rem;
  }

  .p-treenode-label {
    &.p-state-highlight {
      background-color: transparent !important;
    }
  }
  .p-treenode-selectable {
    &.p-highlight {
      background-color: transparent !important;
    }
  }
} // end dropdown-menu-accounts
