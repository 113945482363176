nav.left-nav {
  margin-top: 2.5rem;

  .list-group {
    a.list-group-item {
      padding: 0.5rem 1rem;
      margin-bottom: 0.5rem;
      border: 0;
      border-radius: 0;
      color: var(--text);
      cursor: pointer;
      &:hover {
        color: var(--hyperlink);
      }
      &.active {
        padding-left: calc(1rem - 0.125rem);
        border-left: 0.125rem solid var(--hyperlink);
        background-color: transparent;
        color: var(--hyperlink);
      }
    }
  }

  .card {
    border: 0;
    border-bottom: 1px solid var(--border_color) !important;
    background-color: transparent;

    .card-body {
      padding: 0;
      .list-group-item {
        padding-left: 2rem;
        &.active {
          padding-left: calc(2rem - 0.125rem);
        }
      }
    }
  }

  .section-head {
    padding-left: 15px;
    color: var(--text);
    font-weight: bold;
  }

  .section-group {
    .list-group {
      margin-bottom: 0;
    }
  }

  .section-group:not(:first-child) {
    padding-top: 30px;
    margin-top: 30px;
    border-top: solid 1px var(--border_color);
  }
}

.splitview-wrapper {
  height: calc(100% - 63px);
  > .row {
    height: 100%;
  }
  .splitview-nav {
    width: 200px;
    padding-right: 0;
    padding-left: 0;
    .list-group-item {
      background-color: transparent;
    }
    &.splitview-nav-filled {
      background-color: #fff;
      .bgwhite & {
        background-color: var(--rich_bg_color);
      }
    }
    &.splitview-nav-bordered {
      border-right: 1px solid var(--border_color);
    }
  }
  .splitview-content {
    padding-left: 2rem;
  }
}

.splitview-wrapper {
  height: initial;

  > .row {
    height: initial;
    min-height: calc(100vh - 70px - 63px);
  }

  .splitview-nav {
    width: initial; // NOTE: width of 200px does nothing, should be removed
  }
}
