.input-typeahead {
  position: relative;

  ngb-typeahead-window {
    &::after {
      display: block;
      padding: 0.25rem 0.625rem;
      background-color: var(--white);
      color: var(--text);
      content: 'powered by Pitney Bowes';
      font-size: 0.563rem;
      text-align: right;
    }
  }

  .input-typeahead-loader {
    position: absolute;
    top: 0.625rem;
    right: 0.625rem;
  }
}
