.color-picker {
  box-sizing: initial;
  background: #fff;

  .color-saturation {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-bottom: 75%;
  }
  .color-controls {
    display: flex;
  }
  .color-sliders {
    flex: 1 1 0%;
    padding: 4px 0;
  }
  .color-hue {
    position: relative;
    overflow: hidden;
    height: 10px;
  }
  .color-alpha {
    position: relative;
    overflow: hidden;
    height: 10px;
    margin-top: 4px;
  }
  .color-preview {
    position: relative;
    width: 24px;
    height: 24px;
    margin-top: 4px;
    margin-left: 4px;
    border-radius: 3px;
  }
  .color-active {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.15) 0 0 0 1px inset, rgba(0, 0, 0, 0.25) 0 0 4px inset;
  }

  .color-fields {
    display: flex;
    padding-top: 4px;

    .color-double {
      flex: 2 1 0%;
    }
    .color-single {
      flex: 1 1 0%;
      padding-left: 6px;
    }
    .color-alpha {
      flex: 1 1 0%;
      padding-left: 6px;
    }

    .color-preview {
      width: 80px;
      height: 40px;
      margin-top: 0;
      margin-left: 20px;
    }
  }

  .color-swatches {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding: 15px 0 0 10px;
    margin: 0 -10px;
    border-top: none;
  }
  .color-wrap {
    width: 16px;
    height: 16px;
    margin: 0 10px 10px 0;
  }

  .color-button {
    display: inline-block;
    width: 17px;
    height: 17px;
    border: 1px solid var(--btn_primary);
    color: var(--btn_primary);
    cursor: pointer;
    font-size: 0.9rem;
    line-height: 0.9rem;
    text-align: center;
  }
  .color-button:hover {
    background-color: var(--gray100);
  }

  .color {
    border-radius: 3px;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15);
  }

  .color-input {
    input {
      width: 100%;
      box-sizing: border-box;
      padding: 4px 10% 3px;
      border: none;
      box-shadow: inset 0 0 0 1px #ccc;
      font-size: 11px;
    }
    span {
      display: block;
      padding-top: 3px;
      padding-bottom: 4px;
      color: var(--text);
      font-size: 11px;
      text-align: center;
      text-transform: capitalize;
    }
  }

  .swatch-dot {
    position: absolute;
    top: 5px;
    right: 5px;
    bottom: 5px;
    left: 5px;
    border-radius: 50%;
    opacity: 0;
  }
  .swatch-dot.active {
    opacity: 1;
  }
}
