// colors
$ht-color: #0372b8;
$ht-color-light: var(--text);
$ht-color-future: #a1c2e1;
$ht-color-delivered: #00b140;
$ht-text-color: var(--text);

// Sizing
$ht-tracker-padding: 5px !default;
$ht-marker-size: 18px;
$ht-path-height: 18px;
$ht-marker-border-width: 3px;
$ht-marker-size-half: 12px;
$ht-marker-size-third: 4px;
$ht-marker-size-quarter: 4px;
$ht-marker-spacing: 10px !default;
// $ht-path-position: $ht-marker-size-half - ($ht-path-height / 2);
$ht-path-position: 0px;
$text-padding: $ht-marker-size-half !default;
$text-padding-X: $ht-marker-size-third !default;
$text-padding-Y: $ht-marker-size-half !default;
$text-padding--vertical: $ht-marker-size + $ht-marker-size-half !default;
// Only needed for short text version, the word size should be the width of the widest word without padding.
$word-size: 54px !default;
// $ht-tracker-word-padding: ($word-size + $text-padding-X + $ht-marker-size-half) / 2;
$ht-tracker-word-padding: 35px;

// ----- Elements -----
// Container element

.ht-tracker {
  display: flex;
  padding: 0;
  // margin: 40px auto;
  list-style: none;
}
// Step container that creates lines between steps
.ht-step {
  position: relative;
  display: block;
  min-width: $ht-marker-size; // For a flexbox bug in firefox that wont allow the text overflow on the text
  flex: 1 1 0;
  padding: 0;
  margin: 0;
  // Stops the last step growing
  &:last-child {
    flex-grow: 0;
  }
  // Path between markers, this is not created for the last step
  &:not(:last-child)::after {
    position: absolute;
    top: $ht-path-position;
    right: $ht-marker-size-half;
    bottom: $ht-path-position;
    display: block;
    width: 100%;
    height: $ht-path-height;
    content: '';
  }
}
// Progress marker
.ht-marker {
  position: relative;
  z-index: 3;
  display: flex;
  width: $ht-marker-size;
  height: $ht-marker-size;
  align-items: center;
  justify-content: center;
  border: $ht-marker-border-width solid transparent;
  border-radius: 50%;
  color: #fff;
  font-weight: 400;
  line-height: $ht-marker-size;
  transition: background-color, border-color;
}
// Progress text
.ht-text {
  display: block;
  overflow: hidden;
  padding: $text-padding-Y $text-padding-X;
  text-overflow: ellipsis;
}

.ht-title {
  margin-top: 0;
}
// Step state mixin - The first arugment is required and the rest are optional, if you pass in null the value will not be changed.
@mixin progress-state(
  $ht-marker-color-bg,
  $ht-marker-color-border: null,
  $ht-marker-color-text: null,
  $ht-path-color: null,
  $text-color: null
) {
  .ht-marker {
    border-color: $ht-marker-color-border;
    background-color: $ht-marker-color-bg;
    color: $ht-marker-color-text;
    .out-for-delivery & {
      border-color: $ht-color-delivered;
    }
  }

  &::after {
    background-color: $ht-path-color;
  }

  .out-for-delivery & {
    &::after {
      background-color: $ht-color-delivered;
    }
  }
}
// States
.ht-step {
  .ht-title {
    font-weight: bold;
    color: var(--text);
    line-height: 1.2;
    margin-bottom: 2px;
  }
  .ht-date {
    font-weight: 400;
    font-size: 12px;
  }

  // Inactive - Default state
  @include progress-state(#fff, $ht-color-future, #fff, $ht-color-future, $ht-text-color);
  // Active state
  &.is-active {
    @include progress-state($ht-color);
  }
  // Complete state
  &.is-complete {
    @include progress-state(#fff, $ht-color, $ht-path-color: $ht-color);
  }
}

.ht-step {
  &.is-active {
    .ht-marker {
      border: 3px solid $ht-color;
      background-color: #fff;
      color: var(--text);
      position: relative;
      &::after {
        position: absolute;
        // content: '\e918';
        content: '\ea8e';
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'pbi-icon-mini' !important;
        font-size: 14px;
        color: #fff;
        background-color: $ht-color;
        width: 28px;
        height: 28px;
        border: 3px solid #fff;
        border-radius: 50%;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
      }
      .out-for-delivery & {
        &::after {
          position: absolute;
          content: '\e918';

          background-color: $ht-color-delivered;
        }
      }
    }
  }
}
// ----- Modifiers -----
// Center align markers and text
.ht-tracker--center {
  .ht-step {
    text-align: center;

    &:last-child {
      flex-grow: 1;
    }

    &::after {
      right: -50%;
    }
  }

  .ht-marker {
    margin-right: auto;
    margin-left: auto;
  }
}

// Word below markers
.ht-tracker--word {
  overflow: hidden;
  padding-right: $ht-tracker-word-padding;

  .ht-text {
    display: inline-block;
    white-space: nowrap;
  }
}

.ht-tracker--word-center {
  padding-right: $ht-tracker-word-padding;
  padding-left: $ht-tracker-word-padding;

  .ht-text {
    padding-right: 0;
    padding-left: 0;
    transform: translateX(calc(-50% + #{$ht-marker-size-half}));
  }
}

// Text below markers
.ht-tracker--text {
  .ht-step {
    &:last-child {
      flex-grow: 1;
    }
  }
}
