/*!
Design System
Version: <% version %>
Date: <% date %>
https://designsystem.pitneycloud.com
*/

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import '../../../node_modules/bootstrap/scss/functions';

// 2. Include any default variable overrides here
@import 'variables-css';
@import 'colors';
@import 'variables';

// 3. Include remainder of required Bootstrap stylesheets
@import '../../../node_modules/bootstrap/scss/variables';

// 4. Include any default map overrides here

// 5. Include remainder of required parts
@import '../../../node_modules/bootstrap/scss/maps';
@import '../../../node_modules/bootstrap/scss/mixins';
@import '../../../node_modules/bootstrap/scss/root';

// 6. Optionally include any other parts as needed
// @import '../../../node_modules/bootstrap/scss/utilities';
// @import 'bootstrap/scss/reboot';
// @import 'bootstrap/scss/type';
// @import 'bootstrap/scss/images';
// @import 'bootstrap/scss/containers';
// @import 'bootstrap/scss/grid';
// @import 'bootstrap/scss/helpers';

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
// @import '../../../node_modules/bootstrap/scss/utilities/api';

// 8. Add additional custom code here

@import 'fonts';

@import 'mixins';

@import 'pbi-icon-outline';
@import 'pbi-icon-mini';

@import 'gradients';

@import 'layout';
@import 'icons';
@import 'menus';
@import 'header';
@import 'footer';
@import 'type';
@import 'themes';

@import 'bs5_utilities';

@import 'alerts';
@import 'badges';
@import 'breadcrumbs';
@import 'buttons';
@import 'buttons-pagelevel';
@import 'cards';
@import 'accordions';
@import 'checkboxes';
@import 'color-picker';
@import 'datepicker';
@import 'dragdrop';
@import 'empty-states';
@import 'errors';
@import 'form-floating-labels';
@import 'labels';
@import 'forms';
@import 'hamburger';
@import 'input-typeahead';
@import 'inputs';
@import 'metric-blocks';
@import 'miller-columns';
@import 'modals';
@import 'ng-select';
@import 'payment-blocks';
@import 'popovers-tooltips';
@import 'dataviz';
@import 'lists';
@import 'ngx-slider';
@import 'pricing-plans';
@import 'pbds-multiple-values';

@import 'primeng/base';
@import 'primeng/buttons';
@import 'primeng/calendar';
@import 'primeng/checkbox';
@import 'primeng/chips';
@import 'primeng/dropdown';
@import 'primeng/fileupload';
@import 'primeng/forms';
@import 'primeng/inputtext';
@import 'primeng/knobcontrol';
@import 'primeng/multiselect';
@import 'primeng/paginator';
@import 'primeng/slider';
@import 'primeng/stepper';
@import 'primeng/tables';
@import 'primeng/treetable';
@import 'primeng/tree';
@import 'primeng/virtualscroller';
@import 'primeng/contextmenu';
@import 'primeng/toast';
@import 'primeng/skeleton';
@import 'primeng/listskeleton';
@import 'primeng/table-scrollable';
@import 'primeng/picklist';
@import 'primeng/rich-text-editor';
@import 'primeng/image';
@import 'primeng/carousel';

@import 'account-switcher';
@import 'case-create';
@import 'dashboards';
@import 'line-clamp';
@import 'progress';
@import 'progress-bar';
@import 'progress-tracker';
@import 'promo-hero';
@import 'search';
@import 'signin';
@import 'splitviews';
@import 'switches';
@import 'table-cards';
@import 'tables';
@import 'tabletoolbar';
@import 'tabs';
@import 'tabs-tiles';
@import 'toastrs';
@import 'welcome';
@import 'widgets';
@import 'coach-marks';
@import 'tracking-timeline';
@import 'tracking-timeline-horizontal';
@import 'rating';
@import 'tile-button';

@import 'material/mat-list';
@import 'material/mat-accordion';
@import 'material/mat-dialog';
@import 'material/mat-toggle-button';
@import 'material/mat-checkbox';
@import 'material/mat-radio';
@import 'material/mat-tabs';
@import 'material/mat-sidenav';
@import 'material/mat-theme';

@import 'focus';

@import 'a11y-widget';

@import 'compact-ui';
