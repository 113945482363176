$grads: (
  classic: (
    'start': #3e53a4,
    'mid': #a03f9b,
    'end': #cf0989,
  ),
  ocean: (
    'start': #314183,
    'mid': #1f62ae,
    'end': #0c884c,
  ),
  sunset: (
    'start': #50248f,
    'mid': #b70077,
    'end': #ee6700,
  ),
  twilight: (
    'start': #0072b8,
    'mid': #3e53a4,
    'end': #a03f9b,
  ),
  shipaccel: (
    'start': #505877,
    'mid': #505877,
    'end': #505877,
  ),
);

$themes: 'classic', 'ocean', 'sunset', 'twilight', 'shipaccel';

@mixin gradient($color-start, $color-center, $color-end) {
  background-image: linear-gradient(90deg, $color-start 0%, $color-center 50%, $color-end 100%);
}

@mixin gradient-angled($color-start, $color-center, $color-end) {
  background-image: linear-gradient(135deg, $color-start 0%, $color-center 50%, $color-end 100%);
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }
  @return $map;
}

@each $theme in $themes {
  .gradient-#{$theme} {
    @include gradient(
      map-deep-get($grads, #{$theme}, 'start'),
      map-deep-get($grads, #{$theme}, 'mid'),
      map-deep-get($grads, #{$theme}, 'end')
    );
  }
  .gradient-#{$theme}-angled {
    @include gradient-angled(
      map-deep-get($grads, #{$theme}, 'start'),
      map-deep-get($grads, #{$theme}, 'mid'),
      map-deep-get($grads, #{$theme}, 'end')
    );
  }
}
