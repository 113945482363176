@mixin normalize-labels {
  text-transform: none;
  font-size: var(--font_size_base);
  letter-spacing: 0;
}

.form-label {
  text-transform: uppercase;
  font-size: var(--font_size_xs);
  letter-spacing: 0.07em;
  margin-bottom: 0.25rem;

  .optional,
  .required {
    text-transform: none;
    font-size: var(--font_size_sm);
    font-style: italic;
  }

  span {
    text-transform: none;
  }
}
.mdc-form-field label {
  font-size: var(--font_size_base) !important;
  font-family: var(--font_family_base) !important;
  color: var(--text) !important;
  text-transform: none !important;
  letter-spacing: normal !important;
}

.btn-group-toggle {
  label {
    @include normalize-labels;
  }
}

.checkbox-list.form-control {
  label {
    @include normalize-labels;
  }
}
