.p-dropdown {
  display: inline-flex;
  cursor: pointer;
  position: relative;
  user-select: none;
  border: 1px solid var(--input_border_color);
  background-color: #fff;

  .bgwhite & {
    background-color: var(--rich100);
  }

  .bgdark & {
    background-color: var(--dark_input_bg);
    border: 1px solid var(--dark_input_border);
  }
}

.p-dropdown-clear-icon {
  position: absolute;
  top: 50%;
  margin-top: -0.5rem;
}

.p-dropdown-trigger {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  .pi {
    margin-top: 0.3em;
    margin-left: -0.125em;
    font-family: 'pbi-icon-mini';

    &.pi-caret-down::before {
      content: '\ea33';
    }
  }
}

.p-dropdown-label {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  flex: 1 1 auto;
  width: 1%;
  text-overflow: ellipsis;
  cursor: pointer;
}

.p-dropdown-label-empty {
  overflow: hidden;
  visibility: hidden;
}

input.p-dropdown-label {
  cursor: default;
}

.p-dropdown .p-dropdown-panel {
  min-width: 100%;
  background-color: #fff;
  border: 1px solid var(--input_border_color);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
}

.p-dropdown-panel {
  position: absolute;
}

.p-dropdown-items-wrapper {
  overflow: auto;
}

.p-dropdown-item {
  cursor: pointer;
  font-weight: normal;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  color: var(--text);
  font-family: var(--font-family-sans-serif);
  margin: 1px 0;
  padding: 0.25rem 0.5rem;

  &:not(.p-state-highlight):hover {
    border-color: var(--input_border_color);
    background: #e9edf9;
    color: var(--text);
  }
}

.p-dropdown-items {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.p-dropdown-filter {
  width: 100%;
}

.p-dropdown-filter-container {
  position: relative;
}

.p-dropdown-filter-icon {
  position: absolute;
  top: 50%;
  margin-top: -0.5rem;
}

.p-fluid .p-dropdown {
  display: flex;
}

.p-fluid .p-dropdown .p-dropdown-label {
  width: 1%;
}

.p-treeselect {
  width: 100%;
  background: #fff;
  border-radius: 4px;
  border: 1px solid var(--input_border_color);
  padding: 0.4rem 0.75rem;

  .bgdark & {
    border: 1px solid var(--dark_input_border);
    background: var(--dark_input_bg);
  }
  .bgwhite & {
    background-color: var(--form_control_bg_richcolor);
  }
  &.p-focus {
    @include focused;
  }
}

.p-treeselect .p-tree-wrapper {
  border: 1px solid var(--input_border_color);
  background-color: #fff;
  box-shadow: 0 1px #0000000f;

  .bgdark & {
    border: 1px solid var(--dark_input_border);
    background: var(--dark_input_bg);
  }
}

.p-treeselect .p-tree-wrapper .p-treenode-content.p-treenode-selectable {
  .p-tree-wrapper {
    border: 1px solid var(--input_border_color);
    background-color: #fff;
    box-shadow: 0 1px #0000000f;

    .bgdark & {
      border: 1px solid var(--dark_input_border);
      background: var(--dark_input_bg);
    }
  }
}

.p-tree-wrapper .p-treenode-content.p-treenode-selectable {
  padding: 0.5rem 1rem;
}

.p-treeselect .p-tree-wrapper .p-treenode-content.p-treenode-selectable {
  &:hover {
    background-color: var(--menu_hover);
    color: var(--text);
    outline: 1.5px solid var(--focus_border_color);
    outline-offset: -1px;
    .bgdark & {
      background: var(--dark_menu_hover);
      color: #fff;
    }
  }
  &:focus-visible {
    outline: 1.5px solid var(--focus_border_color);
    outline-offset: -1px;
  }
}

.p-tree-wrapper .p-tree-toggler-icon {
  color: var(--text);
  margin-right: 4px;
}

.p-treeselect .p-tree-wrapper .p-treenode {
  margin-bottom: 0;
}
.p-treeselect .p-tree-wrapper .p-treenode:focus-visible {
  @include focused;
  outline-offset: -1px;
}
.p-tree-wrapper .p-treenode {
  margin-bottom: 0;
}

.p-treeselect.p-treeselect-chip .p-treeselect-token {
  padding: 0 0.5rem;
  margin-right: 0.5rem;
  background: var(--rich200);
  color: var(--text);
  font-size: var(--font_size_sm);
  border-radius: 16px;

  .bgdark & {
    background-color: var(--dark_token_color);
    color: #000;
  }
}

.p-treeselect .p-treenode-selectable.p-highlight {
  background-color: #fff;

  .bgdark & {
    background-color: var(--dark_menu-hover);
  }
}

.p-treeselect .p-treenode-icon {
  display: none;
}

.p-treeselect .p-treenode-label {
  padding-left: 0;
}
