.type-label {
  font-family: var(--font_family_brand_regular);
  font-size: var(--font_size_lg);
}

.table-header-specs {
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 0;
  }
  h2 {
    padding-bottom: 0;
    border-bottom: 0;
  }
  .ul-sm {
    padding-left: 1rem;
    font-size: 12px;
    line-height: 1.2;
  }
}

.themeswatch {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: var(--font_size_sm);

  div:first-child {
    white-space: nowrap;
  }

  .typeswatch {
    margin-left: 1rem;
    color: #fff;
    height: 26px;
  }
}
table.type-samples {
  td a.demo-link {
    text-decoration: none !important;
    &:hover {
      text-decoration: underline !important;
    }
  }
}

.typeswatch {
  display: inline-block;
  min-width: 62px;
  padding: 4px 5px;
  margin-bottom: 0.25rem;
  font-family: var(--font-family-monospace);
  font-size: var(--font_size_sm);

  &._222 {
    background-color: #222;
    color: #fff;
  }

  &._cf0989 {
    background-color: #cf0989;
    color: #fff;
  }

  &._4e4e4e {
    background-color: #4e4e4e;
    color: #fff;
  }

  &._3e53a4 {
    background-color: #3e53a4;
    color: #fff;
  }

  &._fff,
  &._ffffff {
    border: 1px solid #c0c0c0;
    background-color: #fff;
    color: #000;
  }

  &._c0c0c0 {
    border: 1px solid #c0c0c0;
    background-color: #c0c0c0;
    color: #000;
  }

  &._717171 {
    background-color: #717171;
    color: #fff;
  }

  &._000,
  &._000000 {
    background-color: #000;
    color: #fff;
  }

  &._9b9b9b {
    background-color: #9b9b9b;
    color: #fff;
  }

  &._4a90e2 {
    background-color: #4a90e2;
    color: #fff;
  }

  &._008500 {
    background-color: #008500;
    color: #fff;
  }

  &._ef8200 {
    background-color: #ef8200;
    color: #fff;
  }

  &._eaeaea {
    background-color: #eaeaea;
    color: #000;
  }

  &._0072b8 {
    background-color: #0072b8;
    color: #fff;
  }

  &._0099ff {
    background-color: #09f;
    color: #fff;
  }

  &._a03f9b {
    background-color: #a03f9b;
    color: #fff;
  }

  &._f8f8f8 {
    background-color: #f8f8f8;
    color: #000;
  }

  &._e1e1e1 {
    background-color: #e1e1e1;
    color: #000;
  }

  &._0099ff {
    background-color: #09f;
    color: #fff;
  }
}

// these might not be used on the new site
.typeclass {
  display: block;
  padding: 5px;
  margin-bottom: 3px;
}

.text-bold {
  font-weight: bold;
}

// fonts for the font samples table
.psr14 {
  font-family: var(--font_family_brand_regular);
  font-size: var(--font_size_base);
  font-weight: normal;
}

.psb14 {
  font-family: var(--font_family_brand_bold);
  font-size: var(--font_size_base);
  font-weight: normal;
}

.psr16 {
  font-family: var(--font_family_brand_regular);
  font-size: var(--font_size_lg);
  font-weight: normal;
}

.psr32 {
  font-family: var(--font_family_brand_regular);
  font-size: 2rem;
  font-weight: normal;
  line-height: 1.1;
}
.psl32 {
  font-family: var(--font_family_brand_light);
  font-size: 2rem;
  font-weight: normal;
  line-height: 1.1;
}
.psb32 {
  font-family: var(--font_family_brand_bold);
  font-size: 2rem;
  font-weight: normal;
  line-height: 1.1;
}

.hn12 {
  font-family: var(--font-family-sans-serif);
  font-size: var(--font_size_sm);
}
.hn13 {
  font-family: var(--font-family-sans-serif);
  font-size: 0.8125rem; // 13px
}

.hn32 {
  font-family: var(--font_family_base);
  font-size: 2rem;
  font-weight: normal;
  line-height: 1.1;
}
