.modals {
  .tab-content {
    .tab-pane {
      padding: 0;
      border-bottom: 0;

      .component-example {
        border-top: 0;
      }

      figure {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
      }
    }
  }
}
