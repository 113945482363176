// ripples for buttons.  checkboxes/radios in their own scss file
.mat-ripple-element {
  background-color: hsla(227, 54%, 80%, 0.5);
}

.mat-mdc-button,
.mat-mdc-icon-button,
.mat-mdc-outlined-button {
  font-family: var(--font_family_brand_regular);
  font-size: var(--font_size_base);
  border-radius: 2px !important;
  padding: 0.5rem 0.75rem !important;
  line-height: 21px !important;

  &.btn-sm {
    padding: 0.26rem 0.5rem !important;
    border-radius: 0.2rem !important;
    font-size: 0.749875rem !important;
    line-height: 1.5 !important;
  }
}

mat-spinner-button {
  .mat-mdc-button.mat-primary[disabled],
  .mat-button.mat-accent[disabled],
  .mat-button.mat-warn[disabled],
  .mat-button[disabled][disabled],
  .mat-mdc-icon-button.mat-primary[disabled],
  .mat-icon-button.mat-accent[disabled],
  .mat-icon-button.mat-warn[disabled],
  .mat-icon-button[disabled][disabled],
  .mat-mdc-outlined-button.mat-accent[disabled],
  .mat-stroked-button.mat-warn[disabled],
  .mat-stroked-button[disabled][disabled] {
    background-color: transparent;
    border-color: var(--btn_primary);
    border-radius: 2px;
    padding: 0.5rem 0.75rem;
    color: var(--btn_primary);

    .mat-mdc-progress-spinner.mat-accent circle,
    .mat-mdc-progress-spinner.mat-accent circle {
      stroke: var(--btn_primary);
    }
  }

  .mat-mdc-button.btn-primary[disabled],
  .mat-mdc-outlined-button.btn-primary[disabled] {
    background-color: var(--btn_primary);
    border-radius: 2px;
    padding: 0.5rem 0.75rem;

    .mat-mdc-progress-spinner.mat-accent circle,
    .mat-mdc-progress-spinner.mat-accent circle {
      stroke: var(--white);
    }
  }
}
