.btn-tile {
  --tile-width: 160px;
  --tile-height: 120px;
  --tile-padding: 15px;
  --tile-background: #fff;
  --tile-border-color: var(--border_color);
  --tile-divider-color: var(--border_color);

  position: relative;
  width: var(--tile-width);
  height: var(--tile-height);
  padding: var(--tile-padding);
  background-color: var(--tile-background);
  border: 1px solid var(--tile-border-color);
  border-radius: var(--border_radius);
  color: var(--primary);

  &:after,
  &:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(136, 183, 213, 0);
    border-top-color: var(--tile-background);
    border-width: 16px;
    margin-left: -16px;
  }

  &:before {
    border-color: rgba(194, 225, 245, 0);
    border-top-color: var(--tile-border-color);
    border-width: 17px;
    margin-left: -17px;
  }

  &:hover,
  &.hover {
    color: var(--primary);
    box-shadow: 1px 1px 16px 0 hsla(var(--hue), 0%, 44%, 0.3);
  }

  &:focus-visible,
  &.focus {
    box-shadow: none;
    --tile-border-color: var(--primary);
  }

  &:active,
  &.active {
    --tile-background: var(--primary);
    --tile-border-color: var(--primary);
    --tile-divider-color: var(--white);
    color: var(--white);
    background-color: var(--tile-background);
  }

  &[disabled],
  &.disabled {
    pointer-events: none;
    --tile-background: var(--rich100);
    --tile-border-color: var(--disabled);
    --tile-divider-color: var(--disabled);
    color: var(--disabled);

    .bgwhite & {
      --tile-background: var(--white);
    }
  }

  .title {
    padding: 0;
    margin: 0;

    &:after {
      content: '';
      width: 20px;
      height: 1px;
      border-bottom: 1px solid var(--tile-divider-color);
      display: block;
      margin: 2px auto 8px;
    }
  }
}
