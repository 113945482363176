.ui-spinner {
  position: relative;
  display: inline-block;
  overflow: visible;
  padding: 0;
  vertical-align: middle;

  .ui-spinner-input {
    padding-right: 2.429em;

    &:disabled {
      background-color: #e1e1e1;
      color: #c0c0c0;
      -webkit-text-fill-color: #c0c0c0;
    }
  }

  .ui-spinner-button {
    position: absolute;
    right: 0;
    display: block;
    overflow: hidden;
    width: 2em;
    width: 1.5em;
    height: 50%;
    padding: 0;
    margin: 0;
    cursor: default;
    text-align: center;
    vertical-align: middle;

    &:disabled {
      border-color: var(--disabled);
      background-color: #e1e1e1;
      color: #c0c0c0;
      -webkit-text-fill-color: #c0c0c0;
      opacity: 1;
    }
  }

  .ui-spinner-up {
    top: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0;
  }

  .ui-spinner-down {
    bottom: 0;
    border-top: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.p-inputnumber-buttons-stacked {
  position: relative;
  display: inline-block;
  overflow: visible;
  padding: 0;
  vertical-align: middle;

  .p-inputnumber-input {
    padding-right: 2.429em !important;

    .bgdark & {
      &:disabled {
        border-color: var(--dark_input_border);
        background-color: var(--dark_input_disabled_bg);
        color: var(--dark_input_disabled_text) !important;
        // -webkit-text-fill-color: var(--dark_input_disabled_text);
      }
    }

    &:disabled {
      background-color: #e1e1e1;
      color: #c0c0c0;
      -webkit-text-fill-color: #c0c0c0;
    }
  }

  .p-inputnumber-button {
    position: absolute;
    right: 0;
    overflow: hidden;
    width: 2em;
    width: 1.5em;
    height: 50%;
    padding: 0;
    margin: 0;

    .bgdark & {
      &:disabled {
        border-color: var(--dark_input_border);
        background-color: var(--dark_input_disabled_bg);
        color: var(--dark_input_disabled_text) !important;
        // -webkit-text-fill-color: var(--dark_input_disabled_text);
      }
    }

    &:disabled {
      border-color: var(--disabled);
      background-color: #e1e1e1;
      color: #c0c0c0;
      -webkit-text-fill-color: #c0c0c0;
      opacity: 1;
    }
  }

  .p-inputnumber-button-up {
    top: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0;
  }

  .p-inputnumber-button-down {
    bottom: 0;
    border-top: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
