.p-virtualscroller {
  .p-virtualscroller-list {
    padding: 0;
    margin: 0;
    li:not(:first-child) {
      border-top: 1px solid var(--border_color);
    }
    li:not(:last-child) {
      border-bottom: 1px solid var(--border_color);
    }
  }
  .p-virtualscroller-content {
    border: 1px solid var(--border_color);
    background-color: #ffffff;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
  .p-virtualscroller-header {
    padding: 0.75rem;
    border: 1px solid var(--border_color);
    background-color: transparent;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    color: var(--text);
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: bold;
    text-align: center;
  }

  .p-virtualscroller-item {
    button.scroller-item-content {
      text-align: left;
    }
  }
}
