.shepherd-element {
  max-width: 200px;
  .shepherd-content {
    font-size: var(--font_size_xs);

    .shepherd-text {
      padding: 1rem 1rem 1.5rem 1rem;
      font-size: var(--font_size_sm);
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: 0em;
      text-align: center;
    }
    header {
      height: 0px !important;
    }
    footer {
      padding: 0rem !important;
      background-color: #fff;
      position: inherit;
    }
    .shepherd-footer {
      padding: 0rem !important;
    }
    .shepherd-button {
      margin-right: 0rem;
    }
    .btn {
      margin-left: 0rem;
    }
  }
}
