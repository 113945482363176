.video-grid {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));

  .card-footer {
    padding: 0.5rem 0.75rem;
    background-color: var(--rich100);
    min-height: 60px;
    h4 {
      margin: 0;
    }
    a {
      text-decoration: underline;
    }
  }
}
.container-showcase-detail {
  padding: 0 2rem;
}

.video-display {
  display: flex;
  justify-content: space-between;
  iframe {
    flex-basis: 600px;
    flex-shrink: 0;
  }
  .video-content {
    margin-left: 2rem;
  }
  @include media-breakpoint-down(sm) {
    flex-direction: column;
    .video-content {
      margin-top: 1rem;
      margin-left: 0;
    }
  }
}

.showcase-grid-item {
  &:hover {
    cursor: pointer;

    .card {
      box-shadow: 0 3px 10px 2px rgba(0, 0, 0, 0.2);
      transform: translate(0, -1px);
      transition:
        transform 0.3s,
        box-shadow 0.3s;
    }
  }
}
