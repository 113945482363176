$table-width: 700px;
$number-of-columns: 5;

.table-cards {
  width: $table-width;

  &-row,
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .columns {
      display: flex;
      align-items: center;

      > div {
        // table width ÷ number of columns
        width: 140px;
        padding-right: 10px;
      }
    }

    .buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .btn-link {
        width: 40px;
      }

      > div {
        margin-left: 5px;
      }
    }
  }

  &-row {
    padding: 10px 10px 10px 20px;
    margin-bottom: -1px; // to overlap the borders
    border: 1px solid var(--border_color);
    background-image: url($gripper);
    background-position: 3px center;
    background-repeat: no-repeat;
  }

  &-header {
    padding: 10px 10px 10px 20px;
    margin-bottom: -1px;
    border: 1px solid var(--border_color);
    background-color: transparent;
  }

  .btn {
    min-width: auto;
  }
}
