.progress {
  box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
}

.spinner-sample {
  padding: 20px;

  &.spinner-sample-ko {
    background-color: #333;
  }
}

// circles
.mat-step-header .mat-step-icon {
  background-color: hsl(var(--hue), 11%, 63%);
}

// active/completed circles
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: var(--primary);
}

// Carla's changes 2/23/2023
.mat-step-icon.mat-step-icon-state-number.mat-step-icon-selected {
  background-color: white;
  border: 2px solid var(--primary);
  color: var(--primary);
}

.mat-step-label {
  // display: none !important;
  font-family: var(--font-family-sans-serif);
  font-size: var(--font_size_base);
}

// --------------------

// background color of control
.mat-stepper-horizontal,
.mat-stepper-vertical {
  background-color: transparent;
}

// step hover/focus
.mat-step-header.cdk-keyboard-focused,
.mat-step-header.cdk-program-focused,
.mat-step-header:hover {
  background-color: var(--rich200);
}

.process-completed-success {
  background-color: #fff;
  border-radius: 8px;
  padding: 2rem;
  width: 440px;
  margin: 16px auto;
  border: 1px solid var(--border_color);
}

.button-loader {
  // background: url('/assets/images/button-loader.svg') no-repeat scroll center center transparent;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='16' width='16' viewBox='0 0 16 16'%0A%3E%3Ctitle%3Ecircle anim 3%3C/title%3E%3Cg fill='currentColor' class='nc-icon-wrapper'%3E%3Cg class='nc-loop-circle-3-16-icon-f'%3E%3Cpath d='M8 16a8 8 0 1 1 8-8 1 1 0 0 1-2 0 6 6 0 1 0-1.8 4.286 1 1 0 1 1 1.4 1.428A7.956 7.956 0 0 1 8 16z' fill='currentColor' %3E%3C/path%3E%3C/g%3E%3Cstyle%3E .nc-loop-circle-3-16-icon-f %7B --animation-duration: 0.7s; transform-origin: 8px 8px; animation: nc-loop-circle-3-anim var(--animation-duration) infinite linear; %7D @keyframes nc-loop-circle-3-anim %7B 0%25 %7B transform: rotate(0); %7D 100%25 %7B transform: rotate(360deg); %7D %7D %3C/style%3E%3C/g%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: 1rem 0.65rem;
}
