@mixin accordion-icon-closed {
  display: inline-block;
  margin-top: 0;
  margin-right: -1rem;
  content: '\eb47';
  font-family: 'pbi-icon-outline';
  float: right;
  transform: rotate(0deg);
  transition: transform 200ms;
  background-image: none;
}

@mixin accordion-icon-open {
  content: '\eb47';
  font-family: 'pbi-icon-outline';
  transform: rotate(90deg);
  background-image: none;
}

.accordion {
  .accordion-item {
    margin-bottom: 0;
    border-color: var(--divider_color);
    box-shadow: none;
    border-radius: 0;
    border-left: 0;
    border-right: 0;
    background-color: transparent;
    .accordion-header {
      padding: 0.5rem 2rem 0.5rem 1rem;
      margin: 0;
      background-color: transparent;
      font-size: var(--font_size_base);
      font-family: var(--font_family_brand_regular);

      a {
        display: block;
      }
    }
  }

  .accordion-header {
    display: block;
    width: 100%;
    padding-right: 1.5rem;
    border-bottom: 0;
    font-weight: normal;

    h5 {
      margin: 0;
      font-size: var(--font_size_base);
      font-family: var(--font_family_brand_regular);
      line-height: 1;
    }
    &:focus-within {
      @include focused;
    }
    .accordion-button,
    .btn.btn-acc {
      width: 100%;
      padding: 0;
      border: 0;
      color: var(--hyperlink);
      font-size: inherit;
      text-align: left;
      white-space: initial;
      background-color: transparent;
      box-shadow: none;

      &::after {
        @include accordion-icon-closed;
      }

      &:not(.collapsed)::after {
        @include accordion-icon-closed;
      }

      &.collapsed {
        color: var(--text);
      }

      &:focus,
      &.focus,
      &:not(:disabled):not(.disabled):active:focus,
      &:not(:disabled):not(.disabled).active:focus {
        outline: none;
      }

      // &:focus-visible,
      // &.focus-visible,
      // &:not(:disabled):not(.disabled):active:focus-visible,
      // &:not(:disabled):not(.disabled).active:focus-visible {
      //   @include focused;
      // }

      // ng-bootstrap does not apply a class, but DOES toggle the aria-expanded
      &[aria-expanded='true'] {
        &::after {
          transform: rotate(90deg);
        }
      }

      &[aria-expanded='false'] {
        color: var(--text);

        &::after {
          @include accordion-icon-closed;
        }
      }

      &[aria-disabled='true'],
      &[disabled] {
        color: var(--disabled);
        cursor: not-allowed !important;
        pointer-events: none;

        &::after {
          @include accordion-icon-closed;
        }
      }
    }
  }

  .accordion-body {
    color: var(--text);
  }
}

// --  for general "collapse", not accordion groups ----------------------

.collapse-button {
  cursor: pointer;

  &:hover {
    color: var(--hyperlink);

    a::after {
      color: var(--hyperlink);
    }
  }

  a::after {
    @include accordion-icon-closed;
  }

  &.open a::after {
    @include accordion-icon-open;
  }
}

// -- COMPACT UI ----------------------------------------------

.compact-ui {
  .accordion {
    .accordion-header {
      font-size: var(--font_size_sm);
    }

    .accordion-body {
      font-size: var(--font_size_sm);
    }
  }
}
