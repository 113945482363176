.demo-side-menu {
  border: 1px solid #c0c0c0;
  margin-bottom: 32px;
  padding: 4px 0;
  background-color: var(--white);

  z-index: 4;
  display: flex;
  justify-content: space-around;
  align-items: baseline;

  * {
    font-family: var(--font_family_base) !important;
  }

  .bgdark & {
    background-color: #000;
  }

  @include media-breakpoint-up(md) {
    position: fixed;
    top: 70px;
    width: 100%;
    margin-bottom: 20px;
  }
}
