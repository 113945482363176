.p-carousel {
  display: flex;
  flex-direction: column;
}

.p-carousel-content {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.p-carousel-prev,
.p-carousel-next {
  align-self: center;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.p-carousel-container {
  display: flex;
  flex-direction: row;
}

.p-carousel-items-content {
  overflow: hidden;
  width: 100%;
}

.p-carousel-items-container {
  display: flex;
  flex-direction: row;

  .p-carousel-item {
    &.p-carousel-item-active {
      display: flex;
    }
  }
}

.p-carousel-indicators {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.p-carousel-indicator > button {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Vertical */
.p-carousel-vertical .p-carousel-container {
  flex-direction: column;
}

.p-carousel-vertical .p-carousel-items-container {
  flex-direction: column;
  height: 100%;
}

/* Keyboard Support */
.p-items-hidden .p-carousel-item {
  visibility: hidden;
}

.p-items-hidden .p-carousel-item.p-carousel-item-active {
  visibility: visible;
}

/* theme */
.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
  width: 2rem;
  height: 2rem;
  color: var(--gray500);
  border: 0 none;
  background: transparent;
  transition: box-shadow 0.15s;
  margin: 0.5rem;
  font-size: 20px;

  &.p-disabled {
    color: var(--gray300);
  }
}

// .p-carousel .p-carousel-content .p-carousel-prev {
//   span::before {
//     opacity: 1;
//     font-family: pbi-icon-mini;
//     content: '';
//   }
// }

// .p-carousel .p-carousel-content .p-carousel-next {
//   span::before {
//     opacity: 1;
//     font-family: pbi-icon-mini;
//     content: '';
//   }
// }

.p-carousel .p-carousel-content .p-carousel-prev:enabled:hover,
.p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
  color: var(--gray700);
  border-color: transparent;
  background: transparent;
}

.p-carousel .p-carousel-indicators {
  padding: 1rem;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator button {
  background-color: var(--gray400);
  opacity: 0.25;
  width: 0.5rem;
  height: 0.5rem;
  transition: box-shadow 0.15s;
  border-radius: 50%;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background: var(--gray400);
  opacity: 1;
  color: #ffffff;
}
