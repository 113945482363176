.card-sidebar {
  border: 1px solid var(--border_color);
  background: #fff;
  p:last-child {
    margin-bottom: 0;
  }
}

.for-more-info {
  margin-top: 0.5rem;
}
