$bar-color: #26c6da;
$spinner-color: #fff;

#loading-bar .bar {
  height: 2px;
  background: $bar-color;
}

/* Fancy blur effect */
#loading-bar .peg {
  box-shadow: $bar-color 1px 0 6px 1px;
}

#loading-bar-spinner {
  top: 6px;
  left: 2px;
}

#loading-bar-spinner .spinner-icon {
  border-top-color: $spinner-color;
  border-left-color: $spinner-color;
}

.progress-bar {
  background-color: var(--primary);
}

// unsure why NGB added text-bg-primary to the progress bar and set it to important
.progress-bar.text-bg-primary {
  background-color: var(--primary) !important;
}

.progress {
  background-color: var(--white);
  height: 1.25rem;
  border-radius: var(--border_radius);

  .bgwhite & {
    background-color: var(--rich100);
  }

  .bgdark & {
    background-color: var(--dark_input_bg);
    border: 1px solid var(--dark_input_border);
  }
}
