.line-clamp,.line-clamp-sm,.line-clamp-lg{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-line;
}
.line-clamp{
  -webkit-line-clamp: 3;
}
.line-clamp-sm{
  -webkit-line-clamp: 2;
}
.line-clamp-lg{
  -webkit-line-clamp: 5;
}   
