.pricing-plan-cards {
  @include media-breakpoint-down(md) {
    grid-column-gap: 0;
    grid-row-gap: 2rem;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
  }
  display: grid;
  grid-column-gap: 2px;
  grid-template-columns: repeat(4, 1fr);
  margin-bottom: 100px;
}

.pricing-plan-card {
  display: grid;
  border: 1px solid var(--border_color);
  background-color: #fff;
  border-radius: $border-radius;
  grid-template-areas: 'eyebrow' 'title' 'summary' 'price' 'pricedetail' 'button' 'features';
  grid-template-columns: 1fr;
  grid-template-rows: 32px 48px 64px 72px 48px 72px 1fr;

  .plan-eyebrow {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-area: eyebrow;
    visibility: hidden;
  }

  .plan-title {
    color: var(--secondary);
    font-family: var(--font_family_brand_regular);
    font-size: 1.5rem;
    grid-area: title;
    text-align: center;
  }

  .plan-summary {
    padding: 0 1rem;
    font-size: 1rem;
    grid-area: summary;
    line-height: 1.33;
    text-align: center;
  }

  .plan-price {
    color: var(--primary);
    font-family: var(--font_family_brand_light);
    font-size: 3rem;
    grid-area: price;
    letter-spacing: -0.05em;
    line-height: 1;
    text-align: center;

    span {
      vertical-align: top;

      &:first-child {
        font-size: 2rem;
        transform: translateY(4px);
        display: inline-block;
      }
    }

    .plan-price-interval {
      display: block;
      font-family: var(--font-family-sans-serif);
      font-size: var(--font_size_base);
      letter-spacing: normal;
      text-align: center;
    }
  }

  .plan-price-details {
    grid-area: pricedetail;
    line-height: 1.33;
    text-align: center;
  }

  .plan-button {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-area: button;
    h2 {
      color: var(--primary);
      margin: 0;
    }
  }

  .plan-features {
    padding-top: 1rem;
    margin: 1.5rem;
    border-top: 1px solid $border-color;
    grid-area: features;

    h5 {
      margin-top: 0;
      font-family: var(--font-family-sans-serif);
      font-weight: bold;
    }

    ul {
      padding-left: 1em;
    }
  }
}

@keyframes featuredPlan {
  from {
    box-shadow: 0 0 0 0 rgba(#000, 0);
    transform: scale(1);
  }

  to {
    box-shadow: 0 0 16px 2px rgba(#000, 0.25);
    transform: scale(1.05);
  }
}

.pricing-plan-card-featured {
  z-index: 3;
  // border: 2px solid var(--seondary);
  border-width: 2px;
  border-style: solid;
  border-color: var(--secondary);
  animation: featuredPlan 0.5s ease-out forwards;
  border-radius: $border-radius-lg;
  box-shadow: 0 0 16px 2px rgba(#000, 0.25);
  transform: scale(1.05);
  // transform: perspective(500px) translate3d(0, 0, 15px);
  .plan-eyebrow {
    background-color: var(--secondary);
    color: #fff;
    font-family: var(--font-family-sans-serif);
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    visibility: visible;
  }

  .plan-title {
    margin-top: 0.5rem;
  }
  .plan-price {
    color: var(--secondary);
  }

  h5 {
    color: var(--seondary);
  }
}

.pricing-plan-card-current {
  border: 2px solid var(--primary);

  .plan-price {
    color: var(--seondary);
  }

  h5 {
    color: var(--seondary);
  }
}
