.demo-image {
  margin: 0 0 1rem;

  a {
    position: relative;
    display: inline-block;
    background-color: var(--text);
    cursor: pointer;

    img {
      border: 1px solid var(--border_color);
      opacity: 1;
      transition: opacity 0.2s;
    }

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      display: block;
      padding: 6px 12px;
      color: #fff;
      content: 'View Demo';
      font-family: 'PrecisionSans_W_Rg', 'Helvetica Neue', Arial, sans-serif;
      font-size: 18px;
      opacity: 0;
      transition: opacity 0.3s;
    }

    &:hover {
      &::before {
        opacity: 1;
      }

      img {
        opacity: 0.2;
      }
    }
  }
}
