/* Button */

.p-button {
  -webkit-appearance: none;
  align-items: center;
  background-color: var(--btn_secondary_bg);
  background-image: none;
  border-radius: var(--border_radius);
  border: 1px solid var(--btn_primary);
  color: var(--btn_primary);
  cursor: pointer;
  display: inline-flex;
  font-size: var(--font_size_base);
  font-family: var(--font_family_brand_regular);
  line-height: var(--line_height_base);
  margin-right: 0.1em;
  overflow: visible;
  padding: 0.5rem 0.75rem;
  position: relative;
  text-align: center;
  text-decoration: none !important;
  user-select: none;
  vertical-align: bottom;
  zoom: 1;

  .p-button-icon-left {
    margin-right: 4px;
  }

  &:hover,
  &.hover,
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    border-color: var(--btn_primary);
    background-color: var(--btn_secondary_bg_active);
    color: var(--btn_primary);
  }
  &.p-disabled {
    border-color: var(--disabled);
    color: var(--disabled);
    opacity: 1;
    &:hover {
      border-color: var(--disabled);
      color: var(--disabled);
      background-color: transparent;
    }
  }
}

.p-button-label {
  flex: 1 1 auto;
}

.p-button-icon-right {
  order: 1;
}

.p-button:disabled {
  cursor: default;
}

.p-button-icon-only {
  justify-content: center;
}

.p-button-icon-only .p-button-label {
  visibility: hidden;
  width: 0;
  flex: 0 0 auto;
}

.p-button-vertical {
  flex-direction: column;
}

.p-button-icon-bottom {
  order: 2;
}

.p-buttonset .p-button {
  margin: 0;
}

.p-buttonset .p-button:not(:last-child) {
  border-right: 0 none;
}

.p-buttonset .p-button:not(:first-of-type):not(:last-of-type) {
  border-radius: 0;
}

.p-buttonset .p-button:first-of-type {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.p-buttonset .p-button:last-of-type {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.p-buttonset .p-button:focus {
  position: relative;
  z-index: 1;
}
