.doc-text-block,
.dtb {
  width: 80%;
  margin-bottom: 1rem;
}
.doc-lede,
.dl {
  width: 80%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 16px;
  line-height: 1.5;
  p {
    font-size: 16px;
    line-height: 1.5;
  }
}

h3 {
  .badge {
    transform: translateY(-2px);
    display: inline-block;
  }
}

h2 {
  .badge {
    transform: translateY(-3px);
    display: inline-block;
  }
}

.demo ~ #beacon-container {
  display: none !important;
}
