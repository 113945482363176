.doc-color-picker-example {
  position: relative;
  height: 150px;

  &-image {
    background-image: url('/assets/images/color-picker/background-image.jpg');
    background-size: cover;
  }

  &-swatch {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.doc-swatch-picker {
  display: inline-block;
  padding: 0.25rem;
  border: 1px solid $border-color;
  border-radius: $border-radius;

  &-swatch {
    width: 100px;
    height: 40px;
    cursor: pointer;
  }
}
