$startcolor: #444fa9;
$endcolor: #c6138a;

.hero-bgimage {
  background-image: linear-gradient(135deg, rgba($startcolor, 0.9), rgba($endcolor, 0.9)),
    url("/assets/images/hero_bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.promo-hero {
  .hero {
    padding-top: 100px;
  }
}

.btn-got-it {
  min-width: 7rem;
}
