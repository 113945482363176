.mat-mdc-checkbox {
  font-family: var(--font-family-sans-serif);
  color: var(--text);
}

.controls-stacked {
  margin: 1rem 0;
}

.controls-stacked .control,
.controls-stacked .progress {
  display: block;
}

.controls-stacked .control + .control,
.controls-stacked .progress + .progress {
  margin-top: 0.5rem;
}

.controls-inline {
  margin: 1rem 0;
}

// for case when the controls-inline are used in a form
// that's already set to form-inline
.form-inline .controls-inline .control.radio {
  padding-left: 1.5rem;
}

// for case when the controls are used in a form-horizontal class
.form-horizontal {
  .radio,
  .checkbox,
  .radio-inline,
  .checkbox-inline {
    padding-top: 0;
  }
}

.controls-inline .control {
  display: inline-block;
  // height: 1rem;
}

.controls-inline .control + .control {
  margin-left: 1rem;
}

.form-check-input:focus {
  @include focused;
  outline-offset: 2px;
  border-color: var(--border_color);

  &[type='radio'] {
    border-radius: 50%;
  }
}

.form-check-input:disabled ~ .form-check-label,
.form-check-input[disabled] ~ .form-check-label {
  color: var(--disabled);
}

// add class to input to style OS checkboxes like the material ones
input[type='checkbox'].custom-checkbox {
  appearance: none;
  margin: 0 4px 0 0;
  font: inherit;
  color: currentColor;
  width: 1rem;
  height: 1rem;
  border: 1px solid var(--btn_primary);
  background-color: var(--white);
  line-height: 1.25;
  border-radius: 2px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transform: translateY(2px);

  .bgdark & {
    background-color: var(--dark_input_bg);
  }

  &:checked {
    background-color: var(--btn_primary);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 50% 50%;
    color: #fff;
  }

  + label {
    margin-left: 4px;
    margin-bottom: 0;
    text-transform: none;
    letter-spacing: normal;
    font-size: var(--font_size_base);
    transform: translateY(2px);
  }
}

.check-toggle {
  input[type='checkbox'] {
    clip-path: inset(100%);
    position: absolute;
  }
}

input[type='checkbox'],
input[type='radio'] {
  accent-color: var(--btn_primary);
}

.form-check-input:checked {
  background-color: var(--btn_primary);
  border-color: var(--btn_primary);
}
