// Options
//
// Quickly modify global styling by enabling or disabling optional features.
$enable-validation-icons: false;
$enable-important-utilities: false;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// scss-docs-start spacer-variables-maps
$spacer: 1rem !default;
$spacers: (
  // 0px
  0: 0,
  // 4px
  1: $spacer * 0.25,
  // 8px
  2: $spacer * 0.5,
  // 16px
  3: $spacer,
  // 24px
  4: $spacer * 1.5,
  // 32px
  5: $spacer * 2,
  // 40px
  6: $spacer * 2.5,
  // 48px
  7: $spacer * 3
) !default;
// scss-docs-end spacer-variables-maps

// Body
//
// Settings for the `<body>` element.

// $body-bg: var(--white) !default; //TODO fix using css vars
// $body-color: var(--text) !default; //TODO fix using css vars

// Links
//
// Style anchor elements.

$link-decoration: none;
$link-hover-decoration: underline;

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-gutter-width: 2rem;

// Components
//
// Define common padding and border radius sizes and more.

// scss-docs-start border-variables
$border-color: var(--border_color);
$border-radius: 2px;
$border-radius-lg: 4px; // FIXME the border radius should be the same sizes, fix in DS16
$border-radius-sm: 0;
// scss-docs-end border-variables

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// scss-docs-start type-variables
$lead-font-size: 1rem;
$lead-font-weight: normal;
$list-inline-padding: 4px;
// scss-docs-end type-variables

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

// scss-docs-start table-variables
$table-cell-padding: 0.75rem; // FIXME use BS5 variables for padding (x and y) and update components
$table-cell-padding-sm: 0.3rem;
$table-cell-padding-lg: 1rem;

$table-striped-order: even;
// scss-docs-end table-variables

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

// scss-docs-start input-btn-variables
$input-btn-padding-y: 0.5rem;
$input-btn-padding-x: 0.75rem;
$input-btn-line-height: 1.25;

$input-btn-line-height-sm: 1.5;
$input-btn-line-height-lg: 1.5;
// scss-docs-end input-btn-variables

// Form states and alerts
//
//Define colors for form feedback states and, by default, alerts.

// FIXME DS16 why are these custom variables? Use defined colors instead?
$state-success-text: var(--success);
$state-success-bg: #f2f9f2;
$state-success-border: var(--success);
$state-info-text: var(--info);
$state-info-bg: #f4f9fd;
$state-info-border: var(--info);
$state-warning-text: var(--warning);
$state-warning-bg: #fcf6f2;
$state-warning-border: var(--warning);
$state-danger-text: var(--danger);
$state-danger-bg: #fdf2f2;
$state-danger-border: var(--danger);

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

// scss-docs-start zindex-stack
// FIXME DS16 why do we override these values?
$zindex-modal-backdrop: 1040;
$zindex-modal: 1050;
$zindex-popover: 1060;
$zindex-tooltip: 1070;
// scss-docs-end zindex-stack

// Navs

// scss-docs-start nav-variables
$nav-link-padding-y: 1.5rem; // increased from .5 to match our 75 pixel height
$nav-link-disabled-color: var(--disabled);

$nav-tabs-border-color: $border-color;
$nav-tabs-border-radius: 0;
// scss-docs-end nav-variables

// Navbar

// scss-docs-start navbar-variables
// $navbar-nav-link-padding-x: 0.5rem !default;
$font-size-base: 0.875rem; // 14px  // FIXME DS16 why do we decrease the font size here? not base 16
$font-size-lg: ($font-size-base * 1.25); // 16px (1 rem)
$font-size-sm: ($font-size-base * 0.857); // 12px (.75 rem)
$font-size-xs: ($font-size-base * 0.625); // 10px
$navbar-brand-font-size: ($font-size-lg * 1.5);

// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
// FIXME DS16 why are these hard-coded heights?
$nav-link-height: 36px;
$navbar-brand-height: 69px;
$navbar-brand-padding-y: 16px;
$navbar-brand-height-responsive: 3.625rem; //FIXME DS16 prefix custom variables with pbds
// scss-docs-end navbar-variables

// Badges

// scss-docs-start badge-variables
$badge-font-size: 0.75rem;
$badge-font-weight: normal;
$badge-color: var(--white);
$badge-padding-y: 0.3rem;
$badge-padding-x: 0.5rem;
$badge-pill-padding-x: 0.6em;
// scss-docs-end badge-variables

// Progress bars

// scss-docs-start progress-variables
$progress-height: 1.25rem;
$progress-font-size: 0.75rem;
$progress-bg: var(--rich100);
// scss-docs-end progress-variables

// List group

// scss-docs-start list-group-variables
$list-group-item-padding-y: 0.75rem;
$list-group-item-padding-x: 1.25rem;

$list-group-disabled-color: var(--rich500);
// scss-docs-start list-group-variables

// Breadcrumbs

// scss-docs-start breadcrumb-variables
$breadcrumb-bg: transparent;
$breadcrumb-divider-color: var(--divider_color);
$breadcrumb-active-color: var(--primary);
$breadcrumb-divider: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="9" height="14"> <path fill="silver" fill-rule="nonzero" d="M5.586 7L.293 1.707 1.707.293 8.414 7l-6.707 6.707-1.414-1.414z"/> </svg>');
// scss-docs-end breadcrumb-variables

// Close
$close-color: var(--text);

// Code

$code-font-size: 90%;
$code-color: #bd4147;
