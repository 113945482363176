@font-face {
  font-family: 'pbi-icon-mini';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/pbi-icon-mini.woff2?tp0qrw') format('woff2'),
    url('../fonts/pbi-icon-mini.ttf?tp0qrw') format('truetype'),
    url('../fonts/pbi-icon-mini.woff?tp0qrw') format('woff'),
    url('../fonts/pbi-icon-mini.svg?tp0qrw#pbi-icon-mini') format('svg');
}

.pbi-icon-mini {
  display: inline-block;

  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'pbi-icon-mini' !important;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: none;
  text-transform: none;
}

//spinning
.pbi-icon-mini.spin {
  -webkit-animation: pbi-icon-spin 2s infinite linear;
  -moz-animation: pbi-icon-spin 2s infinite linear;
  animation: pbi-icon-spin 2s infinite linear;
}

@keyframes pbi-icon-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

// quick scaling
.pbi-icon-mini.lg {
  font-size: 1.33333333em;
  vertical-align: -16%;
}

.pbi-icon-mini.x2 {
  font-size: 2rem;
}

.pbi-icon-mini.x3 {
  font-size: 3rem;
}

.pbi-icon-mini.x4 {
  font-size: 4rem;
}

.pbi-icon-mini.x5 {
  font-size: 5rem;
}

.pbi-icon-mini.x6 {
  font-size: 6rem;
}

// rotate and flip
.pbi-icon-mini.rotate-90 {
  @include pbi-rotate(90deg, 1);
}

.pbi-icon-mini.rotate-180 {
  @include pbi-rotate(180deg, 2);
}

.pbi-icon-mini.rotate-270 {
  @include pbi-rotate(270deg, 3);
}

.pbi-icon-mini.flip-y {
  @include pbi-flip(-1, 1, 0);
}

.pbi-icon-mini.flip-x {
  @include pbi-flip(1, -1, 2);
}

// the icons
// * NOTE: for icons to work, each needs to be prepended with .pbi-icon-mini
.pbi-icon-mini.pbi-lockers:before {
  content: '\e95d';
}

.pbi-icon-mini.pbi-end-of-day:before {
  content: '\e95e';
}

.pbi-icon-mini.pbi-history:before {
  content: '\e95f';
}

.pbi-icon-mini.pbi-stamps:before {
  content: '\e960';
}

.pbi-icon-mini.pbi-receiving:before {
  content: '\e961';
}

.pbi-icon-mini.pbi-no-image:before {
  content: '\e95a';
}

.pbi-icon-mini.pbi-archive-drawer:before {
  content: '\e958';
}

.pbi-icon-mini.pbi-wand:before {
  content: '\e959';
}

.pbi-icon-mini.pbi-shipping-label::before {
  content: '\e957';
}

.pbi-icon-mini.pbi-batch::before {
  content: '\e956';
}

.pbi-icon-mini.pbi-link-external::before {
  content: '\e955';
}

.pbi-icon-mini.pbi-column-toggle::before {
  content: '\e954';
}

.pbi-icon-mini.pbi-small-triangle-updown::before {
  content: '\e953';
}

.pbi-icon-mini.pbi-insights::before {
  content: '\e951';
}

.pbi-icon-mini.pbi-support::before {
  content: '\e952';
}

.pbi-icon-mini.pbi-distribute-horizontal::before {
  content: '\e94f';
}

.pbi-icon-mini.pbi-distribute-vertical::before {
  content: '\e950';
}

.pbi-icon-mini.pbi-account-switcher::before {
  content: '\e94e';
}

.pbi-icon-mini.pbi-headset::before {
  content: '\e948';
}

.pbi-icon-mini.pbi-check-square::before {
  content: '\e949';
}

.pbi-icon-mini.pbi-delete-square::before {
  content: '\e94a';
}

.pbi-icon-mini.pbi-remove-square::before {
  content: '\e94b';
}

.pbi-icon-mini.pbi-refresh-2::before {
  content: '\e947';
}

.pbi-icon-mini.pbi-glasses::before {
  content: '\e944';
}

.pbi-icon-mini.pbi-collapse-all::before {
  content: '\e945';
}

.pbi-icon-mini.pbi-expand-all::before {
  content: '\e946';
}

.pbi-icon-mini.pbi-basket::before {
  content: '\e95c';
}

.pbi-icon-mini.pbi-mail-outline::before {
  content: '\e93f';
}

.pbi-icon-mini.pbi-telephone::before {
  content: '\e940';
}

.pbi-icon-mini.pbi-bullet::before {
  content: '\e941';
}

.pbi-icon-mini.pbi-globe-2::before {
  content: '\e942';
}

.pbi-icon-mini.pbi-preferences::before {
  content: '\e943';
}

.pbi-icon-mini.pbi-text-underline::before {
  content: '\e93c';
}

.pbi-icon-mini.pbi-text-align-center::before {
  content: '\e91f';
}

.pbi-icon-mini.pbi-text-align-justify::before {
  content: '\e920';
}

.pbi-icon-mini.pbi-text-align-left::before {
  content: '\e921';
}

.pbi-icon-mini.pbi-text-align-right::before {
  content: '\e922';
}

.pbi-icon-mini.pbi-text-margin-left::before {
  content: '\e923';
}

.pbi-icon-mini.pbi-text-margin-right::before {
  content: '\e924';
}

.pbi-icon-mini.pbi-text-bold::before {
  content: '\e925';
}

.pbi-icon-mini.pbi-text-italic::before {
  content: '\e926';
}

.pbi-icon-mini.pbi-text-code::before {
  content: '\e927';
}

.pbi-icon-mini.pbi-chart-bars::before {
  content: '\e93d';
}

.pbi-icon-mini.pbi-chart-bars-axis::before {
  content: '\e928';
}

.pbi-icon-mini.pbi-cursor-arrow-left::before {
  content: '\e929';
}

.pbi-icon-mini.pbi-add-small::before {
  content: '\e92a';
}

.pbi-icon-mini.pbi-delete-small::before {
  content: '\e92b';
}

.pbi-icon-mini.pbi-remove-small::before {
  content: '\e92c';
}

.pbi-icon-mini.pbi-add-bold::before {
  content: '\e92d';
}

.pbi-icon-mini.pbi-delete-bold::before {
  content: '\e92e';
}

.pbi-icon-mini.pbi-arrow-bold-down::before {
  content: '\e92f';
}

.pbi-icon-mini.pbi-arrow-bold-up::before {
  content: '\e930';
}

.pbi-icon-mini.pbi-arrow-bold-left::before {
  content: '\e931';
}

.pbi-icon-mini.pbi-arrow-bold-right::before {
  content: '\e932';
}

.pbi-icon-mini.pbi-arrow-double-left::before {
  content: '\e934';
}

.pbi-icon-mini.pbi-arrow-double-right::before {
  content: '\e935';
}

.pbi-icon-mini.pbi-check-bold::before {
  content: '\e936';
}

.pbi-icon-mini.pbi-menu-dots::before {
  content: '\e937';
}

.pbi-icon-mini.pbi-palette::before {
  content: '\e938';
}

.pbi-icon-mini.pbi-shopping-tag::before {
  content: '\e939';
}

.pbi-icon-mini.pbi-mobile-button::before {
  content: '\e93a';
}

.pbi-icon-mini.pbi-tablet-simple::before {
  content: '\e93b';
}

.pbi-icon-mini.pbi-tablet-button::before {
  content: '\e93e';
}

.pbi-icon-mini.pbi-toggle-overview::before {
  content: '\e91e';
}

.pbi-icon-mini.pbi-report::before {
  content: '\e919';
}

.pbi-icon-mini.pbi-slider::before {
  content: '\e91a';
}

.pbi-icon-mini.pbi-magnet::before {
  content: '\e91b';
}

.pbi-icon-mini.pbi-ban::before {
  content: '\e91c';
}

.pbi-icon-mini.pbi-archive-check::before {
  content: '\e91d';
}

.pbi-icon-mini.pbi-circle-down::before {
  content: '\e914';
}

.pbi-icon-mini.pbi-circle-up::before {
  content: '\e915';
}

.pbi-icon-mini.pbi-circle-left::before {
  content: '\e916';
}

.pbi-icon-mini.pbi-circle-right::before {
  content: '\e917';
}

.pbi-icon-mini.pbi-minimal-down::before {
  content: '\e90e';
}

.pbi-icon-mini.pbi-minimal-up::before {
  content: '\e90f';
}

.pbi-icon-mini.pbi-minimal-left::before {
  content: '\ea0e';
}

.pbi-icon-mini.pbi-minimal-right::before {
  content: '\ea8e';
}

.pbi-icon-mini.pbi-small-triangle-down::before {
  content: '\ea33';
}

.pbi-icon-mini.pbi-small-triangle-up::before {
  content: '\ea0c';
}

.pbi-icon-mini.pbi-small-triangle-left::before {
  content: '\e910';
}

.pbi-icon-mini.pbi-small-triangle-right::before {
  content: '\e911';
}

.pbi-icon-mini.pbi-tail-up::before {
  content: '\e900';
}

.pbi-icon-mini.pbi-tail-down::before {
  content: '\e901';
}

.pbi-icon-mini.pbi-fullscreen::before {
  content: '\ea48';
}

.pbi-icon-mini.pbi-reduce-screen::before {
  content: '\ea9d';
}

.pbi-icon-mini.pbi-screen-full::before {
  content: '\e90b';
}

.pbi-icon-mini.pbi-screen-reduce::before {
  content: '\e90c';
}

.pbi-icon-mini.pbi-undo::before {
  content: '\eab9';
}

.pbi-icon-mini.pbi-redo::before {
  content: '\ea9c';
}

.pbi-icon-mini.pbi-refresh::before {
  content: '\ea9e';
}

.pbi-icon-mini.pbi-download::before {
  content: '\ea34';
}

.pbi-icon-mini.pbi-upload::before {
  content: '\eabb';
}

.pbi-icon-mini.pbi-import::before {
  content: '\ea68';
}

.pbi-icon-mini.pbi-export::before {
  content: '\ea39';
}

.pbi-icon-mini.pbi-sign-in::before {
  content: '\eaa6';
}

.pbi-icon-mini.pbi-sign-out::before {
  content: '\eaa7';
}

.pbi-icon-mini.pbi-cloud-download::before {
  content: '\ea22';
}

.pbi-icon-mini.pbi-cloud-upload::before {
  content: '\ea23';
}

.pbi-icon-mini.pbi-bell::before {
  content: '\e902';
}

.pbi-icon-mini.pbi-user-circle::before {
  content: '\e903';
}

.pbi-icon-mini.pbi-loader-circle::before {
  content: '\e904';
}

.pbi-icon-mini.pbi-drop::before {
  content: '\e905';
}

.pbi-icon-mini.pbi-list::before {
  content: '\e909';
}

.pbi-icon-mini.pbi-pin-add::before {
  content: '\e90a';
}

.pbi-icon-mini.pbi-pin-delete::before {
  content: '\e912';
}

.pbi-icon-mini.pbi-delete::before {
  content: '\e90d';
}

.pbi-icon-mini.pbi-add::before {
  content: '\ea03';
}

.pbi-icon-mini.pbi-close::before {
  content: '\ea20';
}

.pbi-icon-mini.pbi-check::before {
  content: '\e918';
}

.pbi-icon-mini.pbi-trash::before {
  content: '\eab5';
}

.pbi-icon-mini.pbi-pencil::before {
  content: '\ea37';
}

.pbi-icon-mini.pbi-mail::before {
  content: '\ea81';
}

.pbi-icon-mini.pbi-add-query-builder::before {
  content: '\ea05';
}

.pbi-icon-mini.pbi-address-book::before {
  content: '\ea06';
}

.pbi-icon-mini.pbi-add-circle::before {
  content: '\ea04';
}

.pbi-icon-mini.pbi-help-circle::before {
  content: '\ea4d';
}

.pbi-icon-mini.pbi-help-circle-open::before {
  content: '\ea4e';
}

.pbi-icon-mini.pbi-alert-circle::before {
  content: '\ea07';
}

.pbi-icon-mini.pbi-alert-circle-open::before {
  content: '\ea08';
}

.pbi-icon-mini.pbi-info-circle::before {
  content: '\ea6a';
}

.pbi-icon-mini.pbi-info-circle-open::before {
  content: '\ea6b';
}

.pbi-icon-mini.pbi-delete-circle::before {
  content: '\ea31';
}

.pbi-icon-mini.pbi-delete-circle-open::before {
  content: '\e913';
}

.pbi-icon-mini.pbi-warning-triangle::before {
  content: '\eabe';
}

.pbi-icon-mini.pbi-warning-triangle-open::before {
  content: '\eabf';
}

.pbi-icon-mini.pbi-check-circle::before {
  content: '\ea1d';
}

.pbi-icon-mini.pbi-check-circle-open::before {
  content: '\ea1e';
}

.pbi-icon-mini.pbi-apps::before {
  content: '\ea0b';
}

.pbi-icon-mini.pbi-grid-4::before {
  content: '\e908';
}

.pbi-icon-mini.pbi-attachment::before {
  content: '\ea0d';
}

.pbi-icon-mini.pbi-bank::before {
  content: '\ea0f';
}

.pbi-icon-mini.pbi-betweeness::before {
  content: '\ea10';
}

.pbi-icon-mini.pbi-bluetooth::before {
  content: '\ea11';
}

.pbi-icon-mini.pbi-box::before {
  content: '\ea12';
}

.pbi-icon-mini.pbi-calculator::before {
  content: '\ea13';
}

.pbi-icon-mini.pbi-calendar::before {
  content: '\ea14';
}

.pbi-icon-mini.pbi-camera::before {
  content: '\ea15';
}

.pbi-icon-mini.pbi-camera-off::before {
  content: '\ea16';
}

.pbi-icon-mini.pbi-cart::before {
  content: '\ea17';
}

.pbi-icon-mini.pbi-catalog::before {
  content: '\ea18';
}

.pbi-icon-mini.pbi-catalog-add-to::before {
  content: '\ea19';
}

.pbi-icon-mini.pbi-catalog-new::before {
  content: '\ea1a';
}

.pbi-icon-mini.pbi-chat::before {
  content: '\ea1b';
}

.pbi-icon-mini.pbi-chat-conversation::before {
  content: '\ea1c';
}

.pbi-icon-mini.pbi-clipboard::before {
  content: '\ea1f';
}

.pbi-icon-mini.pbi-closeness::before {
  content: '\ea21';
}

.pbi-icon-mini.pbi-connection::before {
  content: '\ea24';
}

.pbi-icon-mini.pbi-connection-database::before {
  content: '\ea25';
}

.pbi-icon-mini.pbi-connection-filesystem::before {
  content: '\ea26';
}

.pbi-icon-mini.pbi-connection-new::before {
  content: '\ea27';
}

.pbi-icon-mini.pbi-credit-card::before {
  content: '\ea28';
}

.pbi-icon-mini.pbi-currency-dollar::before {
  content: '\ea29';
}

.pbi-icon-mini.pbi-currency-pound::before {
  content: '\ea2a';
}

.pbi-icon-mini.pbi-currency-euro::before {
  content: '\ea2b';
}

.pbi-icon-mini.pbi-currency-yen::before {
  content: '\ea2c';
}

.pbi-icon-mini.pbi-scissors::before {
  content: '\ea2d';
}

.pbi-icon-mini.pbi-dashboard::before {
  content: '\ea2e';
}

.pbi-icon-mini.pbi-database::before {
  content: '\ea2f';
}

.pbi-icon-mini.pbi-degree::before {
  content: '\ea30';
}

.pbi-icon-mini.pbi-delivery-fast::before {
  content: '\ea32';
}

.pbi-icon-mini.pbi-duplicate::before {
  content: '\ea36';
}

.pbi-icon-mini.pbi-entity::before {
  content: '\ea38';
}

.pbi-icon-mini.pbi-star::before {
  content: '\ea3c';
}

.pbi-icon-mini.pbi-file::before {
  content: '\ea3d';
}

.pbi-icon-mini.pbi-file-content::before {
  content: '\ea3e';
}

.pbi-icon-mini.pbi-filter::before {
  content: '\ea3f';
}

.pbi-icon-mini.pbi-flag::before {
  content: '\ea40';
}

.pbi-icon-mini.pbi-flash::before {
  content: '\ea41';
}

.pbi-icon-mini.pbi-folder::before {
  content: '\ea42';
}

.pbi-icon-mini.pbi-folder-add::before {
  content: '\e906';
}

.pbi-icon-mini.pbi-folder-remove::before {
  content: '\e907';
}

.pbi-icon-mini.pbi-folder-open::before {
  content: '\ea46';
}

.pbi-icon-mini.pbi-folder-outline::before {
  content: '\ea43';
}

.pbi-icon-mini.pbi-folder-add-to::before {
  content: '\ea44';
}

.pbi-icon-mini.pbi-folder-new::before {
  content: '\ea45';
}

.pbi-icon-mini.pbi-globe::before {
  content: '\ea4a';
}

.pbi-icon-mini.pbi-heart::before {
  content: '\ea4c';
}

.pbi-icon-mini.pbi-higher-value::before {
  content: '\ea4f';
}

.pbi-icon-mini.pbi-history::before {
  content: '\ea50';
}

.pbi-icon-mini.pbi-home::before {
  content: '\ea51';
}

.pbi-icon-mini.pbi-hubvis-canvas::before {
  content: '\ea52';
}

.pbi-icon-mini.pbi-hubvis-circular::before {
  content: '\ea53';
}

.pbi-icon-mini.pbi-hubvis-cluster::before {
  content: '\ea54';
}

.pbi-icon-mini.pbi-hubvis-expand-arrow::before {
  content: '\ea55';
}

.pbi-icon-mini.pbi-hubvis-graph::before {
  content: '\ea56';
}

.pbi-icon-mini.pbi-hubvis-hierarchy::before {
  content: '\ea57';
}

.pbi-icon-mini.pbi-hubvis-load-pred::before {
  content: '\ea58';
}

.pbi-icon-mini.pbi-hubvis-load-succ::before {
  content: '\ea59';
}

.pbi-icon-mini.pbi-hubvis-map::before {
  content: '\ea5a';
}

.pbi-icon-mini.pbi-hubvis-model::before {
  content: '\ea5b';
}

.pbi-icon-mini.pbi-hubvis-organic::before {
  content: '\ea5c';
}

.pbi-icon-mini.pbi-hubvis-orthogonal::before {
  content: '\ea5d';
}

.pbi-icon-mini.pbi-hubvis-query-selection::before {
  content: '\ea5e';
}

.pbi-icon-mini.pbi-hubvis-radial::before {
  content: '\ea5f';
}

.pbi-icon-mini.pbi-hubvis-remove-filter::before {
  content: '\ea60';
}

.pbi-icon-mini.pbi-hubvis-select-entires::before {
  content: '\ea61';
}

.pbi-icon-mini.pbi-hubvis-select-pred::before {
  content: '\ea62';
}

.pbi-icon-mini.pbi-hubvis-select-succ::before {
  content: '\ea63';
}

.pbi-icon-mini.pbi-hubvis-table::before {
  content: '\ea64';
}

.pbi-icon-mini.pbi-hubvis-tree::before {
  content: '\ea65';
}

.pbi-icon-mini.pbi-hubvis-unload::before {
  content: '\ea66';
}

.pbi-icon-mini.pbi-bulb::before {
  content: '\ea67';
}

.pbi-icon-mini.pbi-influence::before {
  content: '\ea69';
}

.pbi-icon-mini.pbi-logo-instagram::before {
  content: '\ea6c';
}

.pbi-icon-mini.pbi-key::before {
  content: '\ea6d';
}

.pbi-icon-mini.pbi-lock::before {
  content: '\ea7f';
}

.pbi-icon-mini.pbi-unlock::before {
  content: '\eaba';
}

.pbi-icon-mini.pbi-label::before {
  content: '\ea6e';
}

.pbi-icon-mini.pbi-label-auto::before {
  content: '\ea6f';
}

.pbi-icon-mini.pbi-label-layer::before {
  content: '\ea70';
}

.pbi-icon-mini.pbi-label-select::before {
  content: '\ea71';
}

.pbi-icon-mini.pbi-layer::before {
  content: '\ea72';
}

.pbi-icon-mini.pbi-layer-group::before {
  content: '\ea73';
}

.pbi-icon-mini.pbi-layer-new::before {
  content: '\ea74';
}

.pbi-icon-mini.pbi-layers::before {
  content: '\ea75';
}

.pbi-icon-mini.pbi-line::before {
  content: '\ea76';
}

.pbi-icon-mini.pbi-like::before {
  content: '\ea77';
}

.pbi-icon-mini.pbi-link::before {
  content: '\ea78';
}

.pbi-icon-mini.pbi-link-broken::before {
  content: '\ea79';
}

.pbi-icon-mini.pbi-list-bullet::before {
  content: '\ea7b';
}

.pbi-icon-mini.pbi-list-numbers::before {
  content: '\ea7c';
}

.pbi-icon-mini.pbi-location::before {
  content: '\ea7d';
}

.pbi-icon-mini.pbi-location-pin::before {
  content: '\ea7e';
}

.pbi-icon-mini.pbi-lower-value::before {
  content: '\ea80';
}

.pbi-icon-mini.pbi-map-folded::before {
  content: '\ea82';
}

.pbi-icon-mini.pbi-map-world::before {
  content: '\ea83';
}

.pbi-icon-mini.pbi-map-world-new::before {
  content: '\ea84';
}

.pbi-icon-mini.pbi-map-marker::before {
  content: '\ea85';
}

.pbi-icon-mini.pbi-media-player::before {
  content: '\ea86';
}

.pbi-icon-mini.pbi-menu::before {
  content: '\ea87';
}

.pbi-icon-mini.pbi-metadata::before {
  content: '\ea88';
}

.pbi-icon-mini.pbi-metadata-template::before {
  content: '\ea89';
}

.pbi-icon-mini.pbi-microphone::before {
  content: '\ea8a';
}

.pbi-icon-mini.pbi-money::before {
  content: '\ea8b';
}

.pbi-icon-mini.pbi-monitor::before {
  content: '\ea8c';
}

.pbi-icon-mini.pbi-rewind::before {
  content: '\ea9f';
}

.pbi-icon-mini.pbi-previous::before {
  content: '\ea98';
}

.pbi-icon-mini.pbi-pause::before {
  content: '\ea8f';
}

.pbi-icon-mini.pbi-stop::before {
  content: '\eaaf';
}

.pbi-icon-mini.pbi-next::before {
  content: '\ea47';
}

.pbi-icon-mini.pbi-fast-forward::before {
  content: '\ea3b';
}

.pbi-icon-mini.pbi-phone::before {
  content: '\ea91';
}

.pbi-icon-mini.pbi-image::before {
  content: '\ea92';
}

.pbi-icon-mini.pbi-play::before {
  content: '\ea94';
}

.pbi-icon-mini.pbi-plug::before {
  content: '\ea95';
}

.pbi-icon-mini.pbi-point::before {
  content: '\ea96';
}

.pbi-icon-mini.pbi-polygon::before {
  content: '\ea97';
}

.pbi-icon-mini.pbi-printer::before {
  content: '\ea99';
}

.pbi-icon-mini.pbi-property-gradient::before {
  content: '\ea9a';
}

.pbi-icon-mini.pbi-query-builder::before {
  content: '\ea9b';
}

.pbi-icon-mini.pbi-save::before {
  content: '\eaa0';
}

.pbi-icon-mini.pbi-scale::before {
  content: '\eaa1';
}

.pbi-icon-mini.pbi-send::before {
  content: '\eaa2';
}

.pbi-icon-mini.pbi-settings::before {
  content: '\eaa4';
}

.pbi-icon-mini.pbi-share::before {
  content: '\eaa5';
}

.pbi-icon-mini.pbi-sitemap::before {
  content: '\eaa8';
}

.pbi-icon-mini.pbi-sort::before {
  content: '\eaaa';
}

.pbi-icon-mini.pbi-sound-down::before {
  content: '\eaab';
}

.pbi-icon-mini.pbi-sound-off::before {
  content: '\eaac';
}

.pbi-icon-mini.pbi-sound-on::before {
  content: '\eaad';
}

.pbi-icon-mini.pbi-sound-up::before {
  content: '\eaae';
}

.pbi-icon-mini.pbi-table::before {
  content: '\eab0';
}

.pbi-icon-mini.pbi-table-new::before {
  content: '\eab1';
}

.pbi-icon-mini.pbi-tack::before {
  content: '\eab2';
}

.pbi-icon-mini.pbi-tile::before {
  content: '\eab3';
}

.pbi-icon-mini.pbi-tile-new::before {
  content: '\eab4';
}

.pbi-icon-mini.pbi-treeview::before {
  content: '\eab6';
}

.pbi-icon-mini.pbi-logo-trello::before {
  content: '\eab7';
}

.pbi-icon-mini.pbi-logo-twitter::before {
  content: '\eab8';
}

.pbi-icon-mini.pbi-logo-youtube::before {
  content: '\eac2';
}

.pbi-icon-mini.pbi-logo-dropbox::before {
  content: '\ea35';
}

.pbi-icon-mini.pbi-logo-paypal::before {
  content: '\ea90';
}

.pbi-icon-mini.pbi-android::before {
  content: '\ea09';
}

.pbi-icon-mini.pbi-apple::before {
  content: '\ea0a';
}

.pbi-icon-mini.pbi-logo-facebook::before {
  content: '\ea3a';
}

.pbi-icon-mini.pbi-logo-pinterest::before {
  content: '\ea93';
}

.pbi-icon-mini.pbi-logo-github::before {
  content: '\ea49';
}

.pbi-icon-mini.pbi-logo-google::before {
  content: '\ea4b';
}

.pbi-icon-mini.pbi-logo-wordpress::before {
  content: '\eac1';
}

.pbi-icon-mini.pbi-logo-linkedin::before {
  content: '\ea7a';
}

.pbi-icon-mini.pbi-logo-skype::before {
  content: '\eaa9';
}

.pbi-icon-mini.pbi-user::before {
  content: '\eabc';
}

.pbi-icon-mini.pbi-users::before {
  content: '\ea8d';
}

.pbi-icon-mini.pbi-video::before {
  content: '\eabd';
}

.pbi-icon-mini.pbi-wifi::before {
  content: '\eac0';
}

.pbi-icon-mini.pbi-search::before {
  content: '\eaa3';
}

.pbi-icon-mini.pbi-zoom-in::before {
  content: '\eac3';
}

.pbi-icon-mini.pbi-zoom-out::before {
  content: '\eac4';
}
