html,
body {
  height: 100%;
}

body {
  background-color: var(--bg_rich_color);
}

// FIXME DS16 why are spacers redefined here?
$spacer: 1rem;
$spacers: (
  0: 0,
  1: (
    $spacer * 0.25,
  ),
  2: (
    $spacer * 0.5,
  ),
  3: $spacer,
  4: (
    $spacer * 1.5,
  ),
  5: (
    $spacer * 2,
  ),
  6: (
    $spacer * 2.5,
  ),
  7: (
    $spacer * 3,
  ),
);

// overrides to Boostrap's grid for 16px gutters
.row:not(.g-0) {
  margin-right: -16px;
  margin-left: -16px;
}

.row:not(.g-0) > .col,
.row:not(.g-0) > [class*='col-'] {
  padding-right: 16px;
  padding-left: 16px;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  padding-right: 16px;
  padding-left: 16px;
}

.page-header {
  background-color: #fff;

  .bgwhite & {
    background-color: var(--rich100);
  }

  .bgdark & {
    background-color: #2a3037;
  }

  h1 {
    margin-top: 100px;
  }
}

.site-root {
  .site-content + main,
  .site-content + .container {
    padding-top: 100px;
  }

  .site-header {
    + .site-content + * {
      height: 100%;
      padding-top: $navbar-brand-height-responsive;

      @include media-breakpoint-up(lg) {
        padding-top: $navbar-brand-height;
      }
    }
  }
}

// to add to long content to ensure enough whitespace below to clear footer
.footer-padding {
  padding-bottom: 6rem;
}

.pb-8 {
  padding-bottom: 4rem;
}

.ng-sidebar__content {
  padding-top: $navbar-brand-height !important;
}

.container-fluid-limit {
  @include media-breakpoint-up(lg) {
    width: 98%;
  }

  @include media-breakpoint-up(lg) {
    width: 94%;
  }
}

.has-global-alert .container h1:first-of-type {
  transition: margin-top 0.5s;
  margin-top: 170px;
}

.border {
  border: 1px solid var(--border_color) !important;
}

.bg-light {
  background-color: #fff !important;

  .bgwhite & {
    background-color: var(--rich100) !important;
  }
}

.bg-gradient-45 {
  background-image: linear-gradient(135deg, var(--grad_start) 0%, var(--grad_end) 50%, var(--grad_end) 100%);
}

.bg-gradient {
  background-image: linear-gradient(90deg, var(--grad_start) 0%, var(--grad_end) 50%, var(--grad_end) 100%);
}

// sticky footer
.site-wrapper {
  display: grid;
  grid-template-rows: 1fr auto;
  min-height: 100%;

  .site-header {
    height: auto;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1030;
  }

  .site-content {
    height: 100%; // was "auto"
    padding-top: 70px;

    &.no-header {
      padding-top: 0;
    }
  }

  .site-wrapper.page-template-blank {
    .site-content {
      padding-top: 0;
    }
  }

  .site-footer {
    grid-row: 2 / 3;
  }

  footer {
    bottom: unset;
    left: unset;
    position: relative;
    right: unset;
    // margin-top: 2rem;
  }
}
