/* Scrollable */
.p-datatable-scrollable .p-datatable-wrapper {
  position: relative;
  overflow: auto;
}

.p-datatable-scrollable .p-datatable-table {
  display: block;
}

.p-datatable-scrollable .p-datatable-thead,
.p-datatable-scrollable .p-datatable-tbody,
.p-datatable-scrollable .p-datatable-tfoot {
  display: block;
}

.p-datatable-scrollable .p-datatable-thead>tr,
.p-datatable-scrollable .p-datatable-tbody>tr,
.p-datatable-scrollable .p-datatable-tfoot>tr {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}

.p-datatable-scrollable .p-datatable-thead>tr>th,
.p-datatable-scrollable .p-datatable-tbody>tr>td,
.p-datatable-scrollable .p-datatable-tfoot>tr>td {
  display: flex;
  flex: 1 1 0;
  align-items: center;
}

.p-datatable-scrollable .p-datatable-thead {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: var(--rich100);

  .bgwhite & {
    background-color: #fff;
  }

  .bgdark & {
    background-color: var(--dark_bg);
  }
}

.p-datatable-scrollable .p-datatable-frozen-tbody {
  position: sticky;
  z-index: 1;
}

.p-datatable-scrollable .p-datatable-tfoot {
  position: sticky;
  bottom: 0;
  z-index: 1;
}

.p-datatable-scrollable .p-frozen-column {
  position: sticky;
  background: inherit;
}

.p-datatable-scrollable th.p-frozen-column {
  z-index: 1;
}

.p-datatable-scrollable-both .p-datatable-thead>tr>th,
.p-datatable-scrollable-both .p-datatable-tbody>tr>td,
.p-datatable-scrollable-both .p-datatable-tfoot>tr>td,
.p-datatable-scrollable-horizontal .p-datatable-thead>tr>th,
.p-datatable-scrollable-horizontal .p-datatable-tbody>tr>td,
.p-datatable-scrollable-horizontal .p-datatable-tfoot>tr>td {
  flex: 0 0 auto;
}

.p-datatable-flex-scrollable {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.p-datatable-flex-scrollable .p-datatable-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}

.p-datatable-scrollable .p-rowgroup-header {
  position: sticky;
  z-index: 1;
}

.p-datatable-scrollable.p-datatable-grouped-header .p-datatable-thead,
.p-datatable-scrollable.p-datatable-grouped-footer .p-datatable-tfoot {
  display: table;
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
}

.p-datatable-scrollable.p-datatable-grouped-header .p-datatable-thead>tr,
.p-datatable-scrollable.p-datatable-grouped-footer .p-datatable-tfoot>tr {
  display: table-row;
}

.p-datatable-scrollable.p-datatable-grouped-header .p-datatable-thead>tr>th,
.p-datatable-scrollable.p-datatable-grouped-footer .p-datatable-tfoot>tr>td {
  display: table-cell;
}

/* Flex Scrollable */
.p-datatable-flex-scrollable {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}

.p-datatable-flex-scrollable .p-datatable-virtual-scrollable-body {
  flex: 1;
}

.p-datatable-scrollable .p-frozen-column {
  position: sticky;
  background: var(--rich100) !important;
}


table.table-sticky-column tbody tr td:first-child,
table.table-sticky-column thead tr th:first-child {
  border-right: 1px solid var(--border_color);
  background-color: var(--rich100) !important;

  .bgwhite & {
    background-color: #fff !important;
  }

  .bgdark & {
    background-color: var(--dark_bg) !important;
  }
}