.card {
  border-color: var(--border_color);
  border-radius: var(--card_border_radius);

  .card-group & {
    box-shadow: none;
  }

  .bgwhite & {
    box-shadow: 1px 0 8px 0 hsla(var(--hue), 0%, 44%, 0.1);
  }

  .bgdark & {
    background-color: var(--dark_input_bg);
  }

  .card-body {
    padding: 1rem;
  }
}

.card-noborder {
  border: 0;
}

.card-header {
  border-bottom: 0;
  background-color: transparent;
  border-radius: var(--card_border_radius) var(--card_border_radius) 0 0;
  color: var(--text);
  font-family: var(--font_family_brand_regular);
  font-size: var(--font_size_lg);
  padding: 1rem;

  >.dropdown .dropdown-toggle {
    color: inherit;
  }

  +.card-body {
    padding-top: 0;
  }

  &.closed {
    border-bottom: 0;
  }
}

.card-body {
  p:last-child {
    margin-bottom: 0;
  }
}

.card-filled-header {
  .card-header {
    padding: 0.5rem 1rem;
    margin: 0;
    background-color: var(--token_color);
    border-radius: var(--card_border_radius) var(--card_border_radius) 0 0;

    .bgdark & {
      background-color: var(--dark_bg_accent);
    }

    +.card-body {
      padding-top: 1rem;
    }
  }
}

.card-shadowed {
  box-shadow: 1px 0 10px 0 hsla(0, 0%, 44%, 0.15);
}

.card-table {
  .card-header {
    border-bottom: 0;
  }

  .table.table-open {
    margin-bottom: 0;

    &>tbody>tr:last-child>td {
      border-bottom: 0;
    }
  }
}

.card-title {
  margin-top: 0;
}

// -- COMPACT UI --
.card-sm {
  .card-header {
    padding: 0.5rem 0;
    margin: 0 0.75rem;
    font-size: var(--font_size_base);
  }

  .card-body {
    padding: 0.75rem;
    font-size: var(--font_size_sm);
  }

  .card-title {
    font-family: var(--font_family_base);
    font-size: var(--font_size_base);
    font-weight: bold;
  }

  .card-filled-header {
    .card-header {
      padding: 0.5rem;
      margin: 0;
    }
  }
}

.card-collapsible {
  .card-header {
    cursor: pointer;
    // + .card-body {
    //   padding-top: 0.5rem;
    // }
  }

  button[aria-expanded='true'] {
    color: var(--secondary);

    .bgdark & {
      color: var(--hyperlink);
    }
  }
}

.card>.list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: var(--card_border_radius);
  border-bottom-left-radius: var(--card_border_radius);
}

.card-footer {
  border-top: 1px solid var(--border_color);
}

.card-footer:last-child {
  border-radius: 0 0 calc(6px - 1px) calc(6px - 1px);
}