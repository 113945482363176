// Components
//
// Define common padding and border radius sizes and more.
:root {
  --border_color: var(--border_color);
  --border_width: 1px;
  --border_radius: 2px;
  --border_radius_lg: 4px;
  --border_radius_sm: 0;

  --card_border_radius: 4px;

  --smiley-stroke: #ddd;
  --smiley-fill: #edb700;

  // Typography

  // override bootstrap's css variables here
  --font-family-serif: Georgia, Times, 'Times New Roman', serif;
  --font-family-sans-serif: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  --font-family-monospace: 'SFMono-Regular', Menlo, Consolas, Monaco, 'Liberation Mono', 'Courier New', monospace;

  // our font families

  // aliases so you can use snake case for consistency
  --font_family_base: var(--font-family-sans-serif);
  --font_family_serif: var(--font-family-serif);
  --font_family_sans_serif: var(--font-family-sans-serif);
  --font_family_monospace: var(--font-family-monospace);

  // brand fonts
  --font_family_brand_light: 'PrecisionSans_W_Lt', 'Helvetica Neue', Arial, sans-serif;
  --font_family_brand_light_italic: 'PrecisionSans_W_LtIt', 'Helvetica Neue', Arial, sans-serif;
  --font_family_brand_regular: 'PrecisionSans_W_Rg', 'Helvetica Neue', Arial, sans-serif;
  --font_family_brand_regular_italic: 'PrecisionSans_W_RgIt', 'Helvetica Neue', Arial, sans-serif;
  --font_family_brand_medium: 'PrecisionSans_W_Md', 'Helvetica Neue', Arial, sans-serif;
  --font_family_brand_medium_italic: 'PrecisionSans_W_MdIt', 'Helvetica Neue', Arial, sans-serif;
  --font_family_brand_bold: 'PrecisionSans_W_Bd', 'Helvetica Neue', Arial, sans-serif;
  --font_family_brand_bold_italic: 'PrecisionSans_W_BdIt', 'Helvetica Neue', Arial, sans-serif;

  // our font sizes
  --font_size_base: 0.875rem; // 14px
  --font_size_lg: 1rem; // 16px (1 rem)
  --font_size_sm: 0.75rem; // 12px (.75 rem)
  --font_size_xs: 0.625rem; // 10px

  //headings
  --h1_font_size: 2rem; // 32px
  --h2_font_size: 1.5rem; // 24px
  --h3_font_size: 1.25rem; // 20px
  --h4_font_size: 1rem; // 16px

  --line_height_base: 1.5;
  --line_height: 1.5; // separate from base to allow overrides
  --list_inline_padding: 4px;

  // tables
  --table-cell-padding: 0.75rem;
  --table-cell-padding-sm: 0.3rem;
  --table-cell-padding-lg: 1rem;

  // Buttons + Forms
  //
  // Shared variables that are reassigned to `--input-` and `--btn-` specific variables.

  --input-btn-padding-y: 0.5rem;
  --input-btn-padding-x: 0.75rem;
  --input-btn-line-height: 1.25;
  --input-btn-padding-y-sm: 0.25rem;
  --input-btn-padding-x-sm: 0.5rem;
  --input-btn-line-height-sm: 1.5;
  --input-btn-padding-y-lg: 0.5rem;
  --input-btn-padding-x-lg: 1rem;
  --input-btn-line-height-lg: 1.5;
  --btn-font-weight: normal;
  --btn-block-spacing-y: 0.5rem;
  --btn-border-radius: var(--border-radius);
  --btn-border-radius-lg: var(--border-radius-lg);
  --btn-border-radius-sm: var(--border-radius-sm);

  // Form states and alerts
  //
  //## Define colors for form feedback states and, by default, alerts.
  --state-success-text: theme-color('success');
  --state-success-bg: #f2f9f2;
  --state-success-border: theme-color('success');
  --state-info-text: theme-color('info');
  --state-info-bg: #f4f9fd;
  --state-info-border: theme-color('info');
  --state-warning-text: theme-color('warning');
  --state-warning-bg: #fcf6f2;
  --state-warning-border: theme-color('warning');
  --state-danger-text: theme-color('danger');
  --state-danger-bg: #fdf2f2;
  --state-danger-border: theme-color('danger');

  // Navs

  --nav-link-padding-y: 1.5rem; // increased from .5 to match our 75 pixel height
  --nav-link-disabled-color: var(--disabled);
  --nav-tabs-border-color: --border-color;
  --nav-tabs-border-width: --border-width;
  --nav-tabs-border-radius: 0;

  // Navbar

  --navbar-nav-link-padding-x: 0.5rem !default;

  --navbar-brand-font-size: (--font-size-lg * 1.5);

  // Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
  --nav-link-height: --navbar-brand-font-size * --line-height-base;
  --navbar-brand-height: (--font-size-base * --line-height-base + --nav-link-padding-y * 2);
  --navbar-brand-padding-y: (--navbar-brand-height - --nav-link-height) / 2;
  --navbar-brand-height-responsive: 3.625rem;

  // Cards

  --card-border-color: var(--border_color);
  --card-cap-bg: var(--white);
  --card-header-font: --font-family-brand-regular;
  --card-spacer-y: 0.5rem;
  --card-spacer-x: 1rem;
  --card-border-width: 1px;
  --card-border-radius: --border-radius;

  // Badges

  --badge-color: var(--white);
  --badge-font-size: 0.75rem;
  --badge-font-weight: normal;
  --badge-padding-y: 0.3rem;
  --badge-padding-x: 0.5rem;
  --badge-pill-padding-x: 0.6em;

  // Modals

  // Padding applied to the modal body
  --modal-inner-padding: 1rem;
  --modal-dialog-margin: 2rem;
  --modal-dialog-margin-y-sm-up: 2rem;
  --modal-content-border-color: --border-color;

  // Progress bars

  --progress-height: 1.25rem;
  --progress-font-size: 0.75rem;
  --progress-bg: var(--rich100);

  // List group

  --list-group-item-padding-y: 0.75rem;
  --list-group-item-padding-x: 1.25rem;

  --list-group-disabled-color: var(--rich500);

  // Code

  --code-font-size: 90%;
  --code-color: #bd4147;

  // NEW for BS5

  --bs-list-group-item-padding-y: 0.75rem;
  --bs-list-group-item-padding-x: 1.25rem;
}
