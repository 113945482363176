.p-image {
  border: 1px solid var(--border_color);
}
.p-image-mask {
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-image-preview-container {
  position: relative;
  display: inline-block;
}

.p-image-preview-indicator {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 36px;
}

.p-image-preview-icon {
  font-size: 1.5rem;
}

.p-image-preview-container:hover > .p-image-preview-indicator {
  opacity: 1;
  cursor: pointer;
}

.p-image-preview-container > img {
  cursor: pointer;
}

.p-image-toolbar {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  z-index: 1;
}

.p-image-action.p-link {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
}

.p-image-action.p-link {
  color: #f8f9fa;
  background-color: transparent;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin-right: 0.5rem;
}
.p-image-action.p-link:hover {
  color: #f8f9fa;
  background-color: rgb(255 255 255 / 10%);
}

.p-image-action.p-link[disabled] {
  opacity: 0.5;
}

.p-image-preview {
  transition: transform 0.15s;
  max-width: 100vw;
  max-height: 100vh;
}

.p-image-mask {
  background-color: rgb(0 0 0 / 80%);
}

.p-image-action.p-link {
  color: #f8f9fa;
  background-color: transparent;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin-right: 0.5rem;
}
.p-image-preview-container:hover > .p-image-preview-indicator {
  opacity: 1;
  cursor: pointer;
  background-color: rgb(0 0 0 / 50%);
  color: white;
}

.only-a-test {
  background-color: red;
}
