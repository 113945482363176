.p-skeleton {
  background-color: var(--rich200);
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}

.p-skeleton::after {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
  content: '';
  animation: p-skeleton-animation 1.2s infinite;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(-100%);
  z-index: 1;
}

.p-skeleton.p-skeleton-circle {
  border-radius: 50%;
}

.p-skeleton-none::after {
  animation: none;
}

@keyframes p-skeleton-animation {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(100%);
  }
}

.bgdark .p-skeleton {
  background-color: var(--dark_bg_accent);
}

.bgdark .p-skeleton::after {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(172, 186, 199, 0.3), rgba(255, 255, 255, 0));
}