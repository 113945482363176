// FIXME: deprecated p-table styling should be removed, redundant since we use tableStyleClass to add Bootstrap table styling.

.p-datatable,
.p-treetable {
  table {
    width: 100%;
    max-width: 100%;
    background-color: transparent; // Reset for nesting within parents with `background-color`.
    border-bottom: 1px solid var(--border_color);

    &.table-unstyled {
      border: 0;
      background-color: transparent;
    }
  }

  th,
  td {
    padding: $table-cell-padding;
    border-top: 1px solid var(--border_color);
    vertical-align: top;
  }

  thead {
    border-color: var(--border_color);
    color: var(--text);
  }

  thead th {
    // border-bottom: 1px solid var(--border_color);

    vertical-align: bottom;
    border-top: 0;
    @include table-headers;

    &.p-sortable-column {
      color: var(--text);

      &.p-state-highlight {
        color: var(--text);
      }

      sortalticon {
        &:before {
          content: '\eaaa';
          font-family: 'pbi-icon-mini';
        }

        svg {
          display: none;
        }
      }

      sortamountupalticon {
        &:before {
          content: '\ea0c';
          font-family: 'pbi-icon-mini';
        }

        svg {
          display: none;
        }
      }

      sortamountdownicon {
        &:before {
          content: '\ea33';
          font-family: 'pbi-icon-mini';
        }

        svg {
          display: none;
        }
      }
    }
  }

  th[scope='row'] {
    color: var(--text);
    font-family: var(--font_family_base);
  }

  tbody + tbody {
    border-top: 2px solid var(--border_color);
  }

  tbody {
    td {
      color: var(--text);

      // &:first-of-type {
      //   border-top: 0;
      // }
    }

    tr {
      &:first-of-type {
        td {
          border-top: 0;
        }
      }
    }
  }

  .table {
    background-color: transparent;
  }
}

//
// Condensed table w/ half padding
//

// bordered table
.p-datatable-bordered {
  table {
    border: 1px solid var(--border_color);

    th,
    td {
      border: 1px solid var(--border_color);
    }

    thead {
      th,
      td {
        border-bottom-width: 2px;
      }
    }
  }
}

// striped table
.p-datatable-striped {
  .p-datatable-tbody tr:nth-of-type(even) {
    background-color: var(--table_stripe);
  }

  .bgdark & {
    .p-datatable-tbody tr:nth-of-type(even) {
      background-color: var(--dark_bg_accent);
    }
  }
}

.table {
  .table-light {
    th {
      border-color: var(--border_color);
      background-color: transparent;
      color: var(--text);
    }
  }
  &.table-sm {
    th,
    td {
      padding: $table-cell-padding-sm;
    }
  }
}

.table-row-toggle {
  color: var(--text);
  padding: 4px;

  i.pbi-icon-mini {
    padding-right: 0;
  }
}

.table-details {
  .table-details-content {
    padding: 10px;
  }
}

// Responsive
.p-datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

@media screen and (max-width: 40em) {
  .p-datatable-responsive .ui-table-thead > tr > th,
  .p-datatable-responsive .ui-table-tfoot > tr > td {
    display: none !important;
  }

  .p-datatable-responsive .p-datatable-tbody {
    border-top: 1px solid var(--border_color);

    tr {
      &:not(:first-child) {
        border-top: 1px solid var(--border_color);
      }

      td {
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
      }
    }
  }

  .p-datatable-responsive .p-datatable-tbody > tr > td {
    display: block;
    width: 100% !important;
    box-sizing: border-box;
    border: 0 none;
    clear: left;
    float: left;
    text-align: left;
  }

  .p-datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
    display: inline-block;
    min-width: 30%;
    padding: 0;
    margin: 0 1em 0 0;
    color: var(--primary);
    font-family: var(--font_family_brand_bold);
    font-weight: normal;
  }
}

.table tbody tr.ui-state-highlight td {
  background-color: var(--rich200);
}

// editable
p-celleditor {
  border-bottom: 1px dashed var(--hyperlink);
  color: var(--hyperlink);
}

.p-corner-all {
  border-radius: $border-radius;
}

.table-slider-label {
  font-family: var(--font_family_base);
  font-size: var(--font_size_base);
  font-weight: normal;
}

// Resizable
.p-table-resizable > .p-table-wrapper {
  overflow-x: auto;
}

.p-datatable-resizable .p-datatable-thead > tr > th,
.p-datatable-resizable .p-datatable-tfoot > tr > td,
.p-datatable-resizable .p-datatable-tbody > tr > td {
  overflow: hidden;
}

.p-datatable-resizable .ui-resizable-column {
  position: relative;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

.p-datatable-resizable-fit .ui-resizable-column:last-child .ui-column-resizer {
  display: none;
}

.p-datatable .ui-column-resizer {
  position: absolute !important;
  top: 0;
  right: 0;
  display: block;
  width: 0.5em;
  height: 100%;
  padding: 0;
  margin: 0;
  border: 1px solid transparent;
  cursor: col-resize;
}

.p-datatable.ui-column-resizer-helper {
  position: absolute;
  z-index: 10;
  display: none;
  width: 1px;
}

.ui-table-open {
  thead > tr > th,
  thead > tr > td,
  tbody > tr > th,
  tbody > tr > td,
  tfoot > tr > td {
    border-right: 0;
    border-left: 0;
  }
}

.p-datatable-open tbody > tr > td:last-child,
.p-datatable-open tfoot > tr > td:last-child {
  border-right: 0;
}

.p-datatable-open table,
.ui-table-open table {
  border-style: none;
  background-color: transparent;
}

.p-datatable-open thead > tr > th,
.ui-table-open thead > tr > th {
  border-bottom: 0;
}

.p-datatable-open tbody > tr:last-child > td,
.ui-table-open tbody > tr:last-child > td {
  border-bottom: 1px solid var(--border_color);
}

// nested tables (table inside detail row)
.p-datatable-open {
  td {
    .table.table-unstyled,
    .p-datatable.table-unstyled,
    .ui-table.table-unstyled {
      tr:hover {
        background-color: transparent;
      }

      tr:last-child td {
        border-bottom: none;
      }
    }
  }
}

/* Row Reorder */
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
  box-shadow: inset 0 2px 0 0 var(--primary);
}

.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
  box-shadow: inset 0 -2px 0 0 var(--primary);
}

tr[draggable] {
  background-color: #fff;

  .bgdark & {
    background-color: var(--dark_bg_accent);
  }
}

/* Scrollable */
.p-datatable-scrollable-wrapper {
  position: relative;
  border-bottom: 1px solid var(--border_color);
}

.p-datatable-scrollable-header,
.p-datatable-scrollable-footer {
  overflow: hidden;
  border: 0 none;
}

.p-datatable-scrollable-body {
  position: relative;
  overflow: auto;

  tr:first-child td {
    border-top: 1px solid transparent;
  }
}

.p-datatable-virtual-table {
  position: absolute;
}

// added 10/6/20
.p-sortable-column-icon.pi.pi-fw {
  font-family: 'pbi-icon-mini' !important;

  &.pi-sort-alt::before {
    content: '\e953';
  }

  &.pi-sort-amount-up-alt::before {
    content: '\ea0c';
  }

  &.pi-sort-amount-down::before {
    content: '\ea33';
  }
}
.p-datatable .p-sortable-column .p-column-title,
.p-datatable .p-sortable-column .p-sortable-column-icon,
.p-datatable .p-sortable-column .p-sortable-column-badge {
  font-style: normal;
}

// keep sort icons from breaking to a new line
.p-sortable-column-icon {
  font-size: var(--font_size_xs);
  &.pi {
    display: inline;
  }
}

.p-paginator.p-paginator-bottom {
  > span:first-child {
    margin-right: auto;
    border: 0;
    cursor: none;
  }
}

.p-datatable-resizable-table {
  td,
  th {
    border-color: inherit;
    border-style: solid;
    border-right: 1px solid var(--border_color) !important;
  }
  td:first-of-type,
  th:first-of-type {
    border-left: 1px solid var(--border_color) !important;
  }
  th {
    border-top: 1px solid var(--border_color) !important;
  }
}
.p-column-resizer-helper {
  background: var(--focus_border_color) !important;
}
