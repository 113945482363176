.mat-mdc-radio-button .mdc-radio {
  --mdc-radio-state-layer-size: 26px;
  // padding: 4px !important;
}
.mat-mdc-radio-button .mdc-radio .mdc-radio__background::before {
  top: calc(-1 * (var(--mdc-radio-state-layer-size, 25px) - 20px) / 2) !important;
  left: calc(-1 * (var(--mdc-radio-state-layer-size, 25px) - 20px) / 2) !important;
  width: var(--mdc-radio-state-layer-size, 25px) !important;
  height: var(--mdc-radio-state-layer-size, 25px) !important;
}
.mat-mdc-radio-button.mat-mdc-radio-checked .mdc-radio__background::before {
  // border: 1.5px solid var(--focus_border_color) !important;
  @include focused;
  outline-offset: 0px;
  border-radius: 50% !important;
  background-color: transparent !important;
}
.mdc-radio__native-control:focus + .mdc-radio__background::before {
  transform: scale(1);
  opacity: 1 !important;
  transition:
    opacity 120ms 0ms cubic-bezier(0, 0, 0.2, 1),
    transform 120ms 0ms cubic-bezier(0, 0, 0.2, 1);
}
// radio button unselected
.mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:enabled:not(:checked)
  + .mdc-radio__background
  .mdc-radio__outer-circle {
  border: 1px solid var(--btn_primary) !important;
}

// radio button selected
.mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:enabled:checked
  + .mdc-radio__background
  .mdc-radio__outer-circle {
  border: 1px solid var(--btn_primary) !important;
  background-color: var(--white);

  .bgdark & {
    background-color: var(--dark_input_bg);
    border: 1px solid var(--dark_input_border);
  }

  .bgwhite & {
    border: 1px solid var(--btn_primary) !important;
    background-color: var(--bg_rich_color);
  }
}

.mat-mdc-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: var(--btn_primary) !important;
  border-color: var(--btn_primary) !important;
}

// radio button selected inner circle
.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle {
  border-color: var(--btn_primary) !important;
  background-color: var(--btn_primary) !important;

  @media (forced-colors: active) {
    background-color: #fff !important;
    border: solid 10px;
  }
}

.mat-mdc-radio-button.mat-mdc-radio-checked .mdc-radio__background::before {
  background-color: #fff !important;
}

.mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:enabled:not(:checked)
  + .mdc-radio__background
  .mdc-radio__outer-circle {
  border: 1px solid var(--btn_primary) !important;
}

// found inspecting during WHCM
.cdk-high-contrast-active .mat-mdc-radio-button.mat-mdc-radio-checked .mdc-radio__inner-circle,
.cdk-high-contrast-active .mat-radio-checked .mat-radio-inner-circle {
  border: solid 10px;
}

.mat-mdc-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: rgb(66, 134, 244);
}

// radio label
.mdc-form-field label {
  font-size: var(--font_size_base) !important;
  font-family: var(--font-family-sans-serif) !important;
  color: var(--text) !important;
}

// disabled radio button
.mat-mdc-radio-button
  .mdc-radio
  [aria-disabled='true']
  .mdc-radio__native-control:not(:checked)
  + .mdc-radio__background
  .mdc-radio__outer-circle,
.mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:disabled:not(:checked)
  + .mdc-radio__background
  .mdc-radio__outer-circle {
  opacity: 1 !important;
  border: 1px solid var(--disabled) !important;
  background-color: rgba(192, 192, 192, 0.25) !important;

  .bgdark & {
    border-color: var(--dark_input_border) !important;
    background-color: #45494d !important;
  }
}

// disabled label
.mat-mdc-radio-button[disabled] {
  .mdc-form-field label {
    color: var(--disabled) !important;

    .bg-dark & {
      color: var(--dark_input_disabled_bg) !important;
    }
  }
}

.mat-mdc-radio-group {
  .mat-mdc-radio-button {
    margin-right: 1rem;
  }
}

.stacked-radio-group {
  display: inline-flex;
  flex-direction: column;
}

.inline-checkbox-group {
  .mat-mdc-checkbox {
    margin-right: 1rem;
  }
}

.stacked-checkbox-group {
  display: inline-flex;
  flex-direction: column;
}

// onboarding pattern radio buttons

.radio-toolbar {
  width: 40%;

  input[type='radio'] {
    opacity: 0;
    width: 0;

    &:checked + label {
      border: 2px solid var(--btn_page_primary);
      box-shadow: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }

    &:focus + label {
      @include focused;
    }
  }

  label {
    display: block;
    background-color: var(--white);
    padding: 20px 40px;
    font-family: var(--font-family-sans-serif);
    font-size: var(--font_size_lg);
    font-weight: normal;
    border: 2px solid var(--rich400);
    box-sizing: border-box;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

    .bgdark & {
      background-color: var(--dark_input_bg);
      border-color: var(--dark_input_border);
      filter: none;
    }
  }
}

.add-user-modal .stacked-checkbox-group,
.add-role-modal .stacked-checkbox-group {
  line-height: 2.5;
}

.roles-and-permissions h6,
.add-role-modal h6,
.add-user-modal h6 {
  font-size: 10px;
  color: var(--blue);
}

.roles-and-permissions .checkbox-list {
  margin-left: 23px;
}

.invite-icon {
  color: var(--green);
  font-size: 42px;
  font-weight: bold;
  margin-bottom: 20px;
}
