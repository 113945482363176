.mdc-list-item,
.mat-list-option,
.mat-list-text,
.mdc-list-item__primary-text {
  font-family: var(--font_family_base);
  font-size: var(--font_size_base);
  color: var(--text);
}

.mat-expansion-panel {
  color: var(--text);
}

.mat-expansion-panel-header {
  font-family: var(--font_family_brand_regular);

  .mat-expansion-panel-header-title,
  .mat-expansion-indicator::after {
    color: var(--text);
  }

  &.mat-expanded {
    .mat-expansion-panel-header-title,
    .mat-expansion-indicator::after {
      color: var(--secondary);
    }
  }
}

.mat-mdc-list,
.mat-mdc-selection-list {
  .mat-mdc-subheader {
    padding-left: 0;
    padding-bottom: 0;
    font-size: var(--font_size_base);
    color: var(--text);
    font-family: var(--font_family_brand_medium);
  }
}
