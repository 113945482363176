.table {
  margin-bottom: 1.5rem;
  color: var(--text);
  border-bottom: 1px solid var(--border_color);
}

.table thead th,
.table thead.table-light th,
.table th[scope='row'] {
  @include table-headers;
}

.table .border-bottom {
  border-bottom: 1px solid var(--border_color) !important;
}

.table thead th {
  border-bottom: 1px solid var(--border_color);
}

.table tbody td {
  font-variant-numeric: tabular-nums;
}

.td-break {
  word-break: break-all;
}

.table-fixed {
  table-layout: fixed;
}

.table-lg {
  th,
  td {
    padding: $table-cell-padding-lg;
  }
}

.table-sm {
  th,
  td {
    font-size: var(--font_size_sm);
  }
}

.table th {
  border-top: 0;
}

.table tbody tr {
  td {
    border-top: 1px solid var(--border_color);
  }

  &:first-of-type {
    td {
      border-top: 0;
    }
  }
}

// NEW - override BS5 table styles
.table > :not(caption) > * > * {
  padding: 0.75rem;
  background-color: transparent;
  border-bottom: 0;
  box-shadow: none;
}

.table-unstyled {
  border: 0;
  background-color: transparent;
}

.table-unstyled > thead > tr > th,
.table-unstyled > thead > tr > td,
.table-unstyled > tbody > tr > th,
.table-unstyled > tbody > tr > td,
.table-unstyled > tfoot > tr > th,
.table-unstyled > tfoot > tr > td {
  border: 0;
  background-color: transparent;

  &:first-child {
    padding-left: $table-cell-padding;
  }

  &:last-child {
    padding-right: $table-cell-padding;
  }
}

.table-unstyled > tbody > tr > td,
.table-unstyled > tfoot > tr > td {
  border-right: 0;
}

.table-unstyled > tbody > tr > td:last-child,
.table-unstyled > tfoot > tr > td:last-child {
  border-right: 0;
}

// faceted search
.faceted-search-sidebar {
  .list-group-item {
    padding: 0;
    margin: 0;
    border: 0;
  }

  .card-header {
    padding: 0.5rem 1.5rem 0.5rem 0.5rem;
    margin: 0;
  }

  .card-body {
    padding: 0.5rem;
  }

  .accordion-group .card,
  .accordion .card {
    border-bottom: 0;
    border-color: var(--border_color);

    .card-body {
      padding: 0.5rem;
    }

    .card-header {
      padding: 0.5rem 1.5rem 0.5rem 0.5rem;
      margin: 0;

      h5,
      a {
        font-size: 0.875rem;
      }
    }
  }
}

.table-open > thead > tr > th,
.table-open > thead > tr > td,
.table-open > tbody > tr > th,
.table-open > tbody > tr > td,
.table-open > tfoot > tr > th,
.table > tfoot > tr > td {
  border-right: 0;
  border-left: 0;
}

.table-open > tbody > tr > td:last-child,
.table-open > tfoot > tr > td:last-child {
  border-right: 0;
}

.table-open {
  border: 0;
  background-color: transparent;
}

.table-open > thead > tr > th {
  border-bottom: 0;
}

.ui-table-striped tbody tr:nth-of-type(odd),
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(#fff, 0.4);

  .bgwhite &,
  .card &,
  .bg-white & {
    background-color: var(--table_stripe);
  }

  .bgdark & {
    background-color: var(--dark_bg_accent);
  }

  &.table-striped-on-white {
    background-color: var(--table_stripe);
  }
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: transparent;
}

.table-hover > tbody > tr:hover,
.table-striped.table-hover tbody tr:nth-of-type(odd):hover {
  background-color: var(--rich200);

  .bgdark & {
    background-color: var(--dark_input_bg);
    color: #fff;
  }
}

.table-sticky-container {
  overflow-y: scroll;
  border: 0;

  th {
    position: sticky;
    top: 0;
    z-index: 2;
  }

  table {
    thead,
    .table-light {
      th {
        background-color: var(--rich100);
      }
    }
  }

  th::after,
  th::before {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
  }

  th::before {
    top: -1px;
    border-top: 1px solid var(--border_color);
  }

  th::after {
    bottom: -2px;
    border-bottom: 2px solid var(--border_color);
  }
}

.bgwhite .table-sticky-container table {
  thead,
  .table-light {
    th {
      background-color: white;
    }
  }
}

td {
  .btn.btn-sm {
    border: 0;

    .pbi-icon-mini {
      font-size: var(--font_size_base);
    }
  }
}

table.table-bordered,
.table-bordered td,
.table-bordered th {
  border: 1px solid var(--border_color);
}

.row-hover {
  .check-toggle {
    margin: 0;
    cursor: pointer;
  }

  .pbi-check-circle-open {
    display: none;
  }

  &:hover {
    .pbi-check-circle-open {
      display: inline-block;
    }
  }
}

tr.table-details {
  background-color: #fff;

  .bgwhite & {
    background-color: var(--rich100);
  }

  .bgdark & {
    background-color: var(--dark_input_bg);
  }
}
