@mixin hue-items {
  // rich colors, by number
  --rich600: hsl(var(--hue), var(--S600), var(--L600));
  --rich500: hsl(var(--hue), var(--S500), var(--L500));
  --rich400: hsl(var(--hue), var(--S400), var(--L400));
  --rich300: hsl(var(--hue), var(--S300), var(--L300));
  --rich200: hsl(var(--hue), var(--S200), var(--L200));
  --rich100: hsl(var(--hue), var(--S100), var(--L100));

  // * ALL ITEMS AFFECTED BY A HUE CHANGE GO HERE *
  --h1_color: var(--secondary);
  --text: var(--rich600);

  --bg_rich_color: var(--rich100);

  --btn_primary: var(--rich500);
  --btn_primary_active: var(--rich600);
  --btn_primary_label: var(--white);
  --btn_secondary_bg: transparent;
  --btn_secondary_label: var(--rich500);
  --btn_secondary_bg_hover: var(--rich200);
  --btn_secondary_bg_hover_label: var(--rich600);
  --btn_secondary_bg_active: var(--rich200);
  --btn_secondary_bg_active_label: var(--rich600);
  --btn_page_primary: var(--primary);
  --btn_page_secondary_label: var(--primary);

  --border_color: var(--rich300);

  --input_border_color: #888ea5; // border color for input fields and dropdown as per a11y ratio
  --table_hover_color: var(--rich200);
  --divider_color: var(--rich400);

  --data_widget_1: var(--blue);
  --data_widget_2: var(--purple);
  --data_widget_3: var(--magenta);

  --menu_hover: var(--rich200);
  --tab_hover_bg: var(--rich200);
  --token_color: var(--rich200);
  --readonly_text_color: var(--rich600);
  //placeholder
  --placeholder_color: #61708f;

  --form_control_bg_richcolor: var(--rich100);
  --table_stripe: var(--rich100);
}

:root {
  // custom colors, to override bootstrap's default colors
  --blue300: #8997c8;
  --blue500: #3e53a4;
  --blue700: #314183;
  --green700: #007d00;
  --green900: hsl(183, 67%, 22%);
  --indigo: #50248f;
  --orange700: #c04911;
  --pink: #cf0989;
  --red: #d30c0c;
  --mediumblue700: #005a93;
  --teal: hsl(184, 72%, 28%);

  // PB primary color palette
  --cyan: #009bdf;
  --mediumblue: #0072b8; // darkened
  --blue: #3e53a4;
  --purple: #a319b1;

  // PB secondary color palette
  --magenta: #b70077; //darkened
  --green: #00b140; // actually PB dark green
  --orangedark: #ee6700;
  --orange: #ef8200;
  --yellow: #edb700;

  // neutrals
  --white: #fff;
  --blue50: #eef1fb;
  --rich200: #c8cee4;
  --gray50: #f8f8f8;
  --gray100: #f0f0f0;
  --gray200: #e1e1e1;
  --gray300: #c0c0c0;
  --gray400: #9b9b9b;
  --gray500: #717171;
  --gray600: #4e4e4e;
  --gray700: #222;
  --rich2000: #212529;
  --gray900: #121212;
  --black: #000;

  //notification colors
  --orange: #ef8200;
  --red: hsl(0, 89%, 44%);
  --mediumblue: #0072b8;
  --green: #00b140;
  --green-700: #007d00;

  --error: var(--red);
  --danger: var(--red);
  --warning: var(--orange);
  --info: var(--mediumblue);
  --success: var(--green700);
  --successdark: var(--green700);
  --figcaption: var(--gray700);
  --danger_bg: hsl(0, 73%, 78%);
  // utility colors
  --disabled: var(--gray300);
  // --focus_shadow: 0 0 10px;
  --text_help: var(--gray400);
  --info: var(--blue);
  --border: #b3b9c3;

  // focus
  --focus_border_color: #1a88ff;
  --focus_shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);

  // rich colors, Hues
  --classic_H: 228;
  --ocean_H: 183;
  --sunset_H: 265;
  --twilight_H: 235;
  --neutral_H: 217;
  --shipaccel_H: 235;

  // rich colors, Saturation and Lightness
  --S600: 15%;
  --S500: 20%;
  --S400: 11%;
  --S300: 18%;
  --S200: 50%;
  --S100: 35%;

  --L600: 30%;
  --L500: 39%;
  --L400: 73%;
  --L300: 87%;
  --L200: 93%;
  --L100: 97%;

  // rich colors, by number
  --rich600: hsl(var(--hue), var(--S600), var(--L600));
  --rich500: hsl(var(--hue), var(--S500), var(--L500));
  --rich400: hsl(var(--hue), var(--S400), var(--L400));
  --rich300: hsl(var(--hue), var(--S300), var(--L300));
  --rich200: hsl(var(--hue), var(--S200), var(--L200));
  --rich100: hsl(var(--hue), var(--S100), var(--L100));

  --header_foreground: #fff;

  // Dark theme
  --dark_bg: #22272e;
  --dark_fg: #adbac7;
  --dark_bg_accent: #2a3037;

  // dark forms
  --dark_input_bg: #1c2128;
  --dark_input_disabled_bg: #707881;
  --dark_input_disabled_text: #c0c0c0;
  --dark_input_border: #434c56;
  --dark_input_placeholder: #5f646a;
  --dark_menu_hover: #2a3037;
  --dark_token_color: #adbac7;
  --dark_tooltip: #596678;

  // THEMED ITEMS ---------------------
  // Set defaults ("classic")
  --primary: var(--blue);
  --primary_rgb: 62, 82, 163;
  --secondary: #b70077;
  --hyperlink: #b70077;
  --hyperlink_hover: #b70077;

  --primary_active: var(--blue700);

  // gradient
  --grad_start: #3e53a4;
  --grad_mid: #a03f9b;
  --grad_end: #cf0989;

  --btn_page_primary_active: var(--blue700);

  // set hue for all themed items
  --hue: var(--classic_H);
  @include hue-items;
}

// FIXME DS17 remove after reworking entire themeing, all these should be created using $utilities
.text-bg-primary {
  background-color: var(--primary) !important;
  color: var(--white) !important;
}

.text-bg-secondary {
  background-color: var(--secondary) !important;
  color: var(--white) !important;
}

.text-bg-success {
  background-color: var(--success) !important;
  color: var(--white) !important;
}

.text-bg-danger {
  background-color: var(--danger) !important;
  color: var(--white) !important;
}

.text-bg-warning {
  background-color: var(--warning) !important;
  color: var(--white) !important;
}

.text-bg-info {
  background-color: var(--info) !important;
  color: var(--white) !important;
}

.text-bg-dark {
  background-color: var(--rich500) !important;
  color: var(--white) !important;
}

.text-bg-light {
  background-color: var(--white) !important;
}

.text-primary {
  color: var(--primary) !important;
}

.text-secondary {
  color: var(--secondary) !important;
}

.text-success {
  color: var(--success) !important;
}

.text-danger {
  color: var(--danger) !important;
}

.text-warning {
  color: var(--warning) !important;
}

.text-info {
  color: var(--info) !important;
}

.text-light {
  color: var(--white) !important;
}

.bg-primary {
  background-color: var(--primary) !important;
}

.bg-secondary {
  background-color: var(--secondary) !important;
}

.bg-success {
  background-color: var(--success) !important;
}

.bg-danger {
  background-color: var(--danger) !important;
}

.bg-warning {
  background-color: var(--warning) !important;
}

.bg-info {
  background-color: var(--info) !important;
}

.bg-light {
  background-color: var(--white) !important;
}

.border {
  border: 1px solid var(--border_color) !important;
  border-color: var(--border_color) !important;
}

.bgwhite {
  background-color: var(--white);
}

.bg-rich100 {
  background-color: var(--rich100);
}
.bg-rich200 {
  background-color: var(--rich200);
}
.bg-rich300 {
  background-color: var(--rich300);
}
.bg-rich400 {
  background-color: var(--rich400);
}
.bg-rich500 {
  background-color: var(--rich500);
}
.bg-rich600 {
  background-color: var(--rich600);
}

.rich100 {
  color: var(--rich100);
}
.rich200 {
  color: var(--rich200);
}
.rich300 {
  color: var(--rich300);
}
.rich400 {
  color: var(--rich400);
}
.rich500 {
  color: var(--rich500);
}
.rich600 {
  color: var(--rich600);
}
