.compact-ui {
  h1 {
    margin: 95px 0 1rem;
    font-size: 1.5rem;
  }
  h2 {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-size: 1.25rem;
  }
  h3 {
    margin-bottom: 0.25rem;
    font-size: 1rem;
  }
  h4 {
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
  }
  h5 {
    margin-bottom: 0.25rem;
  }

  dl,
  ol,
  p,
  ul,
  .invalid-feedback {
    margin-bottom: 0.5rem;
    font-size: var(--font_size_sm);
    line-height: 1.33;
  }
  p.lede,
  p.doc-lede {
    margin-bottom: 0.5em;
    font-size: var(--font_size_base);
  }

  // forms
  label {
    margin-bottom: 0.125rem;
    // font-size: 10px;
  }

  .btn-group label {
    margin-bottom: 0;
  }

  .dropdown-item {
    padding: 0.25rem 0.5rem;
    color: var(--text);
    font-size: 12px;
  }

  select.form-control[size],
  select.form-control[multiple] {
    font-size: 12px;
  }

  ul.nav-tabs {
    margin-bottom: 0;
  }

  .nav-tabs a,
  .nav-tabs a.nav-link {
    padding: 0.25rem 0.5rem;
  }

  .tab-content .tab-pane {
    padding: 0.5rem;
    font-size: var(--font_size_sm);
  }

  .data-widget {
    .data-widget-icon {
      padding-left: 1rem;
      font-size: 2rem;
    }
    .data-widget-data {
      padding: 0.5rem;
      .data-description {
        font-size: var(--font_size_base);
      }
      .data-value {
        font-size: 1rem;
      }
    }
  }

  .modal-dialog {
    .modal-header {
      .close {
        top: 1rem;
        right: 1rem;
        width: 1rem;
        height: 1rem;
      }
    }

    .modal-title {
      font-size: 1.25rem;
    }

    .modal-content {
      padding: 1.5rem;
    }

    .modal-footer {
      .btn {
        min-width: 4rem;
      }
    }
  }

  .list-group-item {
    padding: 0.5rem;
  }

  .badge {
    padding: 0.2rem 0.4rem;
    font-size: 10px;
  }

  // -----------------------------------------------------------------------------------------
  // add the -sm versions of things, so they can be switched by just applying compact-ui class
  // these are also still available anywhere with their -sm classes
  // -----------------------------------------------------------------------------------------

  .alert {
    padding: 0.5rem 0.5rem 0.5rem 2rem;
    margin-bottom: 0.5rem;
    background-position: 0.5rem center;
    background-repeat: no-repeat;
    background-size: 1rem 1rem;
    font-size: var(--font_size_sm);
  }

  .table > thead > tr > td,
  .table > tbody > tr > td,
  .table > tfoot > tr > td,
  .table > thead > tr > th,
  .table > tbody > tr > th,
  .table > tfoot > tr > th {
    padding: 0.3rem;
    font-size: var(--font_size_sm);
  }

  // prime ng
  .ui-table table > thead > tr > td,
  .ui-table table > tbody > tr > td,
  .ui-table table > tfoot > tr > td,
  .ui-table table > thead > tr > th,
  .ui-table table > tbody > tr > th,
  .ui-table table > tfoot > tr > th,
  .p-datatable table > thead > tr > td,
  .p-datatable table > tbody > tr > td,
  .p-datatable table > tfoot > tr > td,
  .p-datatable table > thead > tr > th,
  .p-datatable table > tbody > tr > th,
  .p-datatable table > tfoot > tr > th {
    padding: 0.3rem;
    font-size: var(--font_size_sm);
  }
  .table > thead > tr > th,
  .p-datatable table > thead > tr > th {
    font-size: 11px;
  }

  .ui-paginator {
    .ui-state-default {
      padding: 0.25rem;
      font-size: var(--font_size_sm);
    }

    .ui-paginator-left-content {
      font-size: var(--font_size_sm);
    }

    .ui-dropdown .ui-dropdown-label {
      padding: 0.125rem;
    }
    .ui-dropdown .ui-dropdown-trigger {
      top: 0;
    }
  }
  .p-paginator {
    .p-paginator-page,
    .p-paginator-next,
    .p-paginator-last,
    .p-paginator-first,
    .p-paginator-prev,
    .p-paginator-current {
      font-size: var(--font_size_sm);
      padding: 0.3rem;
    }
    .p-dropdown .p-dropdown-label {
      padding: 0.3rem;
    }
  }

  .card {
    .card-header {
      padding: 0.5rem 0;
      margin: 0 0.75rem;
      font-size: var(--font_size_base);
    }

    .card-body {
      padding: 0.75rem;
      font-size: var(--font_size_sm);
    }

    .card-title {
      font-family: var(--font_family_base);
      font-size: var(--font_size_sm);
      font-weight: bold;
    }
  }

  .card-filled-header {
    .card-header {
      padding: 0.5rem;
      margin: 0;
    }
  }

  .btn,
  .btn-group .btn {
    padding: 0.25rem 0.5rem;
    // font-size: var(--font_size_sm);
  }

  .form-control,
  .input-group .form-control,
  .input-group .input-group-prepend .input-group-text,
  .input-group .input-group-append .input-group-text,
  .input-group .input-group-prepend .btn,
  .input-group .input-group-append .btn,
  .input-group .btn-outline-secondary,
  .input-group .input-group-text,
  select.form-control-sm:not([size]):not([multiple]) {
    padding: 0.25rem 0.5rem;
    font-size: var(--font_size_sm);
    line-height: 1.5;
  }
  select.form-control-sm:not([size]):not([multiple]) {
    padding-right: 1.5rem;
  }
  .switch.switch-text,
  .switch.switch-default {
    width: 4rem;
    height: 1.5rem;
    .switch-handle {
      width: 1.5rem;
      height: 1.5rem;
    }
    .switch-input:checked ~ .switch-handle {
      left: 2.5rem;
    }
    .switch-label {
      font-size: 0.546875rem;
    }
  }
} // end compact-ui
