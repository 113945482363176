.rating {
  font-size: 3.4rem;
  color: var(--primary) !important;
}

.star {
  position: relative;
  display: inline-block;
  font-size: 3rem;
  color: var(--primary);
}

.full {
  color: var(--primary);
}

.half {
  position: absolute;
  display: inline-block;
  overflow: hidden;
  color: var(--primary);
}

// smileys

.smiley-ratings {
  display: flex;
  flex-direction: column;
  align-items: center;

  h4 {
    margin-top: 0;
    margin-bottom: 1rem;
  }
}

.smileys {
  display: flex;
  gap: 16px;

  --smiley-fill: #fff;

  // hide the actual radio buttons
  input {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
  }

  &.smileys-color {
    --smiley1: rgb(246, 126, 129);
    --smiley2: rgb(255, 179, 88);
    --smiley3: rgb(255, 204, 42);
    --smiley4: rgb(174, 215, 27);
    --smiley5: rgb(73, 180, 120);

    .smiley-circle {
      fill: var(--smiley-fill);
      stroke: none;
    }

    input + label {
      cursor: pointer;

      .smiley-circle {
        opacity: 0.5;
      }
    }

    input:checked + label {
      cursor: pointer;

      .smiley-circle {
        opacity: 1;
        stroke: #595959;
        stroke-width: 2;
      }
    }

    .smiley1 {
      --smiley-fill: var(--smiley1);
    }

    .smiley2 {
      --smiley-fill: var(--smiley2);
    }

    .smiley3 {
      --smiley-fill: var(--smiley3);
    }

    .smiley4 {
      --smiley-fill: var(--smiley4);
    }

    .smiley5 {
      --smiley-fill: var(--smiley5);
    }
  }

  &.smileys-mono {
    .smiley-circle {
      fill: var(--smiley-fill);
      stroke: #595959;
      stroke-width: 2;
    }

    input + label {
      cursor: pointer;
      --smiley-fill: #fff;
    }

    input:checked + label {
      cursor: pointer;
      --smiley-fill: #ffb800;
    }
  }
}
