$label-centered: translateY(-28px) scale(1);
$label-floated: translateY(-38px) scale(0.8);

.form-floating-labels {
  label {
    position: absolute; //removes label height
    padding-left: 0.5rem;
    margin: 0;
    pointer-events: none;
    transform: $label-centered;
    transform-origin: left;
    transition: all 150ms linear;
  }

  @include media-breakpoint-down(sm) {
    .form-row {
      margin-right: -1rem;
      margin-left: -1rem;

      [class*='col-'] {
        padding-right: 1rem;
        padding-left: 1rem;
      }
    }

    .row,
    .form-row {
      display: flex;
      flex-wrap: wrap;

      [class*='col-'] {
        border-top: 1px solid var(--border_color);

        &:not(:first-child) {
          border-left: 1px solid var(--border_color);
        }
        &.col-xs-12 {
          border-left: none;
        }
        &:not(.col-xs-12) {
          flex: 1; // fix for safari wrap issue
        }
      }

      &:last-child {
        border-bottom: 1px solid var(--border_color);
      }

      [class*='col-xs-12'] {
        border-left: none;
      }

      .invalid-feedback {
        padding-left: 0.5rem;
      }
    }

    input:focus {
      box-shadow: none !important;
    }
  }

  input[type='text'],
  input[type='email'],
  input[type='password'],
  input[type='number'] {
    padding-top: 14px;
    padding-bottom: 2px;
    padding-left: 5px;
    font-size: 14px;

    @include media-breakpoint-down(sm) {
      font-size: 16px; // fix safari zoom

      &.ng-touched,
      &.is-empty:not(:focus) {
        & ~ label {
          font-size: 12px;
        }
      }
    }

    &:focus {
      & ~ label {
        padding-left: 8px;
        transform: $label-floated;
      }
    }

    &.ng-dirty {
      & ~ label {
        padding-left: 8px;
        transform: $label-floated;
      }
    }

    &.ng-touched {
      & ~ label {
        padding-left: 8px;
        transform: $label-floated;
      }
    }

    &.is-empty:not(:focus) {
      & ~ label {
        padding-left: 8px;
        transform: $label-centered;
      }
    }

    &.ng-untouched.is-value {
      & ~ label {
        padding-left: 8px;
        transform: $label-floated;
      }
    }

    @include media-breakpoint-down(sm) {
      border: 0;
      border-radius: 0;
    }
  }

  select {
    padding-top: 14px;
    padding-bottom: 2px;
    padding-left: 5px;
    border: 1px solid var(--input_border_color);
    font-size: 14px;

    &:focus {
      border: 1px solid var(--input_border_color);
    }

    &.is-empty {
      color: var(--text);
    }

    &.is-value ~ label {
      padding-left: 8px;
      transform: $label-floated;
    }

    @include media-breakpoint-down(sm) {
      border: 0;
      border-radius: 0;
      font-size: 16px; // fix safari zoom

      &:focus {
        box-shadow: none !important;
      }

      &.is-empty ~ label {
        font-size: 12px;
      }
    }
  }

  .is-invalid {
    border-bottom-color: $state-danger-text !important;
    box-shadow: none;
  }
}
