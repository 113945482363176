.ng-select {
  &.ng-select-opened {
    > .ng-select-container {
      border-color: var(--input_border_color);
      background-color: var(--white);

      &:hover {
        @include focused;
      }

      .ng-arrow {
        top: -2px;
        border-width: 0 5px 5px;
        border-color: transparent transparent var(--rich500);

        &:hover {
          border-color: transparent transparent var(--rich500);
        }
      }
    }

    &.ng-select-bottom > .ng-select-container {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &.ng-select-top > .ng-select-container {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  &.ng-select-focused {
    &:not(.ng-select-opened) {
      .ng-select-container {
        border-color: var(--input_border_color);
        @include focused;
      }
    }
  }

  &.ng-select-disabled > .ng-select-container {
    background-color: #e1e1e1;
    .ng-arrow {
      border-color: var(--gray300) transparent transparent;
    }
    .ng-value-container .ng-placeholder {
      color: var(--gray300);
    }
    .bgwhite & {
      background-color: #e1e1e1;
      .ng-arrow {
        border-color: var(--gray300) transparent transparent;
      }
      .ng-value-container .ng-placeholder {
        color: var(--gray300);
      }
    }
    .bgdark & {
      background-color: #2a3038;
      .ng-value-container .ng-placeholder {
        color: var(--dark_input_disabled_bg);
      }
    }
  }

  .ng-has-value .ng-placeholder {
    display: none;
  }

  .ng-select-container {
    min-height: 36px;
    align-items: center;
    border: 1px solid var(--input_border_color);
    background-color: var(--white);
    border-radius: var(--border_radius);

    .bgwhite & {
      background-color: var(--form_control_bg_richcolor);
    }

    .bgdark & {
      background-color: var(--dark_input_bg);
      color: var(--dark_fg);
      border: 1px solid var(--dark_input_border);
    }

    .ng-value-container {
      align-items: center;
      padding-left: 10px;

      .ng-placeholder {
        color: var(--text);

        .bgdark & {
          color: var(--dark_fg);
        }
      }
    }
  }

  &.ng-select-single .ng-select-container {
    height: 36px;

    .ng-value-container .ng-input {
      top: 5px;
      left: 0;
      padding-right: 50px;
      padding-left: 10px;
    }
  }

  &.ng-select-multiple {
    &.ng-select-disabled > .ng-select-container .ng-value-container .ng-value {
      border: 1px solid #e3e3e3;
      background-color: #f9f9f9;

      .ng-value-label {
        padding: 0 5px;
      }
    }

    .ng-select-container .ng-value-container {
      padding-top: 5px;
      padding-left: 7px;

      .ng-value {
        margin-right: 5px;
        margin-bottom: 5px;
        border: 1px solid var(--border-color);
        background-color: var(--token_color);
        color: var(--text);
        border-radius: 2px;

        .bgdark & {
          background-color: var(--dark_token_color);
          color: #000;

          .ng-value-label {
            color: #000;
          }
        }

        // font-size: 0.9em;
        .bgwhite & {
          background-color: var(--white);
        }

        &.ng-value-disabled {
          border: 1px solid #e3e3e3;
          background-color: #f9f9f9;

          .ng-value-label {
            padding-left: 5px;
          }
        }

        .ng-value-label {
          display: inline-block;
          padding: 0 5px;
          color: var(--text);
        }

        .ng-value-icon {
          display: inline-block;
          padding: 0 5px;

          &:hover {
            background-color: #d8eafd;
          }

          &.left {
            border-right: 1px solid var(--border-color);
          }

          &.right {
            border-left: 1px solid var(--border-color);
          }
        }
      }

      .ng-input {
        padding-bottom: 3px;
        padding-left: 3px;
      }

      .ng-placeholder {
        top: 5px;
        padding-bottom: 5px;
        padding-left: 3px;
      }
    }
  }
  &.add-item-dropdown .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    .ng-tag-label {
      font-size: 100%;
    }
    .ng-tag-label::before {
      font-family: 'pbi-icon-outline';
      display: inline-block;
      margin-left: 0.5em;
      margin-right: 0.5rem;
      vertical-align: -0.1em;
      font-size: var(--font_size_lg);
      font-weight: normal;
      content: '\eac6';
    }
  }
  .ng-clear-wrapper {
    color: #999;

    .ng-clear:hover {
      color: #d0021b;
    }
  }

  .ng-spinner-zone {
    padding-top: 5px;
    padding-right: 5px;
  }

  .ng-arrow-wrapper {
    width: 25px;
    padding-right: 5px;

    .ng-arrow {
      border-width: 5px 5px 2.5px;
      border-style: solid;
      border-color: var(--rich500) transparent transparent;

      &:hover {
        border-top-color: var(--rich500);
      }

      .bgdark & {
        border-color: var(--dark_fg) transparent transparent;
      }
      + .ng-select-disabled {
        border-color: #999 transparent transparent;
      }
    }
  }
}

.bgdark .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input > input {
  color: var(--dark_fg);
}

.ng-dropdown-panel {
  border: 1px solid var(--input_border_color);
  background-color: #fff;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);

  .bgdark & {
    background-color: var(--dark_input_bg);
    color: var(--dark_fg);
    border: 1px solid var(--dark_input_border);
  }

  &.ng-select-bottom {
    top: 100%;
    margin-top: -1px;
    border-top-color: var(--input_border_color);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    .bgdark & {
      border-top-color: var(--dark_input_border);
    }

    .ng-dropdown-panel-items .ng-option:last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  &.ng-select-top {
    bottom: 100%;
    margin-bottom: -1px;
    border-bottom-color: #e6e6e6;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    .ng-dropdown-panel-items .ng-option:first-child {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
  }

  .ng-dropdown-header {
    padding: 5px 7px;
    border-bottom: 1px solid var(--border_color);
  }

  .ng-dropdown-footer {
    padding: 5px 7px;
    border-top: 1px solid var(--border_color);
  }

  .ng-dropdown-panel-items {
    margin-bottom: 1px;

    .ng-optgroup {
      padding: 8px 10px;
      color: rgba(0, 0, 0, 0.54);
      cursor: default;
      cursor: pointer;
      // font-weight: 500;
      user-select: none;

      &.ng-option-disabled {
        cursor: default;
      }

      &.ng-option-marked {
        background-color: var(--menu_hover);
      }

      &.ng-option-selected {
        background-color: var(--menu_hover);

        &.ng-option-marked {
          background-color: var(--menu_hover);
        }
      }
    }

    .ng-option {
      padding: 0.5rem 1rem;
      background-color: transparent;
      color: var(--text);

      input[type='checkbox'] {
        appearance: none;
        margin: 0 4px 0 0;
        font: inherit;
        color: currentColor;
        width: 1rem;
        height: 1rem;
        border: 1px solid var(--btn_primary);
        background-color: var(--white);
        line-height: 1.25;
        border-radius: 2px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        transform: translateY(2px);

        .bgdark & {
          background-color: var(--dark_input_bg);
        }

        &:checked {
          background-color: var(--btn_primary);
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
          background-position: center center;
          background-repeat: no-repeat;
          background-size: 50% 50%;
          color: #fff;
        }
      }

      &.ng-option-selected {
        background-color: var(--menu_hover);
        color: var(--text);
      }

      &.ng-option-marked {
        background-color: var(--menu_hover);
        color: var(--text);
        @include focused;
        outline-offset: -2px;
        .bgdark & {
          background-color: var(--dark_menu_hover);
          color: #fff;
        }
      }

      &.ng-option-disabled {
        color: var(--disabled);
      }

      &.ng-option-child {
        padding-left: 22px;
      }

      .ng-tag-label {
        padding-right: 5px;
        font-size: 80%;
        font-weight: 400;
      }
    }
  }
  .dropdown-option-close {
    font-size: var(--font_size_sm);
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  background-color: var(--menu_hover);
  color: var(--text);

  .bgdark & {
    background-color: var(--dark_menu_hover);
    color: #fff;
  }
}
