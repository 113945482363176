.signin {
  @include gradient-angled(var(--grad_start), var(--grad_mid), var(--grad_end));
  display: grid;
  align-items: center;
  // overflow: hidden;
  height: 100vh;
  overflow-y: auto;
  background-color: var(--grad_start);

  @include media-breakpoint-only(xs) {
    height: 110%;
    overflow: auto;
  }

  .signin-wrapper {
    width: 400px;
    margin: 0 auto;

    @include media-breakpoint-only(xs) {
      width: 90%;
      margin: 1rem auto 2.5rem;
    }
  }

  .signin-logo {
    @include media-breakpoint-only(xs) {
      height: 50px;
    }

    display: block;
    height: 56px;
    margin: 2rem 0 1rem;
    background-image: url($pb-signin-logo);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  // white box
  .signin-box {
    padding: 2rem;
    margin-bottom: 6rem;
    background-color: #fff;
    border-radius: 6px;

    @include media-breakpoint-only(xs) {
      padding: 1.25rem 1.875rem;

      .signin-title {
        margin-top: 0;
        font-size: 1.625rem;

      }

    }

    .bgdark & {
      background-color: var(--dark_bg);
    }

    .signin-title {
      margin: 0;
      color: var(--text);
      text-align: center;

      sup {
        left: -0.4rem;
        font-size: 1.25rem;
      }


      .bgdark & {
        color: var(--white);
      }
    }

    h1,
    h2 {
      margin-top: 0;
      line-height: 1.25;
    }

    .signin-subtitle {
      color: var(--text);
      font-size: 0.875rem;
      font-weight: normal;
      text-align: center;
      font-family: var(--font_family_brand_regular);
    }
  }

  .alert {

    p,
    li {
      margin-bottom: unset;
    }

    ul {
      margin-bottom: 0;
    }
  }
}