.pbds-multiple-values {
  max-height: var(--pbds-multiple-values-max-height);
  height: auto !important;
  overflow-y: auto;
  white-space: pre-wrap; // handle paste text formatting

  &.is-placeholder {
    color: var(--placeholder_color);
  }

  &[contenteditable='false'] {
    border-color: #ddd;
    background-color: #e1e1e1;
    color: var(--dark_input_disabled_text);
    opacity: 1;
    pointer-events: none;
  }
}
