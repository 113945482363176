.led_white {
  width: 20px;
  height: 20px;
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  border: 1px solid gray;
}

.led_blue {
  width: 20px;
  height: 20px;
  background-color: rgb(0, 155, 223);
  border-radius: 50%;
  border: 1px solid gray;
}

.led_red {
  width: 20px;
  height: 20px;
  background-color: rgb(253, 38, 64);
  border-radius: 50%;
  border: 1px solid gray;
}

.led_green {
  width: 20px;
  height: 20px;
  background-color: rgb(0, 255, 0);
  border-radius: 50%;
  border: 1px solid gray;
}

.led_amber {
  width: 20px;
  height: 20px;
  background-color: rgb(255, 128, 0);
  border-radius: 50%;
  border: 1px solid gray;
}

.led_off {
  width: 20px;
  height: 20px;
  background-color: rgb(150, 150, 150);
  border-radius: 50%;
}
