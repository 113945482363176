.scrollspy {
  position: sticky;
  top: 0;
  padding-top: 1.5rem;

  @include media-breakpoint-down(lg) {
    display: none;
  }

  a {
    color: var(--text);
    cursor: pointer;
    text-decoration: none !important;

    &.active {
      color: var(--primary);
    }
  }

  > ul {
    list-style-type: none;

    @include media-breakpoint-up(md) {
      padding-left: 0;
    }

    > li {
      margin-bottom: 1rem;
      font-weight: bold;
    }

    ul {
      padding-left: 0;
      margin-top: 4px;
      list-style-type: none;

      > li {
        font-size: 12px;
        font-weight: normal;
      }
    }
  }

  .back-to-top {
    font-weight: normal;

    &::before {
      content: '\e915\0020';
      font-family: 'pbi-icon-mini';
    }
  }
}
