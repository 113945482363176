.miller-columns {
  $miller-height: 450px; // pick a height for scrolling of columns
  display: flex;
  height: $miller-height;
  border: 1px solid var(--border_color);

  .miller-column {
    flex-basis: 33%;
    overflow-y: auto;

    &:not(:last-of-type) {
      border-right: 1px solid var(--border_color);
    }

    .list-group {
      .list-group-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.75rem;
        margin-bottom: 0;
        border: 0;
        border-bottom: solid 1px var(--border_color);
        border-radius: 0;
        font-family: var(--font-family-sans-serif);
        font-size: var(--font_size_base);
        transition:
          background-color 300ms linear,
          color 200ms linear;
        color: var(--text);
        background-color: transparent;

        .badge {
          background-color: var(--secondary);
        }

        &:hover {
          background-color: var(--btn_secondary_bg_active);
        }

        &.active,
        &:focus {
          background-color: var(--rich200);

          &:hover {
            background-color: var(--rich300);
          }
        }
      }
    }
    &.miller-column-detail {
      .list-group-item {
        display: block;
      }
    }
  }
}
