.mat-drawer-container {
  height: 100%;
}

.action-list-panel {
  .mat-sidenav-container .mat-sidenav .mat-drawer-inner-container {
    padding: 2rem;
    height: unset;
  }
  .mat-drawer-backdrop {
    position: fixed !important;
    background-color: rgba(0, 0, 0, 0.25);
  }
  .mat-sidenav-container .mat-sidenav {
    margin-top: 70px;
    width: 400px;
    color: inherit;
  }
  .mat-drawer:not(.mat-drawer-side) {
    box-shadow:
      0 8px 10px -5px #0003,
      0 16px 24px 2px #00000024,
      0 6px 30px 5px #0000001f;
  }
  .mat-sidenav-fixed {
    position: fixed;
  }
  .mat-drawer {
    z-index: 4;

    display: block;
    outline: 0;
    box-sizing: border-box;
    overflow-y: auto;
  }
  .mat-sidenav-container {
    position: initial !important;
    background-color: transparent;
    overflow: unset !important;
    color: inherit;
  }

  .mat-sidenav-container .mat-sidenav .close {
    position: relative;
    float: none !important;
    top: unset;
    right: unset;
    width: 1rem;
    height: 1rem;
    margin: 0;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9 Im5vIj8+Cjxzdmcgd2lkdGg9IjE3cHgiIGhlaWdodD0iMTdweCIgdmlld0JveD0i MCAwIDE3IDE3IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9y Zy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkv eGxpbmsiPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ry b2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVs ZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpv aW49InJvdW5kIj4KICAgICAgICA8ZyBpZD0iaWNvbi1iaXRzIiB0cmFuc2Zvcm09 InRyYW5zbGF0ZSgtMTYxLjAwMDAwMCwgLTE1OC4wMDAwMDApIiBzdHJva2U9IiMz RTUzQTQiIHN0cm9rZS13aWR0aD0iMiI+CiAgICAgICAgICAgIDxnIGlkPSJjbG9z ZSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTY5LjUwMDAwMCwgMTY2LjUwMDAwMCkg cm90YXRlKC00NS4wMDAwMDApIHRyYW5zbGF0ZSgtMTY5LjUwMDAwMCwgLTE2Ni41 MDAwMDApIHRyYW5zbGF0ZSgxNTkuMDAwMDAwLCAxNTYuMDAwMDAwKSI+CiAgICAg ICAgICAgICAgICA8cGF0aCBkPSJNMjAsMTAuNSBMMS4xMjI2NTc1MmUtMTIsMTAu NSIgaWQ9IkxpbmUiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0x MCwwLjUgTDEwLDIwLjUiIGlkPSJMaW5lIj48L3BhdGg+CiAgICAgICAgICAgIDwv Zz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 1rem 1rem;
    color: var(--primary);
    opacity: 1;
  }
  .action-panel-grid {
    display: grid;
    grid-template-columns: 0.5fr 1.5fr;
    height: 100vh;
    gap: 1rem;
  }
}
