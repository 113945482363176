@mixin rounded($radius: 2px) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

$enableNgDeep: false;

$handleActiveColor: var(--secondary);
$handleHoverColor: #fff !default;
$labelTextColor: var(--text);
$handleBgColor: var(--primary);
$handleInnerColor: #fff !default;
$handleDisabledColor: #d8e0f3 !default;
$limitLabelTextColor: $labelTextColor !default;
$barFillColor: var(--primary);
$barDisabledFillColor: var(--rich400);
$barNormalColor: var(--rich300);
$barLeftOutSelectionColor: #df002d !default;
$barRightOutSelectionColor: #03a688 !default;

$ticksColor: $barNormalColor !default;
$selectedTicksColor: $barFillColor !default;
$ticksWidth: 10px !default;
$ticksHeight: 10px !default;
$ticksValuePosition: -34px !default;
$ticksLegendPosition: 24px !default;
$ticksValuePositionOnVertical: 24px !default;
$tickLegendMaxWidth: 50px !default;

$handleSize: 24px;
$handlePointerSize: 8px !default;
$bubblePaddingVertical: 1px !default;
$bubblePaddingHorizontal: 3px !default;
$labelFontSize: var(--font_size_base);
$barDimension: 4px !default;

$withLegendMargin: 40px !default;

$sliderMargin: 15px !default;
$sliderMarginWithLabel: 35px !default;
$sliderVerticalMargin: 20px !default;

$animationDuration: 0.3s !default;

.pb-slider {
  .ngx-slider {
    display: inline-block;
    position: relative;
    height: 4px;
    width: 100%;
    margin: $sliderMarginWithLabel 0 $sliderMargin 0;
    vertical-align: middle;
    user-select: none;
    touch-action: pan-y;

    &.with-legend {
      margin-bottom: $withLegendMargin;
    }

    &[disabled] {
      cursor: not-allowed;

      .ngx-slider-pointer {
        cursor: not-allowed;
        background-color: $handleDisabledColor;
      }

      .ngx-slider-draggable {
        cursor: not-allowed;
      }

      .ngx-slider-selection {
        background: $barDisabledFillColor;
      }

      .ngx-slider-tick {
        cursor: not-allowed;

        &.ngx-slider-selected {
          background: $barDisabledFillColor;
        }
      }
    }

    .ngx-slider-span {
      white-space: nowrap;
      position: absolute;
      display: inline-block;
    }

    .ngx-slider-base {
      width: 100%;
      height: 100%;
      padding: 0;
    }

    .ngx-slider-bar-wrapper {
      left: 0;
      box-sizing: border-box;
      margin-top: -12px;
      padding-top: 12px;
      width: 100%;
      height: 24px;
      z-index: 1;
    }

    .ngx-slider-draggable {
      cursor: move;
    }

    .ngx-slider-bar {
      left: 0;
      width: 100%;
      height: 3px;
      z-index: 1;
      background: $barNormalColor;
      @include rounded(2px);
    }

    .ngx-slider-bar-wrapper.ngx-slider-transparent .ngx-slider-bar {
      background: transparent;
    }

    .ngx-slider-bar-wrapper.ngx-slider-left-out-selection .ngx-slider-bar {
      background: $barLeftOutSelectionColor;
    }

    .ngx-slider-bar-wrapper.ngx-slider-right-out-selection .ngx-slider-bar {
      background: $barRightOutSelectionColor;
    }

    .ngx-slider-selection {
      z-index: 2;
      background: $barFillColor;
      @include rounded(2px);
    }

    .ngx-slider-pointer {
      cursor: pointer;
      width: 24px;
      height: 24px;
      top: -10px;
      background-color: $handleBgColor;
      z-index: 3;
      @include rounded(12px);

      &::after {
        content: '';
        width: $handlePointerSize;
        height: $handlePointerSize;
        position: absolute;
        top: 8px;
        left: 8px;
        @include rounded(4px);
        background: $handleInnerColor;
      }

      &:hover::after {
        background-color: $handleHoverColor;
      }

      &.ngx-slider-active {
        z-index: 4;

        &::after {
          background-color: $handleActiveColor;
        }
      }
    }

    .ngx-slider-bubble {
      cursor: default;
      bottom: 12px;
      padding: $bubblePaddingVertical $bubblePaddingHorizontal;
      color: $labelTextColor;
      font-size: $labelFontSize;

      &.ngx-slider-limit {
        color: $limitLabelTextColor;
      }
    }

    .ngx-slider-ticks {
      box-sizing: border-box;
      width: 100%;
      height: 0;
      position: absolute;
      left: 0;
      top: -3px;
      margin: 0;
      z-index: 1;
      list-style: none;
    }

    .ngx-slider-ticks-values-under {
      .ngx-slider-tick-value {
        top: auto;
        bottom: $ticksValuePosition - 2;
      }
    }

    .ngx-slider-tick {
      text-align: center;
      cursor: pointer;
      width: $ticksWidth;
      height: $ticksHeight;
      background: $ticksColor;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
      margin-left: 7px; // for centering

      &.ngx-slider-selected {
        background: $selectedTicksColor;
      }
    }

    .ngx-slider-tick-value {
      position: absolute;
      top: $ticksValuePosition;
      transform: translate(-50%, 0);
    }

    .ngx-slider-tick-legend {
      position: absolute;
      top: $ticksLegendPosition;
      transform: translate(-50%, 0);
      max-width: $tickLegendMaxWidth;
      white-space: normal;
    }

    &.vertical {
      position: relative;
      width: $barDimension;
      height: 100%;
      margin: 0 $sliderVerticalMargin;
      padding: 0;
      vertical-align: baseline;
      touch-action: pan-x;

      .ngx-slider-base {
        width: 100%;
        height: 100%;
        padding: 0;
      }

      .ngx-slider-bar-wrapper {
        top: auto;
        left: 0;
        margin: 0 0 0 -12px;
        padding: 0 0 0 12px;
        height: 100%;
        width: 24px;
      }

      .ngx-slider-bar {
        bottom: 0;
        left: auto;
        width: $barDimension;
        height: 100%;
      }

      .ngx-slider-pointer {
        left: -10px !important;
        top: auto;
        bottom: 0;
      }

      .ngx-slider-bubble {
        left: 12px !important;
        bottom: 0;
      }

      .ngx-slider-ticks {
        height: 100%;
        width: 0;
        left: -3px;
        top: 0;
        z-index: 1;
      }

      .ngx-slider-tick {
        vertical-align: middle;
        margin-left: auto;
        margin-top: 7px; // for centering
      }

      .ngx-slider-tick-value {
        left: $ticksValuePositionOnVertical;
        top: auto;
        transform: translate(0, -28%);
      }

      .ngx-slider-tick-legend {
        top: auto;
        right: $ticksLegendPosition;
        transform: translate(0, -28%);
        max-width: none;
        white-space: nowrap;
      }

      .ngx-slider-ticks-values-under {
        .ngx-slider-tick-value {
          bottom: auto;
          left: auto;
          right: $ticksValuePositionOnVertical;
        }
      }
    }

    * {
      transition: none;
    }

    &.animate {
      .ngx-slider-bar-wrapper {
        transition: all linear $animationDuration;
      }

      .ngx-slider-selection {
        transition: background-color linear $animationDuration;
      }

      .ngx-slider-pointer {
        transition: all linear $animationDuration;
      }

      .ngx-slider-bubble {
        transition: all linear $animationDuration;

        &.ngx-slider-limit {
          transition: opacity linear $animationDuration;
        }

        &.ngx-slider-combined {
          transition: opacity linear $animationDuration;
        }
      }

      .ngx-slider-tick {
        transition: background-color linear $animationDuration;
      }
    }
  }
}
