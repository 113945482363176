.pbds-chart {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .axis {
    &.axis-hidden {
      .tick {
        display: none;
      }
    }

    &.axis-zero-hidden .domain + .tick {
      display: none;
    }

    &.axis-domain-hidden {
      .domain {
        display: none;
      }
    }

    &.axis-ticks-hidden {
      .tick line {
        display: none;
      }
    }

    .domain {
      stroke: var(--rich400);
    }

    .tick {
      text {
        fill: var(--text);
        font-size: 14px;
      }

      line {
        stroke: var(--rich300);
      }
    }

    &-x {
      text-anchor: middle;
    }

    &-gridline .tick line {
      stroke: var(--rich300);
    }
  }

  .axis-title {
    fill: var(--text);
  }

  .grid {
    .domain {
      display: none;
    }

    line {
      color: var(--rich300);
    }

    .tick text {
      display: none;
    }

    &.grid-zero-hidden {
      .domain + .tick {
        display: none;
      }
    }
  }

  &-legend-bottom {
    flex-direction: column;
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }

  .legend {
    padding: 0;

    &.legend-right {
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
      margin-left: 2em;

      // .legend-item {
      //   &:last-child {
      //     // margin-bottom: 0;
      //   }
      // }

      @include media-breakpoint-down(sm) {
        width: auto !important;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 1rem;
        margin-left: 0;
      }
    }

    &.legend-bottom {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 1rem;
      margin-bottom: 0;
      margin-left: 0;
      padding-left: 24px;

      @include media-breakpoint-down(sm) {
        margin-right: 0;
      }

      .legend-item,
      .legend-static {
        padding-right: 1.5rem;
        margin-bottom: 0rem;

        @include media-breakpoint-down(sm) {
          padding-right: 0;
        }

        &:last-child {
          padding-right: 0;
        }
      }
    }

    .legend-item {
      display: flex;
      align-items: center;
      padding-bottom: 0.5rem;
      margin-bottom: 0;
      list-style: none;

      &:hover {
        cursor: pointer;
      }

      &.inactive {
        opacity: 0.3;
      }

      @include media-breakpoint-down(sm) {
        margin-right: 1.5rem;

        &:last-child {
          margin-bottom: 0.5rem;
        }
      }

      .legend-key {
        display: inline-block;
        min-width: 15px;
        height: 15px;
        margin-right: 10px;
        border-radius: 50%;
        transition: background-color linear 150ms;
      }

      .legend-label {
        color: var(--text);
      }

      .legend-description {
        .legend-label {
          display: block;
          overflow: hidden;
          color: var(--text);
          font-family: var(--font_family_base);
          text-transform: capitalize;
        }

        .legend-value {
          display: block;
          color: var(--text);
        }
      }
    }

    .legend-static {
      list-style: none;
      display: flex;
      align-items: center;
      padding-bottom: 0.5rem;
      margin-bottom: 0;

      @include media-breakpoint-down(sm) {
        margin-right: 1.5rem;
      }

      &.legend-average {
        .legend-key {
          display: inline-block;
          width: 15px;
          height: 2px;
          margin-right: 10px;
          border-bottom: 2px dashed var(--text);
          vertical-align: middle;
        }

        .legend-label {
          color: var(--text);
        }
      }

      &.legend-threshold {
        .legend-key {
          display: inline-block;
          width: 15px;
          height: 2px;
          margin-right: 10px;
          border-bottom: 2px solid #d30c0c;
          vertical-align: middle;
        }

        .legend-label {
          color: var(--text);
        }
      }
    }
  }

  &.pbds-chart-pie {
    .slice {
      opacity: 1;

      &:hover {
        cursor: pointer;
      }

      &.inactive {
        opacity: 0.3;
      }
    }
  }

  &.pbds-chart-gauge {
    .gauge-background {
      fill: #e1e1e1;
      opacity: 0.4;
    }

    .gauge-details {
      position: absolute;
      display: flex;
      height: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &.halfmoon {
        justify-content: flex-end;
      }

      .gauge-number {
        color: var(--text);
        font-family: var(--font_family_brand_light);
        font-size: 50px;
        line-height: 1;

        @include media-breakpoint-only(sm) {
          font-size: 30px;
        }

        @include media-breakpoint-only(md) {
          font-size: 40px;
        }
      }

      .gauge-description {
        color: var(--rich400);
        font-size: 14px;
        line-height: 1.2;
      }

      &.gauge-details-small {
        .gauge-number {
          @include media-breakpoint-only(xs) {
            font-size: 35px;
          }

          @include media-breakpoint-only(sm) {
            font-size: 20px;
          }

          @include media-breakpoint-only(md) {
            font-size: 30px;
          }

          @include media-breakpoint-only(lg) {
            font-size: 30px;
          }

          @include media-breakpoint-only(xl) {
            font-size: 40px;
          }
        }
      }
    }
  }

  &.pbds-chart-bar {
    .gray-bar {
      fill: #e1e1e1;
      opacity: 0.4;
    }

    .bar {
      cursor: pointer;
      &.inactive {
        opacity: 0.3;
      }
      &[height='0'] {
        height: 0.01px;
      }
    }

    .legend {
      width: 105px;

      &.legend-right {
        .legend-item {
          &:last-child {
            margin-bottom: 0;
          }

          .legend-label {
            overflow: hidden;
          }
        }
      }

      &.legend-bottom {
        width: auto;
      }
    }

    .threshold {
      stroke: #d30c0c;
      stroke-width: 1px;
    }

    .average {
      stroke: var(--text);
      stroke-dasharray: 10, 10;
      stroke-width: 1px;
    }
  }

  &.pbds-chart-bar-grouped {
    .gray-bar {
      fill: #e1e1e1;
      opacity: 0.4;
    }

    .bar-group {
      .bar {
        cursor: pointer;

        &.inactive {
          opacity: 0.3;
        }
      }
    }

    .legend {
      width: 105px;

      &.legend-right {
        .legend-item {
          &:last-child {
            margin-bottom: 0;
          }

          .legend-label {
            overflow: hidden;
          }
        }
      }

      &.legend-bottom {
        width: auto;
      }
    }

    .threshold {
      stroke: #d30c0c;
      stroke-width: 1px;
    }

    .average {
      stroke: var(--text);
      stroke-dasharray: 10, 10;
      stroke-width: 1px;
    }
  }

  &.pbds-chart-stacked-bar {
    .gray-bar {
      fill: #e1e1e1;
      opacity: 0.4;
    }

    .bar-group {
      &.inactive {
        opacity: 0.3;
      }

      .bar {
        cursor: pointer;

        &.bar-divided {
          stroke: var(--rich200);
          stroke-width: 2px;

          &[height='0'] {
            height: 0.01px;
          }
        }

        &.inactive {
          opacity: 0.3;
        }
      }
    }

    .bottom-bar-tick {
      opacity: 0.5;
    }

    .legend {
      width: 105px;

      &.legend-right {
        .legend-item {
          // &:last-child {
          //   // margin-bottom: 0;
          // }

          .legend-label {
            overflow: hidden;
          }
        }
      }

      &.legend-bottom {
        width: auto;
      }
    }

    .centerline {
      stroke: var(--text);
      stroke-width: 1px;
    }
  }

  &.pbds-chart-bar-single-horizontal {
    .grid {
      &.grid-x {
        .tick:first-of-type {
          line {
            stroke: var(--rich300);
          }
        }
      }
    }

    .bar {
      cursor: pointer;

      &.inactive {
        opacity: 0.3;
      }
    }

    .bottom-bar-tick {
      opacity: 0.5;
    }

    .legend {
      width: 105px;

      &.legend-right {
        .legend-item {
          // &:last-child {
          //   // margin-bottom: 0;
          // }

          .legend-label {
            overflow: hidden;
          }
        }
      }

      &.legend-bottom {
        width: auto;
      }
    }
  }

  &.pbds-chart-line {
    .mouserect {
      fill: none;
      pointer-events: all;
    }

    .tooltip-line {
      opacity: 0;
      stroke: #8b98c8;
      stroke-dasharray: 4, 2;
      stroke-width: 2;

      &.active {
        opacity: 1;
      }
    }

    .line {
      fill: none;
      stroke: currentColor;
      stroke-linecap: round;

      &.inactive {
        opacity: 0.3;
      }

      &.active {
        .area {
          opacity: 0.6;
        }
      }
    }

    circle {
      fill: #fff;
      stroke: currentColor;

      &.active {
        fill: currentColor !important;
      }

      &.inactive {
        stroke-opacity: 0.3;
      }
    }

    .area {
      fill: currentColor;
      opacity: 0.3;

      &.inactive {
        opacity: 0.1;
      }
    }
  }

  &.pbds-chart-bubble-map {
    .map {
      .feature {
        fill: var(--rich600);
        opacity: 0.1;
        stroke: none;

        .bgdark & {
          fill: var(--dark_bg_accent);
          opacity: 1;
        }
      }
    }

    .mesh {
      fill: none;
      stroke-linejoin: round;
      stroke: #fff;

      .bgdark & {
        stroke: var(--dark_input_border);
      }
    }

    .dots {
      .dot-circle {
        fill: currentColor;
        fill-opacity: 0.5;
        stroke-width: 1;
        stroke-opacity: 0.8;
        stroke: currentColor;

        &.solid {
          fill-opacity: 1;
          stroke-opacity: 1;
        }

        &.active {
          fill-opacity: 1;
          stroke-opacity: 1;
        }
      }

      .dot-text {
        text-anchor: middle;
        pointer-events: none;
      }
    }
  }

  &.pbds-chart-heatmap {
    .block {
      &.empty {
        fill: #e1e1e1 !important;
      }

      &.inactive {
        // opacity: 0.3;
        fill: #e1e1e1 !important;
      }
    }
  }

  &.pbds-chart-choropleth-map {
    .map {
      .feature {
        fill: var(--rich600);
        // opacity: 0.1;
        stroke: none;
      }
    }

    .mesh {
      fill: none;
      stroke-linejoin: round;
      stroke: #fff;
    }

    .legend-label {
      font-size: 0.75rem;
      fill: currentColor;
    }

    .legend {
      .tick {
        line {
          stroke: #222222;
        }

        text {
          fill: var(--text);
        }
      }
    }
  }

  &.pbds-chart-scatterplot {
    circle {
      cursor: pointer;
      fill-opacity: 0.25;
      stroke-width: 1;

      &.active {
        fill-opacity: 1;
      }

      &.inactive {
        opacity: 0.3;
      }
    }
  }

  &.pbds-annotation-edit {
    .pbds-annotation-add {
      cursor:
        url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='36' height='36'%3E%3Cdefs%3E%3Cfilter id='shadow' x='-1' y='-1' width='50' height='50' %3E%3CfeOffset result='offOut' in='SourceAlpha' dx='0' dy='2' /%3E%3CfeColorMatrix result='matrixOut' in='offOut' type='matrix' values=' 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0' /%3E%3CfeGaussianBlur result='blurOut' in='matrixOut' stdDeviation='2' /%3E%3CfeBlend in='SourceGraphic' in2='blurOut' mode='normal' /%3E%3C/filter%3E%3C/defs%3E%3Ccircle fill='%23fff' cx='16' cy='16' r='14' filter='url(%23shadow)'/%3E%3Ccircle fill='%23b70077' cx='16' cy='16' r='13'/%3E%3Cg transform='translate(8 8) scale(.5 .5)'%3E%3Cpath fill='%23fff' fill-rule='evenodd' d='M16 0C7.2 0 0 6.2 0 14s7.2 14 16 14c.8 0 1.6 0 2.2-.2L28 32v-8.8c2.4-2.4 4-5.6 4-9.2 0-7.8-7.2-14-16-14Z' clip-rule='evenodd'/%3E%3Cpath fill='%23b70077' fill-rule='evenodd' d='M14.142 5.5h3.716v6.642H24.5v3.716h-6.642V22.5h-3.716v-6.642H7.5v-3.716h6.642V5.5Z' clip-rule='evenodd'/%3E%3C/g%3E%3C/svg%3E")
          16 16,
        auto !important;
    }
  }

  .annotations {
    circle {
      fill: #3e53a4;
      stroke: #fff;
      stroke-width: 2px;
      -webkit-filter: drop-shadow(0 2px 2px rgb(0 0 0 / 0.4));
      filter: drop-shadow(0 2px 2px rgb(0 0 0 / 0.4));
      cursor: pointer;
    }

    text {
      fill: #fff;
      font-family: 'pbi-icon-mini';
      font-size: 17px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      pointer-events: none;
    }

    .hovered {
      circle {
        fill: #5d7bf4;
      }
    }

    &-hilight {
      fill: #003aff;
      fill-opacity: 0.05;
      pointer-events: none;
      // stroke-dasharray: 6;
      // stroke-linejoin: round;
      // stroke-linecap: round;
      // animation: pbds-dataviz-moving-dashes 1s linear infinite;
      // stroke: var(--blue700);
      // stroke-width: 2;

      &.hidden {
        opacity: 0;
      }
    }
  }
}

.pbds-tooltip {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: auto;
  height: auto;
  padding: 8px 8px;
  border: 0 none;
  background-color: rgba(#222, 0.8);
  border-radius: $border-radius-sm;
  box-shadow: -3px 3px 15px rgba(#888, 0.2);
  color: #fff;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  pointer-events: none !important; // override library since we're not supporting click drildowns
  text-align: center;
  transition: opacity linear 150ms;

  &.south::after {
    position: absolute;
    bottom: -8px;
    left: 50%;
    width: 0;
    height: 0;
    margin: 0 0 0 -8px;
    border-top: 8px solid rgba(#222, 0.8);
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    content: '';
  }

  &.west::after {
    position: absolute;
    top: 50%;
    left: -8px;
    width: 0;
    height: 0;
    margin: -8px 0 0 0;
    border-top: 8px solid transparent;
    border-right: 8px solid rgba(#222, 0.8);
    border-bottom: 8px solid transparent;
    content: '';
  }

  &.east::after {
    position: absolute;
    top: 50%;
    right: -8px;
    width: 0;
    height: 0;
    margin: -8px 0 0 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid rgba(#222, 0.8);
    content: '';
  }

  &.pbds-tooltip-compare {
    text-align: left;

    .tooltip-label {
      text-align: center;
    }
  }

  &-value {
    font-weight: bold;
  }

  &-label {
    color: rgba(#fff, 0.9);
  }

  &-key {
    display: inline-block;
    min-width: 15px;
    height: 15px;
    margin-right: 10px;
    border: 1px solid rgba(#fff, 0.3);
    background-color: currentColor;
    border-radius: 50%;
    transition: background-color linear 150ms;
    vertical-align: middle;
  }

  .metric-block-indicator {
    color: #c0c0c0;

    &.increase {
      color: #39ce25;

      &.inverse {
        color: #ff5d57;
      }
    }

    &.decrease {
      color: #ff5d57;

      &.inverse {
        color: #39ce25;
      }
    }
  }

  .tooltip-annotation {
    border-top: 1px solid rgba(#fff, 0.3);
    padding-top: 6px;
    margin-top: 10px;
    // font-style: italic;
    font-size: 16px;

    i {
      color: var(--yellow);
    }
  }
}

@keyframes pbds-dataviz-moving-dashes {
  100% {
    stroke-dashoffset: -24px;
  }
}
