.promo-hero:not(.promo-hero-image) {
  @include gradient-angled(var(--grad_start), var(--grad_mid), var(--grad_end));
}

// the promo hero image variant must be defined in the project's scss, due to the image:
// .promo-hero-image {
//   @include gradient-over-image('/assets/images/welcome/bg-imageDemo.jpg');
// }

.promo-hero {
  min-height: 25rem;

  .promo-hero-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 100px;
    h1 {
      text-align: center;
      color: #fff;
    }
  }

  .promohero-message-unit {
    max-width: 300px;
    margin: 0 2.5rem;
    text-align: center;
  }

  h3 {
    font-size: 1.5rem;
    margin-top: 0;
  }
  .container {
    padding-bottom: 100px;
  }

  .remind-me-link {
    color: #fff;
    text-decoration: underline;
    cursor: pointer;
  }
}

.promo-hero-page {
  .container {
    h1:first-of-type {
      margin-top: 1rem;
      transition: 0.2s margin-top;
    }
  }
}
