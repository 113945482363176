.fake-modal {
  .modal-content {
    border: 1px solid var(--border_color);
  }
}

.modal,
.fake-modal {
  //This is to always show tooltips/popovers above a modal
  .tooltip,
  .popover {
    z-index: $zindex-tooltip + $zindex-modal;
  }

  .modal-header {
    padding: 0 0 0.5rem;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid var(--border_color);

    .btn-close {
      position: absolute;
      top: 1rem;
      right: 1rem;
      width: 1rem;
      height: 1rem;
      margin: 0;
      background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2217%22%20height%3D%2217%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M15.218%201.782%201.075%2015.925m0-14.143%2014.143%2014.143%22%20stroke%3D%22%233E53A4%22%20stroke-width%3D%222%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3C%2Fsvg%3E');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 1rem 1rem;
      color: var(--primary);
      opacity: 1;

      @media (forced-colors: active) {
        background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2217%22%20height%3D%2217%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M15.218%201.782%201.075%2015.925m0-14.143%2014.143%2014.143%22%20stroke%3D%22%23fff%22%20stroke-width%3D%222%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3C%2Fsvg%3E');
      }

      .bgdark & {
        background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2217%22%20height%3D%2217%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M15.218%201.782%201.075%2015.925m0-14.143%2014.143%2014.143%22%20stroke%3D%22%23fff%22%20stroke-width%3D%222%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3C%2Fsvg%3E');
      }
    }
  }

  .modal-content {
    padding: 3rem;
    border-radius: var(--border_radius);
    border: 1px solid var(--border_color);
    color: var(--text);
    .bgdark & {
      background-color: var(--dark_bg);
      color: var(--dark_fg);
    }
  }

  .modal-body {
    padding: 0;
  }

  .modal-footer {
    justify-content: flex-start;
    padding: 0;
    margin-top: 1.5rem;
    border-top: 0;
    text-align: left;
    gap: 0.5rem;

    & > * {
      margin: 0;
    }

    .btn {
      min-width: 7rem;
    }
  }

  .modal-title {
    margin: 0;
    font-size: 1.5rem;
  }
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--black);
}

.bgdark .modal-backdrop.show {
  opacity: 0.8;
}

.modal-dialog.modal-sm {
  .modal-header {
    margin-bottom: 0.5rem;

    .close {
      top: 0.5rem;
      right: 0.5rem;
      width: 0.5rem;
      height: 0.5rem;
    }
  }

  .modal-title {
    font-size: 1rem;
  }

  .modal-content {
    padding: 1rem;
  }

  .modal-footer {
    margin-top: 0.5rem;

    .btn {
      min-width: 4rem;
    }
  }
}

[cdkDrag] {
  .modal-dialog {
    cursor: move;
  }
}

#aboutBoxModal {
  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;

    li.list-inline-item:not(:first-child) {
      margin-left: 0.5rem;
    }
  }

  .modal-header {
    margin-bottom: 0;
    border-bottom: 0;
  }
}

.modal.dark-modal {
  .modal-dialog {
    max-width: 500px;
    margin: 0rem 0 1.75rem auto;
  }
}

.modal-backdrop.transparent-backdrop {
  opacity: 0;
}
