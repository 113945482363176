// color swatches table
.swatches {
  padding: 0;
  margin: 0;

  tr > td:first-child {
    width: 325px;
    white-space: nowrap;
  }

  li,
  div {
    padding: 0.5rem;
    color: #fff;
    font-family: var(--font-family-monospace);
    font-size: var(--font_size_lg);
    list-style: none;

    &.bk {
      color: #000;
    }

    span {
      float: right;
    }
  }

  &.spaced {
    li {
      margin-bottom: 0.5rem;
    }
  }

  .header {
    font-family: var(--font-family-sans-serif);
    font-size: var(--font_size_lg);
    font-weight: bold;
  }
}

.colors {
  display: grid;
  margin-bottom: 2rem;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));

  &.colors-no-hover {
    .color-swatch {
      &:hover {
        cursor: default;

        .color-swatch-color {
          box-shadow: none;
          transform: none;
        }
      }
    }
  }
}

@mixin swatch-detail-border-top {
  padding-top: 0.25rem;
  border-top: 1px solid var(--border_color);
}

.color-swatch {
  margin: 0;

  p {
    margin-bottom: 0.25rem;
  }

  &:hover {
    cursor: pointer;

    .color-swatch-color {
      box-shadow: 0 3px 10px 2px rgba(0, 0, 0, 0.2);
      transform: translate(0, -1px);
      transition:
        transform 0.3s,
        box-shadow 0.3s;
    }
  }
}

.color-swatch-color {
  height: 2rem;
  margin-bottom: 10px;
  border-radius: $border-radius;
  box-shadow: none;
  transition:
    transform 0.3s,
    box-shadow 0.3s;
  border: 1px solid var(--border_color);
}

.color-swatch-name {
  color: #000;
  font-family: var(--font_family_brand_regular);
  font-size: 1rem;
}

.color-swatch-hex {
  @include swatch-detail-border-top;
  color: var(--text);
  font-family: var(--font-family-monospace);
}

.color-swatch-pms {
  @include swatch-detail-border-top;
  margin-top: 0;
  color: var(--text);
  font-family: var(--font-family-monospace);
}

.color-swatch-label {
  @include swatch-detail-border-top;
  font-family: var(--font-family-monospace);
  font-size: var(--font_size_sm);
}

.color-swatch-theme-label {
  @include swatch-detail-border-top;
  font-family: var(--font-family-monospace);
  font-size: 11px;
}

.color-swatch-usage {
  @include swatch-detail-border-top;
  font-family: var(--font-family-sans-serif);
  font-size: var(--font_size_sm);
  line-height: 1.3;
}

.gradients {
  display: grid;
  margin-bottom: 2rem;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

  .card-body {
    padding: 4rem;
  }
}
