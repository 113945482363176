.empty-state {
  margin: 30px auto;
  text-align: center;

  .empty-state-head {
    color: var(--text);
    font-family: var(--font_family_brand_bold);
    font-size: 16px;
    line-height: 26px;
  }

  .empty-state-caption {
    color: var(--text);
    font-family: var(--font-family-sans-serif);
    font-size: 12px;
    line-height: 18px;
  }

  .empty-state-icon {
    width: 64px;
    height: 64px;
    margin: 10px auto;
  }
}
