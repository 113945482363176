.p-contextmenu {
  position: absolute;
  display: none;
  padding: 0;
  background: #ffffff;
  color: var(--text);
  border: 1px solid var(--border_color);
  box-shadow:
    0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
  width: 12.5rem;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .p-submenu-list {
    position: absolute;
    min-width: 100%;
    z-index: 1;
    display: none;
  }
  .p-menuitem-link {
    cursor: pointer;
    display: flex;
    align-items: center;
    text-decoration: none;
    overflow: hidden;
    position: relative;
    padding: 10px 30px 10px 20px;
    margin: 0;
    color: var(--text);
    &:hover {
      background-color: var(--menu_hover);
    }
    .p-submenu-icon {
      margin-left: auto;
    }
    .p-menuitem-icon {
      margin-right: 4px;
    }
  }
  .p-menuitem-text {
    line-height: 1;
  }
  .p-menuitem {
    position: relative;
    margin: 0;
    padding: 0;
  }
  .p-menuitem-active {
    > p-contextmenusub {
      > .p-submenu-list {
        display: block !important;
      }
    }
  }
}

.p-contextmenu .p-menuitem {
  position: relative;
}

.p-contextmenu .p-menuitem-link .p-submenu-icon {
  margin-left: auto;
}

.p-contextmenu .p-menuitem-active > p-contextmenusub > .p-submenu-list.p-submenu-list-active,
.p-contextmenu .p-menuitem {
  display: block !important;
  background-color: #ffffff;
}
