// this sass file needs to be added to DS css

@font-face {
  font-family: 'Dyslexic';
  src: url('../fonts/OpenDyslexic-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'DyslexicBold';
  src: url('../fonts/OpenDyslexic-Bold.woff2') format('woff2');
}

// @font-face {
//   font-family: 'AH';
//   src: url('fonts/AtkinsonHyperlegible-Regular.woff2') format('woff2');
// }

// @font-face {
//   font-family: 'AHB';
//   src: url('fonts/AtkinsonHyperlegible-Bold.woff2') format('woff2');
// }

// @font-face {
//   font-family: 'AHI';
//   src: url('fonts/AtkinsonHyperlegible-Italic.woff2') format('woff2');
// }

// @font-face {
//   font-family: 'AHBI';
//   src: url('fonts/AtkinsonHyperlegible-BoldItalic.woff2') format('woff2');
// }

:root {
  --zoom: 1;
}

main,
[role='main'] {
  zoom: var(--zoom);
}

.a11y-widget-dialog {
  zoom: 1 !important;

  .modal-header {
    margin-bottom: 0.5rem;
    border-bottom: 0;

    .close {
      z-index: 10;
    }
  }

  &.modal-dialog.modal-lg {
    position: absolute;
    top: 60px;
    right: 20px;
    width: 600px;
  }

  .modal-content {
    box-shadow: 3px 3px 9px 0 rgba(0, 0, 0, 0.2);
    background-color: #fff !important;
    padding: 1.5rem;
    border-radius: 12px;
  }

  .check-option {
    display: flex;
    gap: 4px;
    margin-bottom: 8px;

    label {
      margin-bottom: 0;
    }
  }

  .help-text {
    font-size: var(--font_size_sm);
  }

  .options {
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));

    .option {
      padding: 12px;
      background-color: var(--rich100);
      // border: 1px solid var(--rich200);
      height: 100%;
      display: flex;
      flex-direction: column;
      font-size: 14px;
      color: #505877;
    }
  }

  p {
    font-size: 14px;
    color: #505877;
  }

  * {
    line-height: 1.5;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 14px;
    zoom: 1;
    filter: none;
  }

  .font-size {
    display: flex;
    gap: 4px;
    align-items: center;

    label {
      flex: 2;
    }

    select {
      flex: 1;
    }
  }

  .section-title {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin: 0 0 8px;
    padding-bottom: 4px;
    border-bottom: 1px solid var(--border_color);

    h2 {
      margin: 0;
      font-family: 'PrecisionSans_W_Rg', 'Helvetica Neue', Arial, sans-serif;
      font-size: 22px;
      color: #505877;
      border: 0;
    }

    .mat-mdc-slide-toggle {
      font-size: 12px;
      color: #505877;
    }
  }

  .options .mat-mdc-slide-toggle {
    transform: scale(1.5);
    transform-origin: 0 25px;
  }
  .mdc-switch__icons svg {
    display: none;
  }

  // switch handles
  .mdc-switch__icons {
    background-color: white;
    border: 1px solid var(--border_color);
    border-radius: 50%;
  }

  .mdc-switch--checked .mdc-switch__icons {
    background-color: var(--primary);
  }

  section {
    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
  }
}

// end of a11y widget dialog

.p-datatable thead th,
.p-treetable thead th {
  font-size: 0.6875rem;
}

.show-headings {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    border: 1px solid orange;
  }
}

.show-links {
  a {
    border: 1px solid rgb(22, 189, 0);
  }
}

.dyslexia {
  * {
    font-family: 'Dyslexic';
  }
}

.a11y-modal-backdrop.show {
  opacity: 0;

  .bgdark & {
    opacity: 0;
  }
}
