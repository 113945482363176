$slider-thumb-size: 16px;
$slider-track-height: 4px;

.ui-slider {
  position: relative;
  border: 0;
  background: #c7cee6;
  border-radius: 2px;
  text-align: left;
  &.ui-slider-horizontal {
    height: $slider-track-height;
  }

  &.ui-slider-vertical {
    width: $slider-track-height;
    height: 100px;
  }
  .ui-slider-range {
    position: absolute;
    z-index: 1;
    display: block;
    height: $slider-track-height;
    border: 0;
    background: var(--btn_primary);
    background-position: 0 0;
    border-radius: 2px;
    font-size: 0.7em;
  }
  .ui-slider-handle {
    position: absolute;
    z-index: 2;
    top: -6px;
    width: $slider-thumb-size;
    height: $slider-thumb-size;
    border: 1px solid var(--btn_primary);
    background-color: #fff;
    border-radius: 8px;
    cursor: default;
    outline: none;
  }
}
