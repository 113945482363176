// ----- Progress Tracker -----
// ----- Variables -----
// Colours
$progress-color-dark: var(--primary);
$progress-color: var(--primary);
$progress-color-light: var(--text);
$progress-color-grey-light: var(--rich400);
$progress-color-grey: var(--rich600);
$progress-color-grey-dark: var(--rich500);
// Sizing
$progress-tracker-padding: 5px !default;
$marker-size: 24px;
$marker-size-half: 12px;
$marker-size-third: 4px;
$marker-size-quarter: 3px;
$marker-spacing: 10px !default;
$path-height: 6px !default;
// $path-position: $marker-size-half - ($path-height / 2);
$path-position: 9px;
$text-padding: $marker-size-half !default;
$text-padding-X: $marker-size-third !default;
$text-padding-Y: $marker-size-half !default;
$text-padding--vertical: $marker-size + $marker-size-half !default;
// Only needed for short text version, the word size should be the width of the widest word without padding.
$word-size: 54px !default;
// $progress-tracker-word-padding: ($word-size + $text-padding-X + $marker-size-half) / 2;
$progress-tracker-word-padding: 35px;
// Transitions
$transition-duration: 0.3s !default;
// ----- Elements -----
// Container element

.progress-tracker {
  display: flex;
  padding: 0;
  margin: 40px auto;
  list-style: none;
}
// Step container that creates lines between steps
.progress-step {
  position: relative;
  display: block;
  min-width: $marker-size; // For a flexbox bug in firefox that wont allow the text overflow on the text
  flex: 1 1 0;
  padding: 0;
  margin: 0;
  // Stops the last step growing
  &:last-child {
    flex-grow: 0;
  }
  // Path between markers, this is not created for the last step
  &:not(:last-child)::after {
    position: absolute;
    // z-index: -10;
    top: $path-position;
    right: -$marker-size-half;
    bottom: $path-position;
    display: block;
    width: 100%;
    height: $path-height;
    content: '';
    transition: background-color $transition-duration;
  }
}
// Progress marker
.progress-marker {
  position: relative;
  z-index: 3;
  display: flex;
  width: $marker-size;
  height: $marker-size;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
  border-radius: 50%;
  color: #fff;
  font-weight: 400;
  line-height: $marker-size;
  // padding-bottom: 2px; // To align text within the marker
  transition: background-color, border-color;
  transition-duration: $transition-duration;
}
// Progress text
.progress-text {
  display: block;
  overflow: hidden;
  padding: $text-padding-Y $text-padding-X;
  text-overflow: ellipsis;
}

.progress-title {
  margin-top: 0;
}
// Step state mixin - The first arugment is required and the rest are optional, if you pass in null the value will not be changed.
@mixin progress-state(
  $marker-color-bg,
  $marker-color-border: null,
  $marker-color-text: null,
  $path-color: null,
  $text-color: null
) {
  .progress-marker {
    border-color: $marker-color-border;
    background-color: $marker-color-bg;
    color: $marker-color-text;
  }

  &::after {
    background-color: $path-color;
  }

  .progress-step > a .progress-text,
  .progress-text {
    color: #717171;
  }
}
// States
.progress-step {
  // Inactive - Default state
  @include progress-state(
    $progress-color-grey-light,
    null,
    #fff,
    $progress-color-grey-light,
    $progress-color-grey-dark
  );
  // Active state
  &.is-active {
    @include progress-state($progress-color);
  }
  // Complete state
  &.is-complete {
    @include progress-state($progress-color-dark, $path-color: $progress-color-dark);
  }
  // Hover state
  &:hover {
    @include progress-state($progress-color-dark);
  }
}
// DS 3.0 modification
.progress-step {
  h4 {
    color: $progress-color-grey-light;
  }

  &:hover {
    h4 {
      color: $progress-color-dark;
    }
  }

  &.is-active {
    .progress-marker {
      border: 3px solid $progress-color;
      background-color: #fff;
      color: var(--text);
    }

    h4 {
      color: $progress-color;
    }
  }

  &.is-complete {
    h4 {
      color: $progress-color-light;
    }
  }
}
// ----- Modifiers -----
// Center align markers and text
.progress-tracker--center {
  .progress-step {
    text-align: center;

    &:last-child {
      flex-grow: 1;
    }

    &::after {
      right: -50%;
    }
  }

  .progress-marker {
    margin-right: auto;
    margin-left: auto;
  }
}
// Right align markers and text
.progress-tracker--right {
  .progress-step {
    text-align: right;

    &:last-child {
      flex-grow: 1;
    }

    &::after {
      right: calc(-100% + #{$marker-size-half});
    }
  }

  .progress-marker {
    margin-left: auto;
  }
}
// Word below markers
.progress-tracker--word {
  overflow: hidden;
  padding-right: $progress-tracker-word-padding;

  .progress-text {
    display: inline-block;
    white-space: nowrap;
  }
}

.progress-tracker--word-center {
  padding-right: $progress-tracker-word-padding;
  padding-left: $progress-tracker-word-padding;

  .progress-text {
    padding-right: 0;
    padding-left: 0;
    transform: translateX(calc(-50% + #{$marker-size-half}));
  }
}
// Border around steps (Only for use without text)
.progress-tracker--border {
  padding: $progress-tracker-padding;
  border: 2px solid $progress-color-grey;
  border-radius: $marker-size + ($progress-tracker-padding * 2);
}
// Spaces between markers
.progress-tracker--spaced {
  .progress-step {
    &::after {
      width: calc(100% - #{$marker-size + ($marker-spacing * 2)});
      margin-right: ($marker-size-half + $marker-spacing);
    }
  }
}

.progress-tracker--word-right {
  padding-right: 0;
  padding-left: $progress-tracker-word-padding;

  .progress-text {
    padding-left: 0;
    transform: translateX(calc(-100% + #{$marker-size}));
  }
}
// Text below markers
.progress-tracker--text {
  .progress-step {
    &:last-child {
      flex-grow: 1;
    }
  }
}
// Text above markers
.progress-tracker--text-top {
  .progress-step::after {
    top: auto;
  }

  .progress-text {
    height: 100%;
  }

  .progress-marker {
    bottom: $marker-size;
  }
}
// Text inline with markers
.progress-tracker--text-inline {
  .progress-step {
    display: flex;
  }

  .progress-text {
    position: relative;
    z-index: 30;
    max-width: 70%;
    padding-top: 0;
    padding-bottom: 0;
    background-color: #fff;
    white-space: nowrap;
  }

  .progress-title {
    margin: 0;
  }
}
// Square markers
.progress-tracker--square {
  .progress-step {
    padding-top: 0;
  }

  .progress-marker {
    border-radius: 0;
    transform: scaleX(0.33) translateY(-$path-position);
  }
}
// Vertical layout
.progress-tracker--vertical {
  flex-direction: column;

  .progress-step {
    flex: 1 1 auto;

    &::after {
      top: $marker-size-half;
      right: auto;
      left: $path-position;
      width: $path-height;
      height: 100%;
    }
  }

  .progress-marker {
    position: absolute;
    left: 0;
  }

  .progress-text {
    padding-top: $marker-size-quarter;
    padding-left: $text-padding--vertical;
  }

  .progress-step:not(:last-child) .progress-text {
    padding-bottom: $text-padding--vertical;
  }
}
