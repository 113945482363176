.p-paginator {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  .p-dropdown {
    background-color: #fff;
    margin-left: 8px;

    .bgwhite & {
      background-color: #fff;
    }

    .bgdark & {
      background-color: var(--dark_input_bg);
      color: var(--dark_fg);
    }

    .p-dropdown-label {
      height: 23px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      border: 0;
    }

    .p-dropdown-trigger {
      top: 0px !important;
      background-color: #fff;
    }
  }
}

.p-dropdown-trigger-icon.pi.pi-chevron-down {
  font-family: 'pbi-icon-mini';

  &::before {
    content: '\ea33';
  }
}

.p-paginator-left-content {
  margin-right: auto;
}

.p-paginator-right-content {
  margin-left: auto;
}

.p-paginator-page,
.p-paginator-next,
.p-paginator-last,
.p-paginator-first,
.p-paginator-prev {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  user-select: none;
  overflow: hidden;
  position: relative;
  padding: 0.25rem 0.5rem;
  margin-right: 0.063em;
  margin-left: 0.063em;
  border: 1px solid var(--border_color);
  color: var(--btn_secondary_label);
  border-radius: $border-radius;

  &.p-highlight {
    border-color: var(--btn_primary);
    background: var(--btn_primary);
    color: #fff;
  }
}

.p-paginator-current {
  margin-right: 0.75rem;
}

.p-paginator-pages {
  padding: 0.25rem 0.5rem;
}

.p-paginator-element:focus {
  z-index: 1;
  position: relative;
}

.p-paginator-bottom {
  margin-top: 0.5rem;
}
