@mixin switch-size($width, $height, $font-size, $handle-margin) {
  width: $width;
  height: $height;

  .switch-label {
    font-size: $font-size;
  }

  .switch-handle {
    width: $height - $handle-margin * 2;
    height: $height - $handle-margin * 2;
  }

  .switch-input:checked ~ .switch-handle {
    left: $width - $height + $handle-margin;
  }
}

@mixin switch($type, $width, $height, $font-size, $handle-margin) {
  position: relative;
  display: inline-block;
  width: $width;
  height: $height;
  background-color: transparent;
  cursor: pointer;
  vertical-align: top;

  .switch-input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }

  .switch-label {
    position: relative;
    display: block;
    height: inherit;
    border: 1px solid var(--border_color);
    border-radius: 2px;
    font-size: $font-size;
    font-weight: normal;
    text-transform: uppercase;
    transition: opacity background 0.15s ease-out;

    @if $type ==icon {
      font-family: FontAwesome;
    }

    @if $type ==ddd {
      background-color: var(--border_color);
    } @else {
      background-color: var(--border_color);
    }
  }

  @if $type ==text or $type ==icon {
    .switch-label::before,
    .switch-label::after {
      position: absolute;
      top: 50%;
      width: 50%;
      margin-top: -0.5em;
      line-height: 1;
      text-align: center;
      transition: inherit;
    }

    .switch-label::before {
      right: 4px;
      color: var(--text);
      content: attr(data-off);
    }

    .switch-label::after {
      left: 4px;
      color: #fff;
      content: attr(data-on);
      opacity: 0;
    }

    .switch-state-outside::before {
      right: -52px;
      color: var(--text);
      content: attr(data-off);
      text-align: left;
    }

    .switch-state-outside::after {
      left: 91px;
      color: var(--text);
      content: attr(data-on);
      opacity: 0;
      text-align: left;
    }
  }

  .switch-input:checked ~ .switch-label::before {
    opacity: 0;
  }

  .switch-input:checked ~ .switch-label::after {
    opacity: 1;
  }

  .switch-handle {
    position: absolute;
    top: $handle-margin;
    left: $handle-margin;
    width: $height - $handle-margin * 2;
    height: $height - $handle-margin * 2;
    border: 1px solid var(--border_color);
    background: #fff;
    border-radius: 1px;
    transition: left 0.15s ease-out;

    @if $type ==ddd {
      border: 0;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    }
  }

  .switch-input:checked ~ .switch-handle {
    left: $width - $height + $handle-margin;
  }

  @if $type ==ddd {
    @extend .switch-pill;
  }

  //size variations
  @if $type ==default {
    &.switch-lg {
      @include switch-size($switch-lg-width, $switch-lg-height, $switch-lg-font-size, $handle-margin);
    }

    &.switch-sm {
      @include switch-size($switch-sm-width, $switch-sm-height, $switch-sm-font-size, $handle-margin);
    }

    &.switch-xs {
      @include switch-size($switch-xs-width, $switch-xs-height, $switch-xs-font-size, $handle-margin);
    }
  } @else if $type ==text {
    &.switch-lg {
      @include switch-size($switch-text-lg-width, $switch-text-lg-height, $switch-text-lg-font-size, $handle-margin);
    }

    &.switch-sm {
      @include switch-size($switch-text-sm-width, $switch-text-sm-height, $switch-text-sm-font-size, $handle-margin);
    }

    &.switch-xs {
      @include switch-size($switch-text-xs-width, $switch-text-xs-height, $switch-text-xs-font-size, $handle-margin);
    }
  } @else if $type ==icon {
    &.switch-lg {
      @include switch-size($switch-icon-lg-width, $switch-icon-lg-height, $switch-icon-lg-font-size, $handle-margin);
    }

    &.switch-sm {
      @include switch-size($switch-icon-sm-width, $switch-icon-sm-height, $switch-icon-sm-font-size, $handle-margin);
    }

    &.switch-xs {
      @include switch-size($switch-icon-xs-width, $switch-icon-xs-height, $switch-icon-xs-font-size, $handle-margin);
    }
  } @else if $type ==ddd {
    &.switch-lg {
      @include switch-size($switch-lg-width, $switch-lg-height, $switch-lg-font-size, 0);
    }

    &.switch-sm {
      @include switch-size($switch-sm-width, $switch-sm-height, $switch-sm-font-size, 0);
    }

    &.switch-xs {
      @include switch-size($switch-xs-width, $switch-xs-height, $switch-xs-font-size, 0);
    }
  }

  &.switch-disabled {
    cursor: not-allowed;

    .switch-label {
      @include disabled;

      &::before {
        color: #c0c0c0;
        -webkit-text-fill-color: #c0c0c0;
      }
    }

    .switch-handle {
      .bgdark & {
        background-color: #c0c0c0;
      }
    }
  }
}

@mixin switch-variant($color) {
  > .switch-input:checked ~ .switch-label {
    background: $color !important;
    // border-color: darken($color, 10%);
    border-color: var(--border_color);
  }

  > .switch-input:checked ~ .switch-handle {
    // border-color: darken($color, 10%);
    border-color: var(--border_color);
  }
}

@mixin switch-outline-variant($color) {
  > .switch-input:checked ~ .switch-label {
    background: #fff !important;
    border-color: $color;

    &::after {
      color: $color;
    }
  }

  > .switch-input:checked ~ .switch-handle {
    border-color: $color;
  }
}

@mixin switch-outline-alt-variant($color) {
  > .switch-input:checked ~ .switch-label {
    background: #fff !important;
    border-color: $color;

    &::after {
      color: $color;
    }
  }

  > .switch-input:checked ~ .switch-handle {
    background: $color !important;
    border-color: $color;
  }
}

$switch-xs-width: 1.5rem;
$switch-xs-height: 1rem;
$switch-xs-font-size: 0.5rem;
$switch-text-xs-width: 2rem;
$switch-text-xs-height: 1rem;
$switch-icon-xs-width: 2rem;
$switch-icon-xs-height: 1rem;
$switch-text-xs-font-size: 0.5rem;
$switch-icon-xs-font-size: 0.5rem;

$switch-sm-width: 4rem;
$switch-sm-height: 1.5rem;
$switch-sm-font-size: var(--font_size_xs);
$switch-text-sm-width: 4rem;
$switch-text-sm-height: 1.5rem;
$switch-text-sm-font-size: var(--font_size_xs);
$switch-icon-sm-width: 4rem;
$switch-icon-sm-height: 1.5rem;
$switch-icon-sm-font-size: var(--font_size_xs);

$switch-width: 5rem;
$switch-height: 2rem;
$switch-font-size: var(--font_size_sm);
$switch-text-width: 5rem;
$switch-text-height: 2rem;
$switch-text-font-size: var(--font_size_sm);
$switch-icon-font-size: var(--font_size_sm);
$switch-icon-width: 3rem;
$switch-icon-height: 1.5rem;

$switch-lg-width: 3rem;
$switch-lg-height: 1.75rem;
$switch-text-lg-width: 6rem;
$switch-text-lg-height: 2.25rem;
$switch-lg-font-size: var(--font_size_base);
$switch-text-lg-font-size: var(--font_size_base);
$switch-icon-lg-width: 3.5rem;
$switch-icon-lg-height: 1.5rem;
$switch-icon-lg-font-size: var(--font_size_base);

$handle-margin: 0;

.switch.switch-default {
  @include switch('default', $switch-width, $switch-height, $switch-font-size, $handle-margin);
}

.switch.switch-text {
  @include switch('text', $switch-text-width, $switch-text-height, $switch-text-font-size, $handle-margin);
}

.switch.switch-icon {
  @include switch('icon', $switch-icon-width, $switch-icon-height, $switch-icon-font-size, $handle-margin);
}

.switch.switch-3d {
  @include switch('ddd', $switch-width, $switch-height, $switch-font-size, 0);
}

//pills style
.switch-pill {
  .switch-label,
  .switch-handle {
    border-radius: 50em !important;
  }

  .switch-label::before {
    right: 2px !important;
  }

  .switch-label::after {
    left: 2px !important;
  }
}

/* stylelint-disable */
@each $variant,
  $color
    in (
      primary: var(--btn_primary),
      secondary: var(--rich100),
      success: var(--success),
      info: var(--info),
      warning: var(--warning),
      danger: var(--danger)
    )
{
  //normal style
  .switch-#{$variant} {
    @include switch-variant($color);
  }

  //outline style
  .switch-#{$variant}-outline {
    @include switch-outline-variant($color);
  }

  //outline alternative style
  .switch-#{$variant}-outline-alt {
    @include switch-outline-alt-variant($color);
  }
}

/* stylelint-enable */

.form-group-switch {
  display: flex;
  align-items: center;

  .control-label {
    margin-right: 0.5rem;
  }

  input:focus {
    ~ .switch-label {
      // box-shadow: 0 0 10px HSLA(195, 100%, 65%, 0.77);
      @include focused;
    }
  }
}
