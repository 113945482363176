.p-inputtext {
  margin: 0;
}

.p-fluid .p-inputtext {
  width: 100%;
}

/* InputGroup */
.p-inputgroup {
  display: flex;
  align-items: stretch;
  width: 100%;
}

.p-inputgroup-addon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-inputgroup .p-float-label {
  display: flex;
  align-items: stretch;
  width: 100%;
}

.p-inputgroup .p-inputtext,
.p-fluid .p-inputgroup .p-inputtext {
  flex: 1 1 auto;
  width: 1%;
}

p-inputnumber {
  &.p-inputwrapper {
    display: block;

    .p-inputnumber {
      &.p-component {
        display: block;
      }
    }
  }
  &.p-inputwrapper-focus {
    @include focused;
  }
  .p-inputnumber-input:focus-visible {
    outline: none;
  }
}

p-inputmask {
  display: block;

  .p-inputtext {
    &.p-component {
      display: block;
    }
  }
}
