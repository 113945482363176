// global color for ripples
.mat-mdc-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  // background-color: hsla(227, 54%, 80%, 0.6);
  background-color: rgba(66, 134, 244, 0.6);
}

// global form field label color
.mdc-form-field label {
  color: var(--text) !important;
}

.mat-mdc-checkbox.mat-checkbox-checked.cdk-keyboard-focused .mat-checkbox-persistent-ripple,
.mat-checkbox.cdk-keyboard-focused .mat-checkbox-persistent-ripple {
  background-color: rgb(66, 134, 244);
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control[data-indeterminate='true']:enabled
  ~ .mdc-checkbox__background {
  border-color: var(--btn_primary) !important;
  background-color: var(--btn_primary) !important;

  .bgdark &,
  .bgdark .signin-box & {
    background-color: var(--dark_input_bg);
  }
}

.mdc-checkbox__background {
  border-width: 1px !important;
}
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:not([disabled]):focus ~ .mdc-checkbox__ripple {
  opacity: 1 !important;
}
.mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: transparent;
  border-radius: var(--border_radius);
  @include focused;
  outline-offset: 0px;
}
.mat-mdc-checkbox-ripple,
.mdc-checkbox__ripple {
  top: 9px !important;
  left: 9px !important;
  right: 6px !important;
  bottom: 6px !important;
  position: absolute !important;
  pointer-events: none !important;
}
.mat-mdc-checkbox .mdc-checkbox__ripple {
  background: transparent !important;
  border-radius: var(--border_radius);

  @include focused;
  outline-offset: 0px;
}
// labels
.mat-mdc-checkbox label,
.mat-mdc-checkbox .mdc-form-field {
  font-size: var(--font_size_base) !important;
  font-family: var(--font_family_base) !important;
  color: var(--text) !important;
  letter-spacing: normal !important;
  padding-left: 0px !important;
}

.mat-mdc-checkbox {
  .mdc-checkbox {
    padding: calc((var(--mdc-checkbox-state-layer-size, 40px) - 21px) / 2) !important;
  }

  .mdc-checkbox__background {
    top: calc((var(--mdc-checkbox-state-layer-size) - 18px) / 2) !important;
    left: calc((var(--mdc-checkbox-state-layer-size) - 18px) / 2) !important;
  }
}

.bgwhite,
.bg-white,
.signin-box {
  .mat-mdc-checkbox
    .mdc-checkbox
    .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate='true'])
    ~ .mdc-checkbox__background {
    border-color: var(--btn_primary) !important;
    background-color: transparent;
  }
}

.bgdark,
.bg-dark .signin-box {
  .mat-mdc-checkbox
    .mdc-checkbox
    .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate='true'])
    ~ .mdc-checkbox__background {
    border-color: var(--dark_input_border) !important;
    background-color: var(--dark_input_bg) !important;
  }
}

// these are used in selection lists
.mat-pseudo-checkbox-checked {
  background-color: transparent;

  &::after {
    top: 3px !important;
    left: 2px !important;
    width: 10px !important;
    height: 5px !important;
    border-bottom: 2px solid var(--btn_primary) !important;
    border-left: 2px solid var(--btn_primary) !important;
  }
}

//disabled
.mdc-checkbox--disabled {
  .mdc-checkbox__background {
    border-color: #ddd !important;
    background-color: rgba(192, 192, 192, 0.25);

    .bgdark & {
      border-color: var(--dark_input_border) !important;
      background-color: #45494d !important;
    }
  }

  .mdc-form-field label {
    color: var(--disabled) !important;

    .bg-dark & {
      color: var(--dark_input_disabled_bg) !important;
    }
  }
}
