body {
  color: var(--text);
  font-family: var(--font_family_sans_serif);
  font-size: var(--font_size_base);
  line-height: var(--line_height_base);
}

// easily change something to serif or sans-serif
.serif,
.serif p {
  font-family: var(--font-family-serif);
}

.sans-serif,
.sans,
.sans-serif p,
.sans p {
  font-family: var(--font_family_sans_serif);
}

.text-small {
  font-size: var(--font_size_sm);
}

.text-large,
.lede {
  font-size: var(--font_size_lg);
}

// assign line height to running copy so it can be increased or decreased
p {
  line-height: var(--line_height);
}

// Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
code,
.code,
pre {
  font-family: var(--font-family-monospace);
}

.nowrap {
  white-space: nowrap;
}

// HEADERS
.display {
  font-family: var(--font_family_brand_light);
  font-kerning: normal;
  font-size: 3rem;
  letter-spacing: -0.02em;
}

// common settings for all headers
h1,
h2,
h3,
h4,
h5,
h6,
.display1,
.display2 {
  margin-top: 0; // remove BS default
  margin-bottom: 0.125rem;
  font-weight: normal;
  line-height: var(--line_height_base); // all headers line height
}

h2,
h3,
h4,
h5,
h6 {
  color: var(--text);
}

h1,
.h1 {
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: var(--h1_color);
  font-family: var(--font_family_brand_light);
  font-size: var(--h1_font_size);

  .pbi-icon-outline {
    display: inline-block;
    transform: translateY(2px);
  }
}

h2,
.h2 {
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-family: var(--font_family_brand_regular);
  font-size: var(--h2_font_size);
}

h1 + h2,
h2 + h3,
h3 + h4 {
  margin-top: 0;
}

h3,
.h3 {
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-family: var(--font_family_brand_light);
  font-size: var(--h3_font_size);
}

h4,
.h4 {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-family: var(--font_family_brand_regular);
  font-size: var(--h4_font_size);
}

h5,
.h5,
h6,
.h6 {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-family: var(--font_family_brand_regular);
  font-size: var(--font_size_base);
  font-weight: normal;
}

.subheader,
.subhead {
  color: var(--text);
  font-family: var(--font_family_base);
  font-size: var(--font_size_base);
  font-weight: normal;
}

// LISTS

ul,
ol {
  padding-left: 1.5em;
  margin-top: 0;
  margin-bottom: 1rem;
  margin-left: 0;

  li {
    margin-bottom: 0.25rem;
  }
}

ol.lower-roman {
  list-style-type: lower-roman;
}

.navbar-nav {
  li {
    margin-bottom: 0;
  }
}

.dropdown-menu {
  li {
    margin-bottom: 0;
  }
}

dl {
  margin-bottom: 1rem;

  dt {
    margin-top: 0.5rem;
    margin-bottom: 0.25rem;

    &:first-child {
      margin-top: 0;
    }
  }

  dd {
    margin-bottom: 0.5rem;
  }
}

// condensed definition list
.dlist-condensed {
  line-height: 1.25;

  dt {
    margin-top: 0.25rem;
    margin-bottom: 0.125rem;

    &:first-child {
      margin-top: 0;
    }
  }

  dd {
    margin-bottom: 0.25rem;
  }
}

blockquote {
  font-size: 1rem;
}

.text-success {
  color: var(--green700) !important;
}
.text-gray-500 {
  color: var(--text) !important;
}

a {
  color: var(--hyperlink);
  text-decoration: none; //NEW: BS5 default is underline

  &:hover {
    text-decoration: underline;
  }

  &:focus-visible {
    @include focused;
  }
}

.nav-tabs > li > a,
.nav-pills > li > a {
  font-family: var(--font_family_brand_regular);
}

.help-block {
  color: var(--text_help);
}

// add support for size multipliers in pb font
$pb-css-prefix: pbfont !default;

.#{$pb-css-prefix}.lg {
  font-size: 1.5rem;
  vertical-align: -16%;
}

.#{$pb-css-prefix}.x2 {
  font-size: 2rem;
}

.#{$pb-css-prefix}.x3 {
  font-size: 3rem;
}

.#{$pb-css-prefix}.x4 {
  font-size: 4rem;
}

.#{$pb-css-prefix}.x5 {
  font-size: 5rem;
}

.#{$pb-css-prefix}.x6 {
  font-size: 6rem;
}

.font-h3 {
  font-family: var(--font_family_brand_light);
  font-size: var(--h3_font_size);
}
