.weather-alert.pbds-chart.pbds-chart-choropleth-map {
  background-color: #4e4e4e;

  .mesh {
    display: none;
  }

  .feature {
    fill: #717171 !important;
    stroke: #717171 !important;
  }

  .hasData {
    fill: #9b9b9b !important;
    stroke: #9b9b9b !important;
  }
}

.table-charting tbody tr td:not(:first-child) {
  width: 90px;
  text-align: center;
}

.table-charting td {
  vertical-align: middle;

  i.pbi-close {
    color: var(--danger);
  }

  i.pbi-check {
    color: var(--success);
  }
}

.table-charting thead th {
  text-align: center;
  white-space: nowrap;
}

h5.chart-filter-title {
  font-family: var(--font-family-sans-serif);
  font-weight: bold;
  em {
    font-weight: normal;
  }
}

// hide the Beacon on dashboard demos for screenshots
.dashboard {
  #beacon-container {
    display: none !important;
  }
}
