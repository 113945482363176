.hamburger {
  &.hamburger-auto {
    @include media-breakpoint-down(md) {
      .fixed-top {
        left: 0;
      }
      &.hamburger-opened .navbar-toggle {
        display: block;
      }
    }
  }

  .nav.left-nav {
    margin-top: 7rem;
  }

  .sidebar-search {
    margin: 1rem;
  }
  // in the header
  .sidenav-toggler {
    font-size: 18px;
    color: white;
  }

  .mat-drawer {
    background-color: var(--rich100);
    width: 250px;
  }
  .mat-drawer-content.mat-sidenav-content {
    padding-bottom: 100px;
  }
}

.close-burger {
  width: 21px;
  height: 21px;
  border: 0;
  background: url('data:image/svg+xml,%3Csvg%20width%3D%2217%22%20height%3D%2217%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cg%20stroke%3D%22%233E53A4%22%20stroke-width%3D%222%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Cpath%20d%3D%22M15.218%201.782%201.075%2015.925M1.075%201.782l14.143%2014.143%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E')
    center center no-repeat;
  color: var(--text);
  cursor: pointer;
  @media (forced-colors: active) {
    background: url('data:image/svg+xml,%3Csvg%20width%3D%2217%22%20height%3D%2217%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cg%20stroke%3D%22%23fff%22%20stroke-width%3D%222%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Cpath%20d%3D%22M15.218%201.782%201.075%2015.925M1.075%201.782l14.143%2014.143%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E');
  }
}
.example-sidenav {
  width: 250px;
  background-color: var(--rich100);
}
