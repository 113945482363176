#cd-main-nav {
  padding-left: 0;
  margin-top: 1rem;
  margin-left: 0;
  column-count: 5;
  list-style: none;

  @include media-breakpoint-only(xs) {
    column-count: 2;

    li {
      margin-bottom: 0.5rem;
    }
  }
}

.icon-grid {
  display: grid;
  margin: 1rem 0;
  grid-auto-rows: 60px;
  grid-auto-rows: minmax(60px, auto);
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));

  list-style: none;

  .icon-swatch-wrapper {
    display: flex;
    height: 100%;
    align-items: stretch;

    border: 1px solid var(--border_color);
    border-radius: $border-radius;
    font-size: var(--font_size_sm);

    &-left {
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid var(--border_color);
      background-color: #fff;
      border-bottom-right-radius: $border-radius;
      border-top-left-radius: $border-radius;
    }

    &-right {
      display: flex;
      flex-grow: 1;
      align-items: center;
      padding: 0.5rem;
      background-color: #fff;

      border-bottom-right-radius: $border-radius;
      border-top-right-radius: $border-radius;

      &:hover {
        box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.3);
        cursor: pointer;
        transform: translate(0, -1px);
        transition: transform 0.3s, box-shadow 0.3s;
      }
    }

    .bgdark & {

      &-left,
      &-right {
        background-color: var(--dark_input_bg);
      }
    }

    .icon {
      padding: 0 0.5rem;
      font-size: 1.5rem;
    }

    .icon-name {
      font-size: 0.8rem;
    }

    .icon-code {
      font-family: var(--font-family-monospace);
      font-size: 10px;
      letter-spacing: -0.05em;
      line-height: 1.2;
    }
  }
}