.mdc-tab,
.mdc-tab__text-label,
.mdc-tab__content {
  font-size: var(--font_size_base) !important;
  font-family: var(--font_family_brand_regular) !important;
  color: var(--text) !important;
  letter-spacing: normal !important;

  .mdc-tab--active & {
    color: var(--hyperlink) !important;
  }
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--hyperlink) !important;
}

.mat-mdc-tab-body-content {
  color: var(--text) !important;
  font-size: var(--font_size_base) !important;
  font-family: var(--font_family_base) !important;
  padding: 1rem;
}

.mat-mdc-tab-header,
.mat-mdc-tab-nav-bar {
  border-bottom: 1px solid var(--divider_color);
}

// tab focus
.mat-mdc-tab-header {
  overflow: visible !important;

  .mat-mdc-tab-label-container {
    overflow: visible !important;

    .mat-mdc-tab-list {
      .mat-mdc-tab-labels {
        .mdc-tab {
          &.cdk-focused {
            &:focus-within {
              @include focused;
            }
          }
        }
      }
    }
  }
  .mat-mdc-tab.cdk-keyboard-focused .mdc-tab__ripple::before {
    opacity: 0;
  }
}
