$metric-block-increase: #007d00;
$metric-block-increase-background: #e5f6eb;
// $metric-block-decrease: #d30c0c;
$metric-block-decrease: var(--danger);
$metric-block-decrease-background: #ffe6e6;

.metric-block-indicator {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  font-family: var(--font-family-sans-serif);
  font-size: 12px;
  min-width: 52px;
  color: #717171;

  &::before {
    display: inline-block;
    margin-right: 4px;
    font-family: 'pbi-icon-mini' !important;
    font-size: 1.25em;
    color: inherit;
    content: '\e911';
  }

  &.increase {
    color: $metric-block-increase;

    &::before {
      content: '\ea0c';
      color: inherit;
    }

    &.inverse {
      color: $metric-block-decrease;
    }
  }

  &.decrease {
    color: $metric-block-decrease;

    &::before {
      content: '\ea33';
      width: auto;
      color: inherit;
    }

    &.inverse {
      color: $metric-block-increase;
    }
  }
}

.metric-block {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  height: 100%;

  &.metric-block-centered {
    justify-content: center;
  }

  &.metric-block-centered-text {
    .metric-block-inner {
      align-items: center;
    }

    .metric-block-data {
      text-align: center;
    }
  }

  .metric-block-inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .metric-block-heading {
    line-height: 1.15;
    margin-bottom: 8px;
    font-size: 14px;
    font-family: var(--font_family_brand_regular);
  }

  .metric-block-value {
    display: inline-block;
    font-family: var(--font_family_brand_regular);
    font-size: 32px;
    font-weight: normal;
    letter-spacing: -0.03em;
    line-height: 1;
    white-space: nowrap;
  }

  .metric-block-data {
    text-align: left;
  }

  .metric-block-data-block {
    display: flex;
    align-items: flex-start;
  }

  .pbds-chart {
    margin-left: 24px;
    // display: flex;
    min-width: 40px;

    +* {
      text-align: left;
    }
  }

  &.metric-block-vertical {
    .metric-block-data-block {
      flex-direction: column;
    }

    .pbds-chart {
      margin-left: 0;
    }
  }

  .metric-block-unit {
    font-size: 1rem;
    letter-spacing: normal;
    margin-left: 4px;
  }

  .metric-block-percentage {
    font-size: 0.55em;
    letter-spacing: normal;
    margin-left: 2px;
    vertical-align: super;
  }

  .metric-block-description {
    line-height: 1;
    margin-top: 4px;

    font-size: 12px;
  }

  .metric-block-contents {
    text-align: left;
  }
}

.row-divider {
  position: relative;
}

.row-divider-sm>[class*='col-']:nth-child(n + 2)::after {
  content: '';
  background-color: var(--border_color);
  position: absolute;
  top: 0;
  bottom: 0;

  @include media-breakpoint-down(sm) {
    width: 100%;
    height: 1px;
    left: 0;
    top: -4px; // quickfix to center line between columns
  }

  @include media-breakpoint-up(sm) {
    width: 1px;
    height: auto;
    left: -1px; // quickfix to hide divider on left side
  }
}

.row-divider-md>[class*='col-']:nth-child(n + 2)::after {
  content: '';
  background-color: var(--border_color);
  position: absolute;
  top: 0;
  bottom: 0;

  @include media-breakpoint-down(md) {
    width: 100%;
    height: 1px;
    left: 0;
    top: -4px; // quickfix to center line between columns
  }

  @include media-breakpoint-up(md) {
    width: 1px;
    height: auto;
    left: -1px; // quickfix to hide divider on left side
  }
}

.row-divider-lg>[class*='col-']:nth-child(n + 2)::after {
  content: '';
  background-color: var(--border_color);
  position: absolute;
  top: 0;
  bottom: 0;

  @include media-breakpoint-down(lg) {
    width: 100%;
    height: 1px;
    left: 0;
    top: -4px; // quickfix to center line between columns
  }

  @include media-breakpoint-up(lg) {
    width: 1px;
    height: auto;
    left: -1px; // quickfix to hide divider on left side
  }
}