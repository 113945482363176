.list-group-item {
  // TODO should they be transparent or white/richgrey?
  background-color: transparent;
  color: var(--text);
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  text-decoration: none;
  border: 1px solid var(--border_color);
  margin: 0;
}

.mat-mdc-selection-list {
  /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
  .mat-list-text {
    color: var(--text);
  }

  .mat-pseudo-checkbox {
    border-color: var(--primary);
    background-color: var(--white);

    .bgwhite & {
      background-color: var(--bg_rich_color);
    }
  }

  .mat-pseudo-checkbox-checked {
    background-color: var(--primary);

    &::after {
      border-bottom: 2px solid var(--white) !important;
      border-left: 2px solid var(--white) !important;
    }
  }
}

.mat-mdc-list-base .mat-mdc-list-item,
.mat-list-base .mat-list-option {
  height: 2rem !important;
  font-size: var(--font_size_base) !important;
}
.list-unstyled-spaced li {
  padding-bottom: 1rem;
}
.list-unstyled-spaced li {
  padding-bottom: 1rem;
}
