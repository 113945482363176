.mat-expansion-panel-header {
  font-family: var(--font_family_brand_regular);
  .mat-expansion-panel-header-title {
    color: var(--text);
  }
}

.mat-expansion-panel-content {
  font-family: var(--font-family-sans-serif);
  color: var(--text);
}
