.doc-hero-gradient {
  background-image: linear-gradient(90deg, var(--grad_start) 0%, var(--grad_mid) 50%, var(--grad_end) 100%);
  height: 45px;
}

.doc-hero-header {
  background-color: #fff;
  padding-left: 1.5rem;

  .bgdark & {
    background-color: var(--dark_bg_accent);
  }

  .theme-menus {
    select {
      background-color: var(--rich100);

      .bgdark & {
        background-color: var(--dark_input_bg);
      }
    }
  }

  .bgwhite & {
    background-color: var(--rich100);

    select {
      background-color: var(--white);
    }
  }
}
