.p-knob-range {
  // stroke: var(--rich300);
  cursor: grab;
}

.p-knob-value {
  // stroke: var(--primary);
  cursor: grab;
}

.p-knob-text {
  font-size: var(--font_size_base);
  fill: var(--text);
}