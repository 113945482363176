@mixin outline-shadow {
  border: 1px solid var(--primary) !important;
  box-shadow: var(--focus_shadow) var(--focus);
}

@mixin data-widget($bgcolor: var(--primary), $color: var(--white)) {
  &.data-widget {
    background-color: $bgcolor;

    .data-widget-icon,
    .data-widget-data {
      color: $color;
    }
  }
}

@mixin focused {
  outline: 1.5px solid var(--focus_border_color);
  outline-offset: 1px;
  border-radius: var(--border_radius);
  box-shadow: none;
}

@mixin disabled {
  border-color: #ddd;
  background-color: #e1e1e1;
  opacity: 1;
  pointer-events: none;
  cursor: not-allowed;
  color: #c0c0c0;
  -webkit-text-fill-color: #c0c0c0;

  .bgdark & {
    background-color: var(--dark_input_disabled_bg);
    border-color: var(--dark_input_border);
    color: var(--dark_input_disabled_text);
    -webkit-text-fill-color: var(--dark_input_disabled_text);
  }
}

@mixin table-headers {
  color: var(--text);
  font-family: var(--font_family_base);
  background-color: transparent;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 11px;
  letter-spacing: 0.08em;
}

@mixin gradient($color-start, $color-center, $color-end) {
  background-image: linear-gradient(90deg, $color-start 0%, $color-center 50%, $color-end 100%);
}

@mixin gradient-angled($color-start, $color-center, $color-end) {
  background-image: linear-gradient(135deg, $color-start 0%, $color-center 50%, $color-end 100%);
}

//see https://codepen.io/smlombardi/full/mddVGNq
@mixin gradient-over-image($image) {
  background-image: linear-gradient(135deg, var(--grad_start) 0%, var(--grad_end) 50%, var(--grad_end) 100%),
    linear-gradient(#bbb, #bbb), url($image);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: multiply;
}

// Contextual backgrounds

@mixin bg-variant($parent, $color) {
  #{$parent} {
    background-color: $color !important;
  }

  a#{$parent} {
    @include hover-focus {
      background-color: darken($color, 10%) !important;
    }
  }
}

@mixin bg-gradient-variant($parent, $leftcolor, $rightcolor) {
  #{$parent} {
    background: $leftcolor linear-gradient(180deg, mix($body-bg, $leftcolor, 15%), $leftcolor) repeat-x !important;
  }
}

@mixin arrow-inside($color: #fff, $left: 65px) {
  &::after {
    position: absolute;
    bottom: -13px;
    left: 4rem;
    border-width: 14px 14px 0;
    border-style: solid;
    border-color: $color transparent;
    content: '';
  }
}

@mixin arrow-outside($color: var(--primary), $left: 65px) {
  &::before {
    position: absolute;
    bottom: -15px;
    left: 4rem;
    border-width: 14px 14px 0;
    border-style: solid;
    border-color: $color transparent;
    content: '';
  }
}

@mixin focus-ring {
  border-color: var(--focus);
  box-shadow: 0 0 10px HSLA(195, 100%, 65%, 0.77);
  // outline-width: 6px;
  // outline-style: solid;
  // outline-color: hsla(190, 58%, 79%, .6);
  outline: none;
}

@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}

@mixin pbi-rotate($degrees, $rotation) {
  transform: rotate($degrees);
}

@mixin pbi-flip($horiz, $vert, $rotation) {
  transform: scale($horiz, $vert);
}

@mixin menu-droparrow {
  padding-right: 22px;
  background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2222%22%20viewBox%3D%220%200%2016%2022%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ctitle%3Edroparrow%3C%2Ftitle%3E%3Cpath%20d%3D%22M0%207.344h10.5l-5.25%205.566-5.25-5.565z%22%20fill%3D%22%234e4e4e%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E') !important;
  background-position: right center !important;
  background-repeat: no-repeat !important;

  @media (forced-colors: active) {
    background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2222%22%20viewBox%3D%220%200%2016%2022%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ctitle%3Edroparrow%3C%2Ftitle%3E%3Cpath%20d%3D%22M0%207.344h10.5l-5.25%205.566-5.25-5.565z%22%20fill%3D%22%23fff%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E') !important;
  }

  .bgdark & {
    background-image: url("data:image/svg+xml,%3csvg width='16' height='22' viewBox='0 0 16 22' xmlns='http://www.w3.org/2000/svg'%3e%3ctitle%3edroparrow%3c/title%3e%3cpath d='M0 7.344h10.5l-5.25 5.566-5.25-5.565z' fill='%23adbac7' fill-rule='evenodd'/%3e%3c/svg%3e") !important;
  }
}

@mixin menu-droparrow-disabled {
  padding-right: 22px;
  background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2222%22%20viewBox%3D%220%200%2016%2022%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M0%207.344h10.5l-5.25%205.566-5.25-5.565z%22%20fill%3D%22%23c0c0c0%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E') !important;
  background-position: right center !important;
  background-repeat: no-repeat !important;

  @media (forced-colors: active) {
    background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2222%22%20viewBox%3D%220%200%2016%2022%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ctitle%3Edroparrow%3C%2Ftitle%3E%3Cpath%20d%3D%22M0%207.344h10.5l-5.25%205.566-5.25-5.565z%22%20fill%3D%22%232db406%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E') !important;
  }

  .bgdark & {
    background-image: url("data:image/svg+xml,%3csvg width='16' height='22' viewBox='0 0 16 22' xmlns='http://www.w3.org/2000/svg'%3e%3ctitle%3edroparrow%3c/title%3e%3cpath d='M0 7.344h10.5l-5.25 5.566-5.25-5.565z' fill='%23adbac7' fill-rule='evenodd'/%3e%3c/svg%3e") !important;
  }
}
