.badge {
  background-color: var(--primary);
  color: var(--white);
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: var(--font_size_sm);
  font-weight: normal;
  line-height: 1;
  display: inline-block;
  padding: 0.3rem 0.5rem;

  .close {
    font-size: 0.7rem;
  }

  &.text-bg-light {
    .bgwhite & {
      background-color: var(--rich100) !important;
    }
  }
}

.badge-light {
  background-color: var(--white);
  color: var(--text);

  .bgwhite & {
    background-color: var(--rich100);
    color: var(--text);
  }
}

.badge-sm {
  padding: 0.2rem 0.4rem;
  font-size: 10px;
}
