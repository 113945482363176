.data-widget {
  display: flex;
  align-items: center;
  border-radius: $border-radius;
  height: 100%;

  .data-widget-icon {
    display: flex;
    flex-grow: 0;
    align-items: center;
    justify-content: center;
    padding-left: 1rem;
    border-bottom-left-radius: $border-radius;
    border-top-left-radius: $border-radius;
    color: var(--white);
    font-size: 3rem;
  }

  .data-widget-data {
    flex-grow: 1;
    padding: 1rem;
    border-bottom-right-radius: $border-radius;
    border-top-right-radius: $border-radius;
    color: var(--white);

    .data-description {
      padding: 0;
      margin: 0;
      font-family: var(--font_family_brand_regular);
      font-size: var(--font_size_lg);
      line-height: 1.2;
    }

    .data-value {
      padding: 0;
      margin: 0;
      font-family: var(--font_family_brand_light);
      font-size: 2rem;
      line-height: 1.33;
    }
  }
}

.data-widget-blue {
  @include data-widget(var(--blue));
}

.data-widget-darkblue {
  @include data-widget(var(--blue700));
}

.data-widget-purple {
  @include data-widget(var(--purple));
}

.data-widget-magenta {
  @include data-widget(var(--magenta));
}

.data-widget-mediumblue {
  @include data-widget(var(--mediumblue));
}

.data-widget-green {
  @include data-widget(#0c884c);
}

.data-widget-orange {
  @include data-widget(#cc4d14);
}

.data-widget-1 {
  @include data-widget(var(--data_widget_1));
}

.data-widget-2 {
  @include data-widget(var(--data_widget_2));
}

.data-widget-3 {
  @include data-widget(var(--data_widget_3));
}

.card.category-widget {
  border-radius: $border-radius;
  border: 1px solid var(--white);
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.05);
  cursor: pointer;

  .category-widget-icon {
    color: var(--primary);
    font-size: 5rem;
    text-align: center;
  }

  .card-footer {
    padding: 1rem 1.5rem;
    background-color: transparent;
    border-top: transparent;
    text-align: center;

    .category-widget-title {
      padding: 0;
      margin: 0 0 5px;
      font-weight: normal;
      line-height: normal;
      font-size: var(--font_size_lg);
      color: var(--primary);
    }

    .category-widget-description {
      padding: 0;
      margin: 0;
    }
  }

  &:hover {
    border-color: var(--btn_primary);
    background-color: var(--btn_secondary_bg_active);
  }
}
.widget-card {
  h2 {
    margin-top: 0;
  }
  border: 1px solid #d8dae4;
  box-sizing: border-box;
  /* Card Shadow */
  box-shadow: 1px 0px 8px rgba(113, 113, 113, 0.1);
  border-radius: 6px;
  font-size: var(--font_size_lg);
  padding-bottom: 10px;
  i {
    margin-right: 5px;
  }
  .card-body {
    padding: 0.75rem 1.25rem;
  }
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .card-title {
    font-size: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    .pbi-icon-outline {
      margin-right: 8px;
      display: inline-block;
      font-size: 1.5em;
    }
  }
  .widget-data {
    font-family: var(--font_family_brand_regular);
    margin-bottom: 2rem;
  }
  .widget-value {
    font-size: 36px;
    line-height: 2.5rem;
  }
  .widget-subhead {
    font-weight: bold;
  }
  table.no-border,
  table.no-border td {
    border: none;
    padding: 0.25rem;
  }
  table:last-of-type {
    margin-bottom: 0;
  }
  .error-field {
    color: var(--error);
  }
  .res-value {
    color: var(--magenta);
    font-size: 24px;
  }
  table td:first-child {
    padding-left: 0;
  }
}
.widget-card.gradient-card {
  background-image: linear-gradient(90deg, #3e53a4 0%, #cf0989 100%);
}
.widget-card.solid-color-card {
  background-color: var(--primary);
}
.widget-card.gradient-card,
.widget-card.solid-color-card,
.widget-card.solid-purple-card {
  color: #fff;
  .card-header,
  a {
    color: #fff;
  }
  .btn-secondary {
    background-color: transparent;
    border: 1px solid #d8dae4;
    color: #fff;
    min-width: 100px;
  }
  .btn-secondary:hover {
    opacity: 0.7;
  }
  .btn-secondary:active,
  .btn-secondary:focus {
    background-color: transparent;
    border: 1px solid #d8dae4;
    color: #fff;
  }
  .btn-secondary {
    background-color: transparent;
    border: 1px solid #d8dae4;
    color: #fff;
  }
  .btn-secondary:hover {
    opacity: 0.7;
  }
  .btn-secondary:active,
  .btn-secondary:focus {
    background-color: transparent;
    border: 1px solid #d8dae4;
    color: #fff;
  }
}
.gradient-bg {
  margin-top: 50px;
  margin-bottom: 10px;
  background-image: linear-gradient(90deg, #3e53a4 0%, #cf0989 100%);
  color: #fff;
  h4 {
    color: #fff;
  }
  .btn-secondary {
    background-color: transparent;
    border: 1px solid #d8dae4 !important;
    color: #fff !important;
  }
  .btn-secondary:hover {
    opacity: 0.7;
  }
  .btn-secondary:active,
  .btn-secondary:focus {
    background-color: transparent !important;
    border: 1px solid #d8dae4 !important;
    color: #fff !important;
  }
}
.widget-card.solid-purple-card {
  background-color: var(--purple);
}
