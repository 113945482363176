.mat-button-toggle-group {
  border-radius: 2px !important;
  border: 1px solid var(--btn_primary) !important;
  overflow: visible !important;

  &.is-invalid {
    border-color: var(--danger) !important;
  }

  .mat-button-toggle {
    background-color: transparent !important;
    border-color: var(--btn_primary) !important;
    color: var(--btn_primary) !important;

    &.cdk-focused,
    &.cdk-keyboard-focused {
      outline: 1.5px solid #1a88ff !important;
      outline-offset: 2px !important;
    }

    &:hover {
      color: #fff !important;
      background-color: var(--btn_primary) !important;
    }

    &.mat-button-toggle-checked {
      color: #fff !important;
      background-color: var(--btn_primary) !important;
    }

    .mat-button-toggle-label-content {
      font-family: var(--font_family_brand_regular) !important;
      line-height: inherit !important;
      padding: 0.5rem 0.75rem;
    }
  }
}

// .mat-button-toggle {
//   margin: 0;
//   border: 0;

//   .mat-button-toggle-button {
//     border: 1px solid var(--btn_primary);
//     border-radius: 0;
//     border-left: 0;

//     &:focus {
//       border: 1px solid var(--focus_border_color);
//       box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
//     }

//   }

//   // &:first-child {
//   //   .mat-button-toggle-button {
//   //     border-left: 1px solid var(--btn_primary);
//   //   }
//   // }
// }

// .mat-button-toggle-checked {
//   background-color: var(--btn_primary);

//   .mat-button-toggle-label-content {
//     color: #fff;
//   }
// }

// .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
// .mat-button-toggle-group-appearance-standard {
//   border-radius: $border-radius !important;
// }

// .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
//   border-left: 0;
// }

// .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
// .mat-button-toggle-group-appearance-standard {
//   border: 0;
//   margin-left: -4px;
// }

// .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
//   line-height: 1.5;
//   padding: 0.5rem 0.75rem !important;
//   color: var(--text);
//   font-family: var(--font_family_brand_regular);
//   font-size: var(--font_size_base);
//   font-weight: normal;
// }

// .mat-button-toggle-group.mat-button-toggle-group-appearance-standard {
//   &.is-invalid {
//     border-color: var(--danger);

//     + .invalid-feedback {
//       display: block;
//     }
//   }
// }
