// common to all buttons
.btn {
  font-family: var(--font_family_brand_regular);
  border-radius: var(--border_radius);
  font-size: var(--font_size_base);
  line-height: var(--line_height_base);
  padding: 0.5rem 0.75rem;
}

.btn-wrap {
  white-space: unset;
}

.btn-primary.disabled,
.btn-primary:disabled {
  border-color: var(--disabled);
  background-color: var(--disabled);
  color: var(--btn_primary_label);
}

.btn.disabled,
.btn:disabled {
  opacity: 1; // override bootstrap
}

// primary buttons
.btn-primary:not([disabled]):not(.disabled),
.btn-primary:not([disabled]):not(.disabled) {
  border-color: var(--btn_primary);
  background-color: var(--btn_primary);
  color: var(--btn_primary_label);

  &:hover,
  &.hover,
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    border-color: var(--btn_primary_active);
    background-color: var(--btn_primary_active);
    text-decoration: none; // Bootstrap 5 fix
  }
}

.btn-secondary,
.btn-outline-secondary,
.btn-outline-primary,
.btn-link,
.btn-group .btn-secondary,
.btn-group .btn-outline-secondary,
.btn-group-vertical .btn-secondary,
.btn-group-vertical .btn-outline-secondary,
.btn-secondary:not([disabled]):not(.disabled),
.btn-link:not([disabled]):not(.disabled),
.btn-outline-secondary:not([disabled]):not(.disabled),
.btn-group > .btn-check:checked + .btn,
.show > .btn-secondary.dropdown-toggle,
.show > .btn-outline-secondary.dropdown-toggle {
  border-color: var(--btn_primary);
  background-color: var(--btn_secondary_bg);
  color: var(--btn_secondary_label);

  &:hover,
  &.hover,
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    border-color: var(--btn_primary);
    background-color: var(--btn_secondary_bg_active);
    color: var(--btn_secondary_bg_active_label);
    text-decoration: none; // Bootstrap 5 fix
  }

  &:active,
  .open > &.dropdown-toggle {
    border-color: var(--focus);
    background-color: var(--btn_primary);
    background-image: none;
    box-shadow: 0 0 10px var(--focus);
    color: var(--btn_secondary_label);

    &:hover {
      background-color: var(--btn_primary);
      color: #fff;
      border-color: var(--btn_primary);
    }
  }

  &:focus,
  &.focus {
    @include focused;
    outline-offset: 1.5px;
    // background-color: var(--white);
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &.hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      border-color: var(--disabled);
      background-color: transparent;
      color: var(--disabled);
      opacity: 1;
    }
  }

  &.disabled.loading,
  &[disabled].loading {
    border-color: var(--disabled);
    background-color: transparent;
    color: var(--disabled);
  }

  &.btn-transparent {
    border-color: var(--white);
    background-color: transparent;
    color: var(--white);
  }

  .badge {
    margin-left: 10px;
    background-color: var(--secondary);
    color: var(--white);
  }

  &.tile-button {
    width: 160px;
    height: 120px;
    border-color: var(--border_color);
    background-color: var(--white);
    border-radius: $border-radius;

    &:hover,
    &.hover {
      background-color: var(--white);
      box-shadow: 1px 1px 16px 0 hsla(var(--hue), 0%, 44%, 0.3);
    }

    &:active,
    &.active {
      border-width: 2px;
      border-color: var(--primary);
      background-color: var(--btn_secondary_bg);
      box-shadow: none;

      &:hover {
        border-width: 2px;
        border-color: var(--primary);
        background-color: var(--btn_secondary_bg);
      }
    }

    &.disabled,
    &[disabled] {
      border-color: var(--disabled);
      pointer-events: none;

      img {
        filter: grayscale(100%);
        opacity: 0.6;
      }
    }
  }
  &.tile-button-style {
    width: auto;
    height: 240px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
}

// override BS4 color:
.btn-secondary:focus,
.btn-secondary.focus,
.btn-outline-secondary:focus,
.btn-outline-secondary.focus,
.btn-primary:focus,
.btn-primary.focus,
.btn:focus,
.btn.focus,
.btn:focus-visible,
.btn-check:focus-visible + .btn {
  @include focused;
  outline-offset: 1.5px;
}
.btn-primary:focus,
.btn-primary.focus,
.btn:focus,
.btn.focus {
  outline: 1.5px solid var(--focus_border_color) !important; // to override outline:0 from reboot.css
}
.btn-link {
  border: 1px solid transparent;
}

.btn-group {
  .btn-secondary,
  .btn-outline-secondary,
  .btn-outline-primary,
  .btn-cancel,
  .btn-group .btn-secondary,
  .btn-group .btn-outline-secondary,
  .btn-group-vertical .btn-secondary,
  .btn-group-vertical .btn-outline-secondary,
  .btn-secondary:not([disabled]):not(.disabled):active,
  .btn-secondary:not([disabled]):not(.disabled).active,
  .btn-outline-secondary:not([disabled]):not(.disabled):active,
  .btn-outline-secondary:not([disabled]):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle,
  .show > .btn-outline-secondary.dropdown-toggle {
    &.active {
      background-color: var(--btn_primary);
      color: var(--btn_primary_label);
    }
  }
}

// bootstrap 5
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:checked + .btn:hover,
.btn-check + .btn:hover {
  background-color: var(--btn_primary);
  color: var(--btn_primary_label);
  border-color: var(--btn_primary);
}

.btn-group + div[ngbDropdown],
.btn + div[ngbDropdown],
div[ngbDropdown] + .btn,
div[ngbDropdown] + .btn-group {
  margin-left: 0.5rem;
}

.btn-group {
  .dropdown {
    .dropdown-toggle {
      padding-right: 0.75rem;
    }
  }
}

.btn-group > .btn-group:not(:first-child),
.btn-group > .btn:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
}

.show > .btn-secondary.dropdown-toggle,
.show > .btn-secondary.dropdown-toggle:focus,
.show > .btn-outline-secondary.dropdown-toggle,
.show > .btn-outline-secondary.dropdown-toggle:focus,
.show > .btn-outline-primary.dropdown-toggle,
.show > .btn-outline-primary.dropdown-toggle:focus,
.show > .btn-outline-primary.dropdown-toggle:hover,
.show > .btn-outline-primary.dropdown-toggle:focus:hover {
  border-color: var(--btn_primary);
  background-color: var(--btn_primary);
  box-shadow: none;
  color: var(--btn_primary_label);
}

.btn-link:not([disabled]):not(.disabled),
.btn-link {
  border: 1px solid transparent;
  background-color: transparent;
  color: var(--btn_secondary_label);

  &:hover,
  &.hover,
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    border-color: var(--btn_secondary_bg_active);
    background-color: var(--btn_secondary_bg_active);
    color: var(--btn_secondary_label);
    text-decoration: underline;
  }

  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }

  &:focus,
  &:focus:hover,
  &.focus {
    @include focused;
    outline-offset: 1.5px;
    background-color: var(--btn_secondary_bg);
    color: var(--btn_secondary-active);
    text-decoration: underline;
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &.hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      border-color: transparent;
      background-color: transparent;
      color: var(--disabled);
      opacity: 1;
    }
  }
}

.dropdown-menu {
  margin: 0;
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 0.26rem 0.5rem;
  border-radius: 0.2rem;
  font-size: 0.749875rem;
  line-height: 1.5;
}

.btn-group-lg > .btn,
.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 4px;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  margin-left: 0 !important;
}

[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

.btn-glass {
  border-color: var(--white);
  color: var(--white);
  background-color: transparent;

  &:hover {
    color: var(--white);
    background-color: hsla(100, 0%, 100%, 0.1);
  }
}

input::file-selector-button {
  cursor: pointer;
  display: inline-block;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  background-color: transparent;
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;

  border: 1px solid var(--btn_primary);
  border-radius: 2px !important;
  background-color: var(--btn_secondary_bg);
  color: var(--btn_primary);
  font-family: 'PrecisionSans_W_Rg', 'Helvetica Neue', Arial, sans-serif;
  margin-right: 1rem;
}

input::-webkit-file-upload-button {
  cursor: pointer;
  display: inline-block;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  background-color: transparent;
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 2px !important;
  border: 1px solid var(--btn_primary);
  background-color: var(--btn_secondary_bg);
  color: var(--btn_primary);
  font-family: 'PrecisionSans_W_Rg', 'Helvetica Neue', Arial, sans-serif;
  border-radius: var (--border_radius) !important;
  margin-right: 1rem;
}

input[type='file']::file-selector-button:hover {
  border: 1px solid var(--btn_primary);
  background-color: var(--btn_secondary_bg_active);
  color: var(--btn_secondary_bg_active_label);
}

input[type='file']::-webkit-file-upload-button:hover {
  border: 1px solid var(--btn_primary);
  background-color: var(--btn_secondary_bg_active);
  color: var(--btn_secondary_bg_active_label);
}
