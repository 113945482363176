@font-face {
  font-family: 'pbi-icon-outline';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/pbi-icon-outline.woff2?fw00tc') format('woff2'),
    url('../fonts/pbi-icon-outline.ttf?fw00tc') format('truetype'),
    url('../fonts/pbi-icon-outline.woff?fw00tc') format('woff'),
    url('../fonts/pbi-icon-outline.svg?fw00tc#pbi-icon-outline') format('svg');
}

.pbi-icon-outline {
  display: inline-block;

  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'pbi-icon-outline' !important;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: none;
  text-transform: none;
}

//spinning
.pbi-icon-outline.spin {
  -webkit-animation: pbi-icon-spin 2s infinite linear;
  -moz-animation: pbi-icon-spin 2s infinite linear;
  animation: pbi-icon-spin 2s infinite linear;
}

@keyframes pbi-icon-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

// quick scaling
.pbi-icon-outline.lg {
  font-size: 1.33333333em;
  vertical-align: -16%;
}

.pbi-icon-outline.x2 {
  font-size: 2rem;
}

.pbi-icon-outline.x3 {
  font-size: 3rem;
}

.pbi-icon-outline.x4 {
  font-size: 4rem;
}

.pbi-icon-outline.x5 {
  font-size: 5rem;
}

.pbi-icon-outline.x6 {
  font-size: 6rem;
}

// rotate flip
.pbi-icon-outline.rotate-90 {
  @include pbi-rotate(90deg, 1);
}

.pbi-icon-outline.rotate-180 {
  @include pbi-rotate(180deg, 2);
}

.pbi-icon-outline.rotate-270 {
  @include pbi-rotate(270deg, 3);
}

.pbi-icon-outline.flip-y {
  @include pbi-flip(-1, 1, 0);
}

.pbi-icon-outline.flip-x {
  @include pbi-flip(1, -1, 2);
}

// icons
// NOTE: for icons to work, each needs to be prepended with .pbi-icon-outline
.pbi-icon-outline.pbi-lockers:before {
  content: '\e95b';
}
.pbi-icon-outline.pbi-endofday:before {
  content: '\e93c';
}
.pbi-icon-outline.pbi-history:before {
  content: '\e93d';
}
.pbi-icon-outline.pbi-stamps:before {
  content: '\e93e';
}
.pbi-icon-outline.pbi-receiving:before {
  content: '\e93f';
}

.pbi-icon-outline.pbi-no-image:before {
  content: '\e93b';
}

.pbi-icon-outline.pbi-returns:before {
  content: '\e93a';
}

.pbi-icon-outline.pbi-sun:before {
  content: '\e938';
}

.pbi-icon-outline.pbi-moon:before {
  content: '\e939';
}

.pbi-icon-outline.pbi-a11y-solid:before {
  content: '\e936';
}

.pbi-icon-outline.pbi-a11y-outline:before {
  content: '\e937';
}

.pbi-icon-outline.pbi-paint-brush:before {
  content: '\e935';
}

.pbi-icon-outline.pbi-measure:before {
  content: '\e934';
}

.pbi-icon-outline.pbi-shipping-label::before {
  content: '\e933';
}

.pbi-icon-outline.pbi-batch::before {
  content: '\e932';
}

.pbi-icon-outline.pbi-link-external::before {
  content: '\e931';
}

.pbi-icon-outline.pbi-delivery::before {
  content: '\e930';
}

.pbi-icon-outline.pbi-rocket::before {
  content: '\e92f';
}

.pbi-icon-outline.pbi-user-circle::before {
  content: '\e92e';
}

.pbi-icon-outline.pbi-account-switcher::before {
  content: '\e92d';
}

.pbi-icon-outline.pbi-headset::before {
  content: '\e927';
}

.pbi-icon-outline.pbi-delete-square::before {
  content: '\e928';
}

.pbi-icon-outline.pbi-remove-square::before {
  content: '\e929';
}

.pbi-icon-outline.pbi-check-square::before {
  content: '\e92a';
}

.pbi-icon-outline.pbi-refresh-2::before {
  content: '\e91d';
}

.pbi-icon-outline.pbi-glasses::before {
  content: '\e923';
}

.pbi-icon-outline.pbi-collapse-all::before {
  content: '\e924';
}

.pbi-icon-outline.pbi-expand-all::before {
  content: '\e925';
}

.pbi-icon-outline.pbi-basket::before {
  content: '\e926';
}

.pbi-icon-outline.pbi-help::before {
  content: '\e91c';
}

.pbi-icon-outline.pbi-coins::before {
  content: '\e91e';
}

.pbi-icon-outline.pbi-direction::before {
  content: '\e91f';
}

.pbi-icon-outline.pbi-newsletter::before {
  content: '\e920';
}

.pbi-icon-outline.pbi-globe-2::before {
  content: '\e921';
}

.pbi-icon-outline.pbi-palette::before {
  content: '\e922';
}

.pbi-icon-outline.pbi-preferences::before {
  content: '\e91a';
}

.pbi-icon-outline.pbi-delete-key::before {
  content: '\e91b';
}

.pbi-icon-outline.pbi-chart-bars-axis::before {
  content: '\e915';
}

.pbi-icon-outline.pbi-chart-bars::before {
  content: '\e916';
}

.pbi-icon-outline.pbi-archive-drawer::before {
  content: '\e917';
}

.pbi-icon-outline.pbi-grammar-check::before {
  content: '\e918';
}

.pbi-icon-outline.pbi-notification::before {
  content: '\e919';
}

.pbi-icon-outline.pbi-add::before {
  content: '\eac5';
}

.pbi-icon-outline.pbi-add-circle::before {
  content: '\eac6';
}

.pbi-icon-outline.pbi-add-query-builder::before {
  content: '\eac7';
}

.pbi-icon-outline.pbi-address-book::before {
  content: '\eac8';
}

.pbi-icon-outline.pbi-alert-circle::before {
  content: '\eac9';
}

.pbi-icon-outline.pbi-android::before {
  content: '\eaca';
}

.pbi-icon-outline.pbi-apple::before {
  content: '\eacb';
}

.pbi-icon-outline.pbi-apps::before {
  content: '\eacc';
}

.pbi-icon-outline.pbi-archive::before {
  content: '\e915';
}

.pbi-icon-outline.pbi-attachment::before {
  content: '\eace';
}

.pbi-icon-outline.pbi-bank::before {
  content: '\ead0';
}

.pbi-icon-outline.pbi-bell::before {
  content: '\e902';
}

.pbi-icon-outline.pbi-betweeness::before {
  content: '\ead1';
}

.pbi-icon-outline.pbi-bluetooth::before {
  content: '\ead2';
}

.pbi-icon-outline.pbi-box::before {
  content: '\ead3';
}

.pbi-icon-outline.pbi-bulb::before {
  content: '\eb22';
}

.pbi-icon-outline.pbi-calculator::before {
  content: '\ead4';
}

.pbi-icon-outline.pbi-calendar::before {
  content: '\ead5';
}

.pbi-icon-outline.pbi-camera::before {
  content: '\ead6';
}

.pbi-icon-outline.pbi-camera-off::before {
  content: '\ead7';
}

.pbi-icon-outline.pbi-cart::before {
  content: '\ead8';
}

.pbi-icon-outline.pbi-catalog::before {
  content: '\ead9';
}

.pbi-icon-outline.pbi-catalog-addto::before {
  content: '\eada';
}

.pbi-icon-outline.pbi-catalog-new::before {
  content: '\eadb';
}

.pbi-icon-outline.pbi-chart-bars::before {
  content: '\e916';
}

.pbi-icon-outline.pbi-chart-bars-axis::before {
  content: '\e917';
}

.pbi-icon-outline.pbi-chat::before {
  content: '\eadc';
}

.pbi-icon-outline.pbi-chat-conversation::before {
  content: '\eadd';
}

.pbi-icon-outline.pbi-check::before {
  content: '\e90b';
}

.pbi-icon-outline.pbi-check-circle::before {
  content: '\eadf';
}

.pbi-icon-outline.pbi-circle-down::before {
  content: '\e907';
}

.pbi-icon-outline.pbi-circle-left::before {
  content: '\e909';
}

.pbi-icon-outline.pbi-circle-right::before {
  content: '\e90a';
}

.pbi-icon-outline.pbi-circle-up::before {
  content: '\e908';
}

.pbi-icon-outline.pbi-clipboard::before {
  content: '\eade';
}

.pbi-icon-outline.pbi-close::before {
  content: '\eae0';
}

.pbi-icon-outline.pbi-closeness::before {
  content: '\eae1';
}

.pbi-icon-outline.pbi-cloud-download::before {
  content: '\eae2';
}

.pbi-icon-outline.pbi-cloud-upload::before {
  content: '\eae3';
}

.pbi-icon-outline.pbi-connection::before {
  content: '\eae4';
}

.pbi-icon-outline.pbi-connection-database::before {
  content: '\eae5';
}

.pbi-icon-outline.pbi-connection-filesystem::before {
  content: '\eae6';
}

.pbi-icon-outline.pbi-connection-new::before {
  content: '\eae7';
}

.pbi-icon-outline.pbi-credit-card::before {
  content: '\eae8';
}

.pbi-icon-outline.pbi-currency-dollar::before {
  content: '\eae9';
}

.pbi-icon-outline.pbi-currency-euro::before {
  content: '\eaea';
}

.pbi-icon-outline.pbi-currency-pound::before {
  content: '\eaeb';
}

.pbi-icon-outline.pbi-currency-yen::before {
  content: '\eaec';
}

.pbi-icon-outline.pbi-cursor-arrow-left::before {
  content: '\e90d';
}

.pbi-icon-outline.pbi-dashboard::before {
  content: '\eaee';
}

.pbi-icon-outline.pbi-database::before {
  content: '\eaef';
}

.pbi-icon-outline.pbi-degree::before {
  content: '\eaf0';
}

.pbi-icon-outline.pbi-delete::before {
  content: '\e910';
}

.pbi-icon-outline.pbi-delete-circle::before {
  content: '\eaf1';
}

.pbi-icon-outline.pbi-delivery-fast::before {
  content: '\eaf2';
}

.pbi-icon-outline.pbi-download::before {
  content: '\eaf4';
}

.pbi-icon-outline.pbi-drop::before {
  content: '\e900';
}

.pbi-icon-outline.pbi-duplicate::before {
  content: '\eaf5';
}

.pbi-icon-outline.pbi-entity::before {
  content: '\eaf7';
}

.pbi-icon-outline.pbi-export::before {
  content: '\eaf8';
}

.pbi-icon-outline.pbi-fast-forward::before {
  content: '\eaf9';
}

.pbi-icon-outline.pbi-file::before {
  content: '\eafb';
}

.pbi-icon-outline.pbi-file-content::before {
  content: '\eafc';
}

.pbi-icon-outline.pbi-filter::before {
  content: '\eafd';
}

.pbi-icon-outline.pbi-flag::before {
  content: '\eafe';
}

.pbi-icon-outline.pbi-flash::before {
  content: '\eaff';
}

.pbi-icon-outline.pbi-folder::before {
  content: '\eb01';
}

.pbi-icon-outline.pbi-folder-addto::before {
  content: '\eb02';
}

.pbi-icon-outline.pbi-folder-new::before {
  content: '\eb03';
}

.pbi-icon-outline.pbi-folder-open::before {
  content: '\eb04';
}

.pbi-icon-outline.pbi-folder-square::before {
  content: '\eb00';
}

.pbi-icon-outline.pbi-fullscreen::before {
  content: '\eb06';
}

.pbi-icon-outline.pbi-globe::before {
  content: '\eb07';
}

.pbi-icon-outline.pbi-grammar-check::before {
  content: '\e918';
}

.pbi-icon-outline.pbi-heart::before {
  content: '\eb08';
}

.pbi-icon-outline.pbi-help-circle::before {
  content: '\eb09';
}

.pbi-icon-outline.pbi-higher-value::before {
  content: '\eb0a';
}

.pbi-icon-outline.pbi-history::before {
  content: '\eb0b';
}

.pbi-icon-outline.pbi-home::before {
  content: '\eb0c';
}

.pbi-icon-outline.pbi-hubvis-canvas::before {
  content: '\eb0d';
}

.pbi-icon-outline.pbi-hubvis-circular::before {
  content: '\eb0e';
}

.pbi-icon-outline.pbi-hubvis-cluster::before {
  content: '\eb0f';
}

.pbi-icon-outline.pbi-hubvis-expand-arrow::before {
  content: '\eb10';
}

.pbi-icon-outline.pbi-hubvis-graph::before {
  content: '\eb11';
}

.pbi-icon-outline.pbi-hubvis-hierarchy::before {
  content: '\eb12';
}

.pbi-icon-outline.pbi-hubvis-load-pred::before {
  content: '\eb13';
}

.pbi-icon-outline.pbi-hubvis-load-succ::before {
  content: '\eb14';
}

.pbi-icon-outline.pbi-hubvis-map::before {
  content: '\eb15';
}

.pbi-icon-outline.pbi-hubvis-model::before {
  content: '\eb16';
}

.pbi-icon-outline.pbi-hubvis-organic::before {
  content: '\eb17';
}

.pbi-icon-outline.pbi-hubvis-orthogonal::before {
  content: '\eb18';
}

.pbi-icon-outline.pbi-hubvis-query-selection::before {
  content: '\eb19';
}

.pbi-icon-outline.pbi-hubvis-radial::before {
  content: '\eb1a';
}

.pbi-icon-outline.pbi-hubvis-remove-filter::before {
  content: '\eb1b';
}

.pbi-icon-outline.pbi-hubvis-select-entires::before {
  content: '\eb1e';
}

.pbi-icon-outline.pbi-hubvis-select-pred::before {
  content: '\eb1c';
}

.pbi-icon-outline.pbi-hubvis-select-succ::before {
  content: '\eb1d';
}

.pbi-icon-outline.pbi-hubvis-table::before {
  content: '\eb1f';
}

.pbi-icon-outline.pbi-hubvis-tree::before {
  content: '\eb20';
}

.pbi-icon-outline.pbi-hubvis-unload::before {
  content: '\eb21';
}

.pbi-icon-outline.pbi-image::before {
  content: '\eb4a';
}

.pbi-icon-outline.pbi-import::before {
  content: '\eb23';
}

.pbi-icon-outline.pbi-influence::before {
  content: '\eb24';
}

.pbi-icon-outline.pbi-info-circle::before {
  content: '\eb25';
}

.pbi-icon-outline.pbi-key::before {
  content: '\eb26';
}

.pbi-icon-outline.pbi-label::before {
  content: '\eb27';
}

.pbi-icon-outline.pbi-label-auto::before {
  content: '\eb28';
}

.pbi-icon-outline.pbi-label-layer::before {
  content: '\eb29';
}

.pbi-icon-outline.pbi-label-select::before {
  content: '\eb2a';
}

.pbi-icon-outline.pbi-layer::before {
  content: '\eb2b';
}

.pbi-icon-outline.pbi-layer-group::before {
  content: '\eb2c';
}

.pbi-icon-outline.pbi-layer-new::before {
  content: '\eb2d';
}

.pbi-icon-outline.pbi-layers::before {
  content: '\eb2e';
}

.pbi-icon-outline.pbi-like::before {
  content: '\eb30';
}

.pbi-icon-outline.pbi-line::before {
  content: '\eb2f';
}

.pbi-icon-outline.pbi-link::before {
  content: '\eb31';
}

.pbi-icon-outline.pbi-link-broken::before {
  content: '\eb32';
}

.pbi-icon-outline.pbi-list-bullet::before {
  content: '\eb33';
}

.pbi-icon-outline.pbi-list-numbers::before {
  content: '\eb34';
}

.pbi-icon-outline.pbi-loader-circle::before {
  content: '\e901';
}

.pbi-icon-outline.pbi-location::before {
  content: '\eb35';
}

.pbi-icon-outline.pbi-location-pin::before {
  content: '\eb36';
}

.pbi-icon-outline.pbi-lock::before {
  content: '\eb37';
}

.pbi-icon-outline.pbi-lower-value::before {
  content: '\eb38';
}

.pbi-icon-outline.pbi-mail::before {
  content: '\eb39';
}

.pbi-icon-outline.pbi-map-folded::before {
  content: '\eb3a';
}

.pbi-icon-outline.pbi-map-marker::before {
  content: '\eb3d';
}

.pbi-icon-outline.pbi-map-world::before {
  content: '\eb3b';
}

.pbi-icon-outline.pbi-map-world-new::before {
  content: '\eb3c';
}

.pbi-icon-outline.pbi-media-player::before {
  content: '\eb3e';
}

.pbi-icon-outline.pbi-megaphone::before {
  content: '\e919';
}

.pbi-icon-outline.pbi-menu::before {
  content: '\eb3f';
}

.pbi-icon-outline.pbi-metadata::before {
  content: '\eb40';
}

.pbi-icon-outline.pbi-metadata-template::before {
  content: '\eb41';
}

.pbi-icon-outline.pbi-microphone::before {
  content: '\eb42';
}

.pbi-icon-outline.pbi-minimal-down::before {
  content: '\e905';
}

.pbi-icon-outline.pbi-minimal-left::before {
  content: '\eacf';
}

.pbi-icon-outline.pbi-minimal-right::before {
  content: '\eb47';
}

.pbi-icon-outline.pbi-minimal-up::before {
  content: '\e906';
}

.pbi-icon-outline.pbi-mobile-button::before {
  content: '\e911';
}

.pbi-icon-outline.pbi-money::before {
  content: '\eb43';
}

.pbi-icon-outline.pbi-money-coins::before {
  content: '\eb44';
}

.pbi-icon-outline.pbi-monitor::before {
  content: '\eb45';
}

.pbi-icon-outline.pbi-next::before {
  content: '\eb05';
}

.pbi-icon-outline.pbi-pause::before {
  content: '\eb48';
}

.pbi-icon-outline.pbi-pencil::before {
  content: '\eaf6';
}

.pbi-icon-outline.pbi-phone::before {
  content: '\eb49';
}

.pbi-icon-outline.pbi-play::before {
  content: '\eb4b';
}

.pbi-icon-outline.pbi-plug::before {
  content: '\eb4c';
}

.pbi-icon-outline.pbi-point::before {
  content: '\eb4d';
}

.pbi-icon-outline.pbi-polygon::before {
  content: '\eb4e';
}

.pbi-icon-outline.pbi-previous::before {
  content: '\eb4f';
}

.pbi-icon-outline.pbi-printer::before {
  content: '\eb50';
}

.pbi-icon-outline.pbi-property-gradient::before {
  content: '\eb51';
}

.pbi-icon-outline.pbi-query-builder::before {
  content: '\eb52';
}

.pbi-icon-outline.pbi-redo::before {
  content: '\eb53';
}

.pbi-icon-outline.pbi-reduce-screen::before {
  content: '\eb54';
}

.pbi-icon-outline.pbi-refresh::before {
  content: '\eb55';
}

.pbi-icon-outline.pbi-rewind::before {
  content: '\eb56';
}

.pbi-icon-outline.pbi-save::before {
  content: '\eb57';
}

.pbi-icon-outline.pbi-scale::before {
  content: '\eb58';
}

.pbi-icon-outline.pbi-scissors::before {
  content: '\eaed';
}

.pbi-icon-outline.pbi-screen-full::before {
  content: '\e904';
}

.pbi-icon-outline.pbi-screen-reduce1::before {
  content: '\e903';
}

.pbi-icon-outline.pbi-search::before {
  content: '\eb59';
}

.pbi-icon-outline.pbi-send::before {
  content: '\eb5a';
}

.pbi-icon-outline.pbi-settings::before {
  content: '\eb5b';
}

.pbi-icon-outline.pbi-share::before {
  content: '\eb5c';
}

.pbi-icon-outline.pbi-sign-in::before {
  content: '\eb5d';
}

.pbi-icon-outline.pbi-sign-out::before {
  content: '\eb5e';
}

.pbi-icon-outline.pbi-sitemap::before {
  content: '\eb5f';
}

.pbi-icon-outline.pbi-small-triangle-down::before {
  content: '\eaf3';
}

.pbi-icon-outline.pbi-small-triangle-up::before {
  content: '\eacd';
}

.pbi-icon-outline.pbi-sort::before {
  content: '\eb60';
}

.pbi-icon-outline.pbi-sound-down::before {
  content: '\eb61';
}

.pbi-icon-outline.pbi-sound-off::before {
  content: '\eb62';
}

.pbi-icon-outline.pbi-sound-on::before {
  content: '\eb63';
}

.pbi-icon-outline.pbi-sound-up::before {
  content: '\eb64';
}

.pbi-icon-outline.pbi-star::before {
  content: '\eafa';
}

.pbi-icon-outline.pbi-stop::before {
  content: '\eb65';
}

.pbi-icon-outline.pbi-table::before {
  content: '\eb66';
}

.pbi-icon-outline.pbi-table-new::before {
  content: '\eb67';
}

.pbi-icon-outline.pbi-tablet-button::before {
  content: '\e913';
}

.pbi-icon-outline.pbi-tablet-simple::before {
  content: '\e912';
}

.pbi-icon-outline.pbi-tack::before {
  content: '\eb68';
}

.pbi-icon-outline.pbi-tail-down::before {
  content: '\e90f';
}

.pbi-icon-outline.pbi-tail-up::before {
  content: '\e90e';
}

.pbi-icon-outline.pbi-text-code::before {
  content: '\e90c';
}

.pbi-icon-outline.pbi-tile::before {
  content: '\eb69';
}

.pbi-icon-outline.pbi-tile-new::before {
  content: '\eb6a';
}

.pbi-icon-outline.pbi-todo::before {
  content: '\e914';
}

.pbi-icon-outline.pbi-trash::before {
  content: '\eb6b';
}

.pbi-icon-outline.pbi-treeview::before {
  content: '\eb6c';
}

.pbi-icon-outline.pbi-undo::before {
  content: '\eb6d';
}

.pbi-icon-outline.pbi-unlock::before {
  content: '\eb6e';
}

.pbi-icon-outline.pbi-upload::before {
  content: '\eb6f';
}

.pbi-icon-outline.pbi-user::before {
  content: '\eb70';
}

.pbi-icon-outline.pbi-users::before {
  content: '\eb46';
}

.pbi-icon-outline.pbi-video::before {
  content: '\eb71';
}

.pbi-icon-outline.pbi-warning-triangle::before {
  content: '\eb72';
}

.pbi-icon-outline.pbi-wifi::before {
  content: '\eb73';
}

.pbi-icon-outline.pbi-zoom-in::before {
  content: '\eb74';
}

.pbi-icon-outline.pbi-zoom-out::before {
  content: '\eb75';
}
