// NOTE: two variants of code blocks.  First, free-standing ones
.code-block {
  position: relative;

  pre[class*='language-'] {
    padding: 0;
  }

  &.code-block-simple {
    // margin: 8px 0;
    margin-bottom: 8px;
    border: 1px solid var(--border_color);
    pre[class*='language-'] {
      padding: 16px 0 0;
    }
  }

  .bgdark & {
    pre[class*='language-'],
    pre[class*='language-'] > code {
      background-color: var(--dark_input_bg);
      color: #fff;
    }
  }

  .code-copy {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    padding: 0.25rem;
    cursor: pointer;
    font-size: 0.675rem;
    z-index: 2;
  }

  ul.tabset-codeblock {
    &.nav-tabs {
      border-bottom: 1px solid var(--divider_color);

      li.tab-codeblock {
        a,
        a.nav-link {
          padding: 0.5rem 1rem;
          background-color: hsla(106, 1%, 99%, 0.3);
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          color: var(--text);
          font-size: var(--font_size_sm);

          .bgdark & {
            background-color: var(--dark_bg);
          }

          &.active {
            border: 1px solid var(--divider_color);

            border-bottom: 1px solid #f5f2f0;
            background-color: #fdfdfd;
            color: var(--text);

            .bgdark & {
              background-color: var(--dark_input_bg);
              color: var(--white);
            }
          }
        }
      }
    }
  }

  .tab-content {
    margin-bottom: 1rem;

    .tab-pane.active {
      padding: 0.5rem 0 0 0;
      border: $border-width solid var(--divider_color);
      border-top: 0;
      background-color: #fdfdfd !important;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      .bgdark & {
        background-color: var(--dark_input_bg) !important;
      }
    }
  }
}

.show-code {
  position: absolute;
  top: 0.5em;
  right: 8.5em;
  padding: 0.25rem;
  cursor: pointer;
  font-size: 0.675rem;
}

//NOTE: second variant is tabs inside a "component example" box
.component-example {
  margin-bottom: 2rem;
  border: $border-width solid var(--divider_color);
  border-radius: 0;

  figure {
    padding: $spacer;
    margin: 0;
  }

  .code-block {
    .tab-content {
      margin-bottom: 0;

      .tab-pane.active {
        border: 0;
      }
    }

    ul.tabset-codeblock {
      margin-bottom: 0;

      li.tab-codeblock:first-child {
        a,
        a.nav-link {
          border-left: 0;
        }
      }
    }
  }
}

// this class is for code displayed outside the code snippet tabs
.code-example {
  background-color: #fff;
  border: 1px solid silver;
  border-radius: 0.25rem;
  padding: 0.5rem;
  margin: 0.25rem 0 1rem;
  color: #0072b8;
  font-family: var(--font-family-monospace);
  font-variant-ligatures: contextual;

  .bgdark & {
    background-color: var(--dark_input_bg);
    color: #e1e1e1;
    border-color: var(--dark_input_border);
  }
}

// this class is for code displayed outside the code snippet tabs
.text-example {
  background-color: #fff;
  border: 1px solid silver;
  border-radius: 0.25rem;
  padding: 0.5rem;
  margin: 0.25rem 0 1rem;

  p {
    margin-bottom: 0;
  }
}

// code snippets
pre code,
code {
  color: #0072b8;

  .bgdark & {
    color: #e1e1e1;
  }

  // font-size: 0.8rem !important;
  .token.comment {
    font-style: italic;
  }
}

.code-expanded code {
  height: auto;
}

.code-collapsed code {
  height: 75px;
  overflow: hidden;
}

.code-collapsed code:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), #f5f6fa);
  background-repeat: repeat-x;
  display: block;
  height: 1.5rem;

  .bgdark & {
    background: linear-gradient(to bottom, rgba(28, 33, 40, 0.5), var(--dark_bg));
  }
}

.cm-s-default .cm-comment {
  color: #a6a6a6;
}

.component-example + .alert {
  margin-top: -1.5rem;
  margin-bottom: 2rem;
}

// note, not figcaption - for captions below some images
figure {
  .caption {
    margin-top: 0.5rem;
    font-style: italic;
  }
}
