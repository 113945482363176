.breadcrumbs {
  margin-top: 100px;
  + h1 {
    margin-top: 10px;
  }
}

.breadcrumb {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  background-color: transparent;
  font-family: var(--font-family-sans-serif);
  font-size: var(--font_size_base);

  a {
    color: var(--hyperlink);
    &:hover {
      color: var(--hyperlink);
    }
  }
  .active {
    color: var(--btn_primary_active);
  }

  .breadcrumb-item {
    display: inline-block;
    margin: 0;
    &::before {
      display: inline-block;
      transform: translateY(3px);
    }
    + .breadcrumb-item::before {
      content: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%229%22%20height%3D%2214%22%3E%20%3Cpath%20fill%3D%22silver%22%20fill-rule%3D%22nonzero%22%20d%3D%22M5.586%207L.293%201.707%201.707.293%208.414%207l-6.707%206.707-1.414-1.414z%22%2F%3E%20%3C%2Fsvg%3E');
    }
  }
}
