.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: var(--text);
}

.checkboxList,
.checkbox-list,
.checkboxList.formcontrol,
.list-box {
  min-height: 120px;
  color: var(--text);
  overflow-y: scroll;

  label {
    margin: 0;
  }

  span {
    color: var(--text);
  }

  &.checkbox-list-autoheight,
  &.list-box-autoheight {
    height: auto;
  }
}

fieldset {
  padding: 1rem;
  border: 1px solid var(--border_color);
  border-radius: $border-radius;

  legend {
    width: auto;
    padding: 0 0.25rem;
    margin: 0;
    font-size: 1rem;
    font-weight: bold;
  }
}

.radio-group-label,
.checkbox-group-label {
  margin-bottom: 0.5rem;
}

input {
  accent-color: var(--primary);
}
