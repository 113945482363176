.brand-category-illustrations {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));

  .icon-with-caption {
    text-align: center;

    img {
      max-height: 6rem;
    }

    p {
      margin-top: 0.5rem;
      font-size: var(--font_size_sm);
      line-height: 1.25;
    }
  }
}

.brand-illustrations {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));

  .icon-with-caption {
    text-align: center;

    img {
      width: 5rem;
      height: 5rem;
    }

    p {
      margin-top: 0.5rem;
      font-size: var(--font_size_sm);
      line-height: 1.25;
    }
  }
}

.icon-family-header {
  padding-bottom: 0.25rem;
  border-bottom: 1px solid #c0c0c0;
  margin-top: 1rem;

  h4 {
    margin: 0;
  }
}

.wearable-application-icons {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  .icon-with-caption {
    text-align: center;
  }

  p {
    margin-top: 0.5rem;
    font-size: var(--font_size_sm);
    line-height: 1.25;
  }
}
