.p-chips {
  width: 100%;
  display: inline-block;
  &.p-inputwrapper-focus:focus-within {
    @include focused;
  }
}

.p-chips .p-chips-multiple-container {
  padding: 0.3rem 0.75rem 0.25rem;
  li {
    margin-bottom: 0;
  }
}

.p-chips-token {
  cursor: default;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  margin-right: 5px;
  border: 0 none;
  padding: 0.25rem;
  font-size: var(--font_size_base);
  background-color: var(--token_color);

  .bgwhite & {
    background-color: var(--white);
  }

  .bgdark & {
    background-color: var(--dark_token_color);
    color: #000;
  }

  border-radius: 2px;
}

.p-chips-token .p-chips-token-label {
  display: block;
  margin-left: 1.5em;
  font-size: 0.9em;

  .bgdark & {
    color: #000;
  }
}

.p-chips > .p-state-disabled .p-chips-token-label {
  margin-right: 0;
}

.p-chips-token .p-chips-token-icon {
  position: absolute;
  top: 0;
  padding: 7px 4px 4px 3px;
  cursor: pointer;
  font-size: 9px;
}

.p-chips-token .p-chips-token-icon:hover {
  background-color: #d8eafd;
}

.form-control.p-chips.p-component {
  height: auto; // allows chips to wrap
}

.p-chips-input-token {
  display: inline-block;
  vertical-align: middle;
  list-style-type: none;
  margin: 0 0 0 0.125em;
  padding: 0.25em 0.25em 0.25em 0;
}

.p-chips-input-token input {
  border: 0 none;
  width: 10em;
  outline: medium none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

.p-fluid .p-chips {
  display: block;
}

// Error styles
p-chips {
  &.ng-touched.is-invalid,
  &.ng-touched.ng-invalid {
    .p-inputtext {
      border-color: var(--danger);
    }

    ~ .invalid-feedback {
      display: block;
    }
  }
}

.p-chips-multiple-container {
  overflow: initial !important;
}

.pi-times-circle::before {
  content: '\ea20' !important;
  font-size: 9px;
  font-family: 'pbi-icon-mini';
}
