$search-icon: 'data:image/svg+xml,%3Csvg%20width%3D%2218%22%20height%3D%2218%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cg%20transform%3D%22matrix(-1%200%200%201%2018%20.188)%22%20stroke%3D%22%23FFF%22%20stroke-width%3D%221.188%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Ccircle%20cx%3D%227.719%22%20cy%3D%227.719%22%20r%3D%227.125%22%2F%3E%3Cpath%20d%3D%22m17.219%2017.219-4.463-4.463%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E';

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes growX {
  0% {
    transform: scaleX(0);
  }

  100% {
    transform: scaleX(1);
  }
}

.header-search {
  padding: 0;

  .search-ui {
    display: none;
    width: 400px;
    float: right;
  }

  // when search is toggled on
  &.search-active {
    .search-ui {
      position: relative;
      display: block;
      animation: fadeIn 1s, growX 0.5s;

      input[type='text'] {
        padding: 7px 30px 7px 40px;
        border: 0;
        background: rgba(0, 0, 0, 0.5) url($search-icon) no-repeat 7px center;
        box-shadow: none;
        color: #fff;
        font-family: Helvetica, Arial, sans-serif;
        font-weight: normal;
      }

      .icon-search {
        color: rgba(255, 255, 255, 0.8);
        font-size: 1.5em;
      }

      .search-clear {
        position: absolute;
        top: 10px;
        right: 10px;
        // display: block;
        // min-width: 1px;
        padding: 0;
        background-color: transparent;
        color: rgba(255, 255, 255, 0.7);
        cursor: pointer;
        font-size: 14px;
        line-height: 0;
        opacity: 0.5;

        &:hover {
          background-color: transparent;
          color: rgba(255, 255, 255, 1);
        }
      }
    }
  }

  .search-dropdown-menu {
    $menu-width: 350px;
    position: absolute;
    z-index: 1000;
    top: 55px;
    right: 0;
    display: none;
    width: $menu-width;
    padding: 0;
    border: 1px solid #e1e1e1;
    background-color: #fff;
    border-radius: 0;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    font-size: 14px;
    list-style: none;
    text-align: left;

    li {
      float: none;

      a {
        display: block;
        padding: 10px 30px 5px 20px;
        color: #717171;
        font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
        font-size: 13px;
        line-height: 2;

        &:hover {
          background-color: #eaedf8;
        }
      }
    }
  }

  &.search-menu-active .search-dropdown-menu {
    display: block;
  }
}

.navbar-right {
  animation: fadeIn 0.5s;
}
.navbar-right.search-active {
  display: none;
}
