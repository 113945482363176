.p-tree-container {
  margin: 0;
  padding: 0;
  list-style-type: none;
  overflow: auto;
  .p-treenode-children {
    margin: 0;
    padding: 0 0 0 1.5em;
    list-style-type: none;
  }
}

.p-tree-wrapper {
  overflow: auto;
}

.p-treenode-selectable {
  cursor: pointer;
  user-select: none;
  .p-checkbox {
    margin-right: 8px;
  }
  &.p-highlight {
    background-color: var(--rich200);
  }
}

.p-tree-toggler {
  cursor: pointer;
  user-select: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.p-treenode-leaf > .p-treenode-content .p-tree-toggler {
  visibility: hidden;
}

.p-treenode-content {
  display: flex;
  align-items: center;
  padding: 0.25rem 0;
}

.p-treenode-label {
  padding-left: 0.5rem;
}

.p-tree-filter {
  width: 100%;
}

.p-tree-filter-container {
  position: relative;
  display: block;
  width: 100%;
}

.p-tree-filter-icon {
  position: absolute;
  top: 50%;
  margin-top: -0.5rem;
}

.p-tree-loading {
  position: relative;
  min-height: 4rem;
}

.p-tree .p-tree-loading-overlay {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.p-tree-flex-scrollable {
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
}

.p-tree-flex-scrollable .p-tree-wrapper {
  flex: 1;
}

.p-tree .p-treenode-droppoint {
  height: 4px;
  list-style-type: none;
}

.p-tree .p-treenode-droppoint-active {
  border: 0 none;
}

.p-tree-horizontal {
  width: auto;
  padding-left: 0;
  padding-right: 0;
  overflow: auto;
}

.p-tree.p-tree-horizontal table,
.p-tree.p-tree-horizontal tr,
.p-tree.p-tree-horizontal td {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}

.p-tree-horizontal .p-treenode-content {
  font-weight: normal;
  padding: 0.4em 1em 0.4em 0.2em;
  display: flex;
  align-items: center;
}

.p-tree-horizontal .p-treenode-parent .p-treenode-content {
  font-weight: normal;
  white-space: nowrap;
}

.p-tree.p-tree-horizontal .p-treenode {
  background: url($line) repeat-x scroll center center transparent;
  padding: 0.25rem 2.5rem;
}

.p-tree.p-tree-horizontal .p-treenode.p-treenode-leaf,
.p-tree.p-tree-horizontal .p-treenode.p-treenode-collapsed {
  padding-right: 0;
}

.p-tree.p-tree-horizontal .p-treenode-children {
  padding: 0;
  margin: 0;
}

.p-tree.p-tree-horizontal .p-treenode-connector {
  width: 1px;
}

.p-tree.p-tree-horizontal .p-treenode-connector-table {
  height: 100%;
  width: 1px;
}

.p-tree.p-tree-horizontal .p-treenode-connector-line {
  background: url($line) repeat-y scroll 0 0 transparent;
  width: 1px;
}

.p-tree.p-tree-horizontal table {
  height: 0;
}
