.p-multiselect {
  width: 100% !important;
  display: inline-flex;
  position: relative;
  user-select: none;
  border-radius: $border-radius;
  border: 1px solid var(--input_border_color);
  background-color: #fff;
  cursor: pointer;
  .p-inputwrapper-focus & {
    @include focused;
    .p-inputtext:focus-visible {
      @include focused;
    }
  }
  .bgwhite & {
    background-color: var(--rich100);
  }

  .bgdark & {
    background-color: var(--dark_input_bg);
    border: 1px solid var(--dark_input_border);
  }

  .p-multiselect-label {
    display: block;
    white-space: nowrap;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: normal;
    padding: 0.4rem 0.75rem;
    text-overflow: ellipsis;
    color: var(--text);
    font-family: var(--font_family_base);

    .bgdark & {
      color: var(--dark_fg);
    }
  }

  .p-multiselect-panel {
    min-width: 100%;
    background-color: #fff;
  }

  .p-multiselect-items {
    padding: 0;
  }

  .p-multiselect-trigger {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    flex-basis: 30px;
  }
}

.p-multiselect-panel {
  position: absolute;
  min-width: 12em;
  padding: 0;
  top: -2px;
  left: -1px;
  border: 1px solid var(--input_border_color);
  background-color: #fff;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);

  .bgdark & {
    background-color: var(--dark_input_bg);
    border: 1px solid var(--dark_input_border);
  }

  .p-multiselect-list,
  .ul-dropdown-list {
    padding: 0;
    margin: 0;
  }

  .p-multiselect-item {
    &:focus-visible {
      outline: 1.5px solid var(--focus_border_color);
      outline-offset: -1px;
    }
    &.p-focus {
      outline: 1.5px solid var(--focus_border_color);
      outline-offset: -1px;
    }
    position: relative;
    display: block;
    padding: 0.5rem 1rem;
    margin: 1px 0;
    border: 0;
    color: var(--text);
    cursor: pointer;
    font-family: var(--font_family_base);
    font-weight: normal;
    text-align: left;
    white-space: nowrap;

    .p-checkbox {
      display: inline-block;
      margin-right: 0.5rem;
      vertical-align: middle;
    }

    label {
      display: inline-block;
      font-family: var(--font_family_base);
      font-weight: normal;
      vertical-align: middle;
    }
  }

  .p-multiselect-item:not(.p-state-highlight):hover {
    border-color: var(--input_border_color);
    background: #e9edf9;
    color: var(--text);
    outline: 1px solid var(--focus_border_color);
    outline-offset: -1px;

    .bgdark & {
      background: var(--dark_menu_hover);
    }
  }
}

.p-multiselect-header {
  position: relative;
  display: flex;
  min-height: 2em;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem;
  margin-bottom: 0.3em;
  background-color: var(--white);
  border-bottom: 1px solid var(--border_color);

  .bgdark & {
    background-color: var(--dark_input_bg);
    border-bottom: 1px solid var(--dark_input_border);
  }

  .p-multiselect-filter-container {
    position: relative;
    display: block;
    flex: 1;
    margin: 0 0.25rem;

    .p-inputtext {
      width: 100%;
      padding-left: 24px;
      border-radius: $border-radius;
    }
    .p-icon-wrapper .p-multiselect-filter-icon {
      position: absolute;
      top: 18px;
      left: 5px;
    }
  }

  .p-multiselect-close {
    display: block;
    border: 0;
    color: var(--btn_primary);
    font-size: 1em;
    &:active {
      @include focused;
    }
    &:focus-visible {
      @include focused;
    }
  }

  .pi.pi-search {
    position: absolute;
    top: 15px;
    left: 5px;
  }
}
