// primary page-level buttons
.btn-page-primary:not([disabled]):not(.disabled),
.btn-page-primary:not([disabled]):not(.disabled) {
  border-color: var(--btn_page_primary);
  background-color: var(--btn_page_primary);
  color: var(--btn_primary_label);

  &:hover,
  &.hover,
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    border-color: var(--btn_page_primary_active);
    background-color: var(--btn_page_primary_active);
  }
}

.btn-page-primary.disabled,
.btn-page-primary:disabled {
  border-color: var(--disabled);
  background-color: var(--disabled);
  color: var(--btn_primary_label);
}

// added for 7.0.0
.btn-page-primary,
.btn-page-secondary {
  min-width: 100px;
}

.btn-page-secondary,
.btn-page-outline-secondary,
.btn-page-outline-primary,
.btn-page-link,
.btn-group .btn-page-secondary,
.btn-group .btn-page-outline-secondary,
.btn-group-vertical .btn-page-secondary,
.btn-group-vertical .btn-page-outline-secondary,
.btn-page-secondary:not([disabled]):not(.disabled),
.btn-page-link:not([disabled]):not(.disabled),
.btn-page-outline-secondary:not([disabled]):not(.disabled),
.show > .btn-page-secondary.dropdown-toggle,
.show > .btn-page-outline-secondary.dropdown-toggle {
  border-color: var(--btn_page_primary);
  background-color: transparent;
  color: var(--btn_page_secondary_label);

  .bgdark & {
    background-color: var(--dark_input_bg);
  }

  &:hover,
  &.hover,
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    border-color: var(--btn_page_primary);
    background-color: var(--btn_secondary_bg_active);
    color: var(--btn_page_secondary_label);
  }

  &:active,
  .open > &.dropdown-toggle {
    border-color: var(--focus);
    background-color: var(--btn_secondary_bg_active);
    background-image: none;
    box-shadow: 0 0 10px var(--focus);
    color: var(--btn_page_primary);

    &:hover {
      background-color: var(--btn_secondary_bg_active);
    }
  }

  &:focus,
  &.focus {
    @include focused;
    outline-offset: 1.5px;
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &.hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      border-color: var(--disabled);
      background-color: transparent;
      color: var(--disabled);
      opacity: 1;
    }
  }

  &.disabled.loading,
  &[disabled].loading {
    border-color: var(--disabled);
    background-color: var(--disabled);
    color: var(--white);
  }

  &.btn-transparent {
    border-color: var(--white);
    background-color: transparent;
    color: var(--white);
  }

  .badge {
    margin-left: 10px;
    background-color: var(--secondary);
    color: var(--white);
  }

  &.tile-button {
    page-width: 160px;
    height: 120px;
    border-color: var(--border_color);
    background-color: var(--white);
    border-radius: $border-radius;

    &:hover,
    &.hover {
      background-color: var(--white);
    }

    &:active,
    &.active {
      border-width: 2px;
      border-color: var(--btn_primary);
      background-color: var(--btn_secondary_bg);
      box-shadow: none;

      &:hover {
        border-width: 2px;
        border-color: var();
        background-color: var(--btn_secondary_bg);
      }
    }

    &.disabled,
    &[disabled] {
      border-color: var(--disabled);

      img {
        filter: grayscale(100%);
        opacity: 0.6;
      }
    }
  }
}

.btn-page-primary.btn-tour-coach-marks {
  border-radius: 0px;
}

.btn-page-primary.btn-tour-coach-marks:not(:last-of-type) {
  border-right: 1px solid var(--white) !important;
}
