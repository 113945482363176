.site-search {
  padding: 15px 0px;

  // category buttons
  .btn-group {
    gap: 1rem;

    .btn {
      color: #fff;
      border: 1px solid #fff !important;
      background-color: rgba(61, 67, 92, 0.7);

      &:hover {
        background-color: hsla(228, 20%, 39%, 1);
      }
    }
  }

  .btn-group > .btn-check:checked + .btn,
  .btn-group > .btn-check:checked + .btn:hover {
    background-color: var(--primary);
    color: #fff;
  }

  .algolia-typeahead {
    position: relative;

    &::before {
      content: '\eb59';
      font-family: 'pbi-icon-outline';
      position: absolute;
      color: #fff;
      font-size: 1rem;
      padding: 0.175rem 0 0 0.5rem;

      .home & {
        color: var(--text);
        font-size: 1.5rem;
      }
    }

    input {
      width: 100%;
      padding: 4px 8px 4px 30px;
      border: 0;
      background-color: hsl(220, 9%, 31%);
      color: #fff;
      font-family: var(--font-family-sans-serif);

      &:focus {
        outline: 1px solid var(--focus_border_color);
        outline-offset: 2px;
        outline-style: dotted;

        color: #fff;
      }

      &:not(:valid) {
        & ~ .search-clear {
          opacity: 0;
          transition: opacity 0.3s ease-in-out;
        }
      }

      .home & {
        background-color: #fff;
        font-size: var(--font_size_lg);
        padding: 0.5rem 1rem 0.5rem 2.5rem;
        min-width: 430px;

        color: black;

        &:focus {
          border-color: hsl(226, 3%, 61%);
          color: black;
        }
      }
    }

    ngb-typeahead-window {
      width: 100%;

      .dropdown-item {
        overflow: hidden;
        padding: 5px;
        font-size: 13px;
        text-overflow: ellipsis;

        &.active {
          background-color: var(--primary);
          color: #fff;
        }
      }
    }

    .typeahead-loader {
      position: absolute;
      top: 0.45rem;
      right: 0.625rem;
    }
  }
}
