.p-p-4 {
  padding: 1.5rem !important;
}

.p-m-0 {
  margin: 0 !important;
}
.p-p-0 {
  padding: 0 !important;
}
.p-mb-3 {
  margin-bottom: 1rem !important;
}
.p-d-flex {
  display: flex !important;
}
.p-mr-2 {
  margin-right: 0.5rem !important;
}
.p-mb-2 {
  margin-bottom: 0.5rem !important;
}
