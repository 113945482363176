.toolbar {
  margin-bottom: 0.5rem;
  border-top: 1px solid var(--divider_color);
  border-bottom: 1px solid var(--divider_color);
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;

  .bgwhite & {
    background-color: var(--rich100);
  }

  .bgdark & {
    background-color: var(--dark_input_bg);
    color: var(--dark_fg);
  }

  .toolbar-left,
  .toolbar-left > div[toolbar-left] {
    display: flex;

    > .btn {
      margin-left: 0;
    }

    @include media-breakpoint-up(md) {
      align-items: center;
      flex-direction: row;
    }
  }

  .toolbar-right,
  .toolbar-right > div[toolbar-right] {
    display: flex;

    input[type='text'] {
      border: 0;
    }

    justify-content: space-between;
    align-items: center;
    width: 100%;

    @include media-breakpoint-up(md) {
      width: auto;
      justify-content: flex-end;
      flex-direction: row;
    }
  }
  &.toolbar-outline {
    border-top: 0;
    padding-bottom: 0.5rem;
    background-color: transparent;

    .btn-cancel,
    .btn-link,
    .btn-group-vertical .btn-default,
    .btn-secondary,
    .btn-secondary.btn-sm,
    .btn-group .btn-secondary,
    .btn-group-vertical .btn-secondary,
    .btn-check + .btn,
    .dropdown-toggle,
    .dropdown-toggle:not([disabled]):not(.disabled),
    .btn-secondary:not([disabled]):not(.disabled) {
      border-radius: var(--border_radius);
      border: 1px solid var(--btn_primary);
      background-color: transparent;
      &:hover {
        border: 1px solid var(--btn_primary);
        background-color: var(--btn_secondary_bg_active);
      }
    }

    .toolbar-left,
    .toolbar-left > div[toolbar-left],
    .toolbar-right,
    .toolbar-right > div[toolbar-right] {
      gap: 8px;
    }
    .input-group {
      border: 1px solid var(--btn_primary);
      border-radius: 2px;
      margin: 0;
      .input-group-text {
        padding: 8px;
      }
      .form-control {
        height: auto;
        border: 0;
      }
      &.pbds-daterange-popover {
        .btn:has(i.pbi-calendar) {
          border: 0;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    align-items: center;
    flex-direction: row;
  }

  .toolbar-divider {
    background-color: var(--divider_color);
    width: 1px;
    margin: 0.5rem 0;
    flex-grow: 0;
    margin: 0 0.25rem;
    height: 100%;

    @include media-breakpoint-up(lg) {
    }
  }

  .pbds-daterange-popover input.form-control {
    font-size: var(--font_size_sm);
    min-width: 130px;
    padding: 8px;
  }
  .pbds-daterange-popover {
    .popover-body {
      .btn-secondary:not([disabled]):not(.disabled) {
        border: 1px solid var(--btn_primary);
        border-radius: var(--border_radius);
      }
    }
  }
  .input-group {
    width: unset;

    @include media-breakpoint-up(lg) {
      margin: 0 0.5rem;
    }

    .form-control:not([readonly]) {
      border-radius: 0;
      padding: 8px;
      font-size: var(--font_size_sm);

      border-color: var(--border_color);
      background-color: transparent;

      @include media-breakpoint-up(lg) {
        border: 0;
      }
    }

    .input-group-text {
      border-radius: 0;
      padding: 4px;
      border: 0;
      background-color: transparent;
    }

    .input-group-prepend,
    .input-group-append {
      background-color: transparent;
    }
  }

  .pagination {
    margin: 0;
  }

  .btn-cancel,
  .btn-link,
  .btn-group-vertical .btn-default,
  .btn-secondary,
  .btn-secondary.btn-sm,
  .btn-group .btn-secondary,
  .btn-group-vertical .btn-secondary,
  .btn-check + .btn,
  .dropdown-toggle,
  .dropdown-toggle:not([disabled]):not(.disabled),
  .btn-secondary:not([disabled]):not(.disabled) {
    padding: 8px 8px 8px 8px;
    border-radius: 0rem;
    font-size: var(--font_size_sm); // see above
    line-height: 1.5;
    color: var(--btn_secondary_label);
    border: 0;

    &:hover {
      border: 0;
      background-color: var(--btn_secondary_bg_active);
    }

    &.active,
    &:active,
    &:active:focus {
      background-color: var(--btn_primary);
      border: 0;
      color: #fff;
    }

    &[disabled],
    &.disabled {
      border: 0;
      color: var(--disabled);
      pointer-events: none;
    }
  }

  .btn-group[data-toggle='buttons'] {
    .btn.btn-secondary.active.disabled {
      background-color: var(--disabled);
      color: #fff;
    }
  }

  .btn-link:focus,
  .btn-link:hover {
    text-decoration: none;
  }

  .input-group-btn {
    &.open {
      color: #fff;

      .dropdown-toggle {
        color: #fff;
      }
    }
  }

  .search-clear {
    position: absolute;
    z-index: 10;
    right: 0;
    padding-right: 3px;
    padding-left: 0;
    border: 0;
    color: var(--disabled);
    opacity: 1;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: inherit;
      color: var(--gray100);
    }
  }

  .show > .btn-secondary.dropdown-toggle,
  .show > .btn-secondary.dropdown-toggle:focus,
  .show > .btn-outline-secondary.dropdown-toggle,
  .show > .btn-outline-secondary.dropdown-toggle:focus,
  .show > .btn-outline-primary.dropdown-toggle,
  .show > .btn-outline-primary.dropdown-toggle:focus,
  .show > .btn-outline-primary.dropdown-toggle:hover,
  .show > .btn-outline-primary.dropdown-toggle:focus:hover {
    border-color: var(--btn_primary);
    background-color: var(--btn_primary);
    box-shadow: none;
    color: var(--btn_primary_label);
  }

  .dropdown-menu > li > a {
    display: block;
    padding: 3px 20px;
    clear: both;
    color: var(--primary);
    font-family: var(--font_family_brand_regular);
    font-size: 13px;
    line-height: 1.42857143;
    white-space: nowrap;

    a:hover {
      text-decoration: none;
    }
  }

  .dropdown-menu > li > a:focus,
  .dropdown-menu > li > a:hover {
    background-color: var(--primary);
    color: #fff;
  }

  .input-group {
    .input-group-prepend {
      .input-group-text {
        border-right: 0;
        padding-right: 0;
      }

      + input.form-control {
        border-left: 1px solid transparent;

        &:focus {
          border-left: 1px solid var(--focus-ring);
        }
      }
    }
  }

  .ui-multiselect-label {
    padding: 0.25rem 1.5rem 0.25rem 0.5rem;
    font-family: var(--font_family_brand_regular);
    font-size: var(--font_size_sm);
    line-height: 1.5;
  }

  .ui-multiselect .ui-multiselect-trigger,
  .ui-multiselect .ui-dropdown-trigger,
  .ui-dropdown .ui-multiselect-trigger,
  .ui-dropdown .ui-dropdown-trigger {
    top: 3px !important;
    padding: 0 !important;
  }

  // for ngb popover-based datepickers
  .popover {
    .btn-secondary {
      border: 1px solid var(--btn_primary);

      &:focus:hover {
        color: var(--white);
      }
    }
  }

  .btn-group-toggle,
  .btn-group {
    margin: 0 4px;

    .btn + .btn {
      margin-left: 0;
    }

    .btn-secondary,
    .btn-secondary:not([disabled]):not(.disabled) {
      background-color: transparent;

      &:hover {
        border: 0;
      }

      &.active {
        border-color: var(--btn_primary_active);

        &:hover {
          border: 0;
        }
      }

      &:not(.active):hover {
        background-color: hsl(228, 52%, 90%);
      }
    }
  }

  // bootstrap 5
  .btn-group > .btn-check:checked + .btn,
  .btn-group > .btn-check:checked + .btn:hover {
    background-color: var(--btn_primary_active);
    color: var(--btn_primary_label);
    border: 0;
  }

  .btn-group > .btn-check + .btn {
    background-color: var(--btn_secondary_bg_active);
    color: var(--btn_secondary_label);
    border: 0;
    margin-left: 0;
  }

  .btn-group > .btn-check + .btn:hover {
    background-color: hsl(228, 52%, 90%);
    border: 0;
    color: var(--btn_secondary_label);
  }
}

.toolbar-pagination {
  .toolbar-right {
    display: flex;
    align-items: center;
  }

  .toolbar-pagination-controls {
    margin-left: 2rem;
  }
}
