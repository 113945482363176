.p-picklist {
  display: flex;
}

.p-picklist-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
}

.p-picklist .p-picklist-buttons {
  padding: 1rem;
}
.p-picklist-buttons button:disabled {
  border-color: var(--disabled);
  background-color: transparent;
  color: var(--disabled);
  opacity: 1;
}

.p-picklist-list-wrapper {
  flex: 1 1 50%;
}

.p-picklist-title {
  font-size: var(--font_size_lg);
  font-family: var(--font_family_brand_regular);
  margin-bottom: 0.5rem;
}

.p-picklist-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: auto;
  // min-height: 12rem;
  height: 250px;
  border: 1px solid var(--border_color);
  background: #fff;
  color: var(--text);
  padding: 0.5rem 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;

  .bgwhite & {
    background: var(--rich100);
  }

  .bgdark & {
    background-color: var(--input_bg_dark);
  }
}

.p-picklist-item {
  display: block;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  padding: 4px 16px;
}

.p-picklist .p-picklist-list .p-picklist-item.p-highlight {
  background-color: var(--rich200);

  .bgdark & {
    background-color: var(--dark_menu_hover);
    color: #fff;
  }
}

.p-picklist-item:not(.cdk-drag-disabled) {
  cursor: move;
}

.p-picklist-item.cdk-drag-placeholder {
  opacity: 0;
}

.p-picklist-item.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.p-picklist-filter {
  position: relative;
}

.p-picklist-filter-icon {
  position: absolute;
  top: 50%;
  margin-top: -0.5rem;
}

.p-picklist-filter-input {
  width: 100%;
}

.p-picklist-list.cdk-drop-list-dragging .p-picklist-item:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
