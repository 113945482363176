.p-editor-container {
  .p-editor-toolbar.ql-toolbar.ql-snow {
    padding: 0 8px;
    border-radius: 0rem;
    font-size: var(--font_size_sm);
    line-height: 1.5;
    color: var(--btn_primary);
    border: 1px solid var(--divider_color);
    font-family: 'PrecisionSans_W_Rg';
    background-color: rgba(255, 255, 255, 0.5);

    .bgwhite & {
      background-color: var(--rich100);
    }

    .bgdark & {
      background-color: #000;
    }
  }

  .ql-container.ql-snow {
    border: 1px solid var(--border_color);

    .bgdark & {
      background-color: var(--dark_input_bg);
    }
  }

  .ql-snow .ql-picker-label {
    font-size: var(--font_size_sm);
    line-height: 1.5;
    color: var(--btn_primary);
    border: 0;
  }

  .ql-snow.ql-toolbar .ql-picker-label:hover,
  .ql-bold:hover,
  .ql-italic:hover,
  .ql-underline:hover,
  .ql-snow.ql-toolbar button svg:hover,
  .ql-snow.ql-toolbar button:hover .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
  .ql-snow.ql-toolbar button:hover,
  .ql-snow.ql-toolbar .ql-picker-item:hover,
  .ql-snow.ql-toolbar button.ql-active .ql-stroke,
  .ql-snow.ql-toolbar button.ql-active,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke {
    color: var(--btn_primary);
    background-color: var(--btn_secondary_bg_active);
    stroke: var(--btn_primary);
  }

  .ql-snow .ql-picker-label:focus-visible,
  .ql-snow.ql-toolbar .ql-picker-item:focus-visible,
  .ql-snow.ql-toolbar button:focus-visible,
  .ql-snow.ql-toolbar button:focus,
  .ql-snow.ql-toolbar .ql-list:focus,
  .ql-snow.ql-toolbar .ql-picker:focus .ql-stroke {
    @include focused;
    color: var(--btn_primary) !important;
    //
  }
  .ql-snow.ql-toolbar .ql-picker-label.ql-active,
  .ql-snow.ql-toolbar button.ql-active {
    @include focused;
    outline-offset: -1px;
    background-color: transparent;
  }

  .ql-snow.ql-toolbar button:focus .ql-stroke {
    stroke: var(--btn_primary);
  }
  .ql-snow.ql-toolbar button:focus .ql-fill {
    fill: var(--btn_primary);
  }

  .ql-snow .ql-stroke {
    fill: none;
    stroke: var(--btn_primary);
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5;
  }

  .ql-snow .ql-picker {
    height: auto;
    //  padding: 8px 0;
    color: var(--text);
    font-size: var(--font_size_sm);
    font-weight: 500;
  }

  .ql-snow .ql-header,
  .ql-snow .ql-font {
    padding: 8px 0;
  }

  .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    background: #ffffff;
    border: 0 none;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    border-radius: 6px;
    padding: 0.75rem 0;
  }

  .ql-snow .ql-editor a {
    text-decoration: underline;
  }

  .ql-snow a {
    color: var(--hyperlink);
  }

  .p-editor-toolbar.ql-snow .ql-picker.ql-expanded:not(.ql-icon-picker) .ql-picker-item {
    padding: 8px 10px;
  }

  .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    margin-top: -1px;
    border-top-color: #e6e6e6;
    border-radius: 0;
    padding: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 1px solid var(--border_color);
    background-color: #fff;
    box-shadow: 0 1px 0 rgb(0 0 0 / 6%);
  }
}
