.p-calendar {
  position: relative;
  display: inline-flex;
  width: 100%;
}
.p-datepicker {
  padding: 0.25rem;
}
.p-calendar .p-inputtext {
  flex: 1 1 auto;
  width: 1%;
}

.p-calendar-w-btn .p-inputtext {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.p-calendar-w-btn .p-datepicker-trigger {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/* Fluid */
.p-fluid .p-calendar {
  display: flex;
}

.p-fluid .p-calendar .p-inputtext {
  width: 1%;
}

/* Datepicker */
.p-calendar .p-datepicker {
  min-width: 75% !important;
  background-color: #fff;
  border: 1px solid var(--border_color);
  border-radius: 0;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
}
.p-datepicker table td > span.p-highlight {
  color: var(--white);
  border-radius: 0.25rem;
  background: var(--primary) !important;
}
.p-datepicker table td > span.p-disabled {
  color: var(--disabled);
}
.p-datepicker table td > span:hover {
  background-color: var(--rich100);
  outline: 1px solid var(--border_color);
}
.p-datepicker table thead {
  font-size: var(--font_size_sm);
  background-color: var(--rich100);
  border-bottom: 1px solid var(--border_color);
}
.p-datepicker {
  width: auto;
  position: absolute;
}
.p-datepicker th {
  font-weight: 100;
}

.p-datepicker-inline {
  display: inline-flex;
  position: static;
}

/* Header */
.p-datepicker-header {
  display: flex;
  align-items: center;
  justify-content: space-around !important;
  padding: 0.5rem 0.25rem;
  background-color: var(--rich100) !important;
  color: var(--text);
}

.p-datepicker-header .p-datepicker-title {
  margin: 0 !important;
}

.p-datepicker-prev,
.p-datepicker-next {
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

/* Multiple Month DatePicker */
.p-datepicker-multiple-month .p-datepicker-group-container {
  display: flex;
}

.p-datepicker-group {
  border-top: 1px solid var(--border_color);
  // background-color: #fff;
  // border-radius: 0;
  // box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
}

/* DatePicker Table */
.p-datepicker table {
  width: 100%;
  border-collapse: collapse;
}

.p-datepicker td > span,
.p-datepicker th > span {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  border-radius: 0.25rem;
}

.p-datepicker td > span,
.p-datepicker th > span {
  padding: 0.5em;
}
.p-datepicker-today {
  // border-color: var(--border_color);
  background-color: var(--rich200);
  border-radius: 0.25rem;
  color: var(--rich600);
}
.p-datepicker .p-datepicker-header .p-link {
  color: var(--rich600) !important;
}

/* Month Picker */
.p-monthpicker-month {
  width: 33.3%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

/*  Button Bar */
.p-datepicker-buttonbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Time Picker */
.p-timepicker {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.857em;
  border: 0 none;
  border-top: 1px solid var(--border_color);

  border-radius: 0;
}

.p-timepicker button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  color: var(--hyperlink);
  font-size: 1.1rem;
  padding: 0.5rem;
}

.p-timepicker > div {
  display: flex;
  align-items: center;
  flex-direction: column;
}

/* Touch UI */
.p-datepicker-touch-ui,
.p-calendar .p-datepicker-touch-ui {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 80vw;
  transform: translate(-50%, -50%);
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  margin-right: 0.5rem;
}
